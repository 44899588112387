@mixin triangle($direction, $position, $height: 16px, $border-width: 1px, $color: #ffffff, $border-color: $base__primary-color) {
  @if not index(top right bottom left, $direction) {
    @warn "Direction must be one of `top`, `right`, `bottom` or `left`; currently `#{$direction}`.";
  }

  @else {

    $opposite-direction: opposite-direction($direction);
    $position-direction: left;
    $border-calculated-width: $height + round($border-width * 1.41421356237);
    @if $direction == left or $direction == right {
      $position-direction: top;
    }

    &:after, &:before {
      #{$opposite-direction}: 100%;
      #{$position-direction}: $position;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after{
      border-color: rgba($color, 0);
      border-#{$opposite-direction}-color: $color;
      border-width: $height;
      margin-#{$position-direction}: - $height;
    }
    &:before{
      border-color: rgba($border-color, 0);
      border-#{$opposite-direction}-color: $border-color;
      border-width: $border-calculated-width;
      margin-#{$position-direction}: - $border-calculated-width;
    }

  }
}
