


.accordion-list{
  list-style: none;
  margin: 0;
}

.accordion-list-item{

}

.accordion-header{
  background: #f5eed3;
  padding: .75rem;
  border-radius: $base__border-radius;
  border-bottom: 1px solid #ccb59b;
  margin: 0;
  &:hover{
    cursor: pointer;
  }
}
.accordion--open .accordion-header{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion-content{
  @include element-invisible;
  padding: .75em;
  border: 1px solid #ccb59b;
  border-top: none;
  border-bottom-left-radius: $base__border-radius;
  border-bottom-right-radius: $base__border-radius;
  opacity: 0;
  @include single-transition(opacity);
}
.accordion--open .accordion-content{
  @include element-invisible-off;
  opacity: 1;
}