.slides__carousel{
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
    overflow: hidden;
    //border-bottom: 1px solid #dddddd;
    &__column{
        margin-bottom: $app-grid__column-gutter * 1.5;
    }
    &__heading{
        border: 1px solid #d8d8d8;
        border-radius: $base__border-radius;
        background: #f0f6fa;
        padding: $app-grid__column-gutter / 3;
    }
    &__slide{
        @include grid-column($columns:12, $padding: true, $float:false);
        margin-right: rem-calc(1);
        background: #ffffff;
        height: rem-calc(285);
        padding-left: $app-grid__column-gutter / 2;
        padding-right: $app-grid__column-gutter / 2;
        &:hover{
            cursor: pointer;
        }
        &--large-3-column{
        }
        &--large-4-column{
        }
        &__heading{
            position: absolute;
            bottom: 0;
            left: $app-grid__column-gutter / 2;
            right: $app-grid__column-gutter / 2;
            text-align: center;
            margin: 0;
            background: #ffffff;
            background: rgba(255,255,255, .85);
            border-top: 1px solid #aaa;
            padding: $app-grid__column-gutter / 4;
            font-weight: $base__font-weight--bold;
            font-family: $base__font-family--condensed;
            font-size: 19px;
            &__link{
                color: $base__heading__color;
                &:focus,
                &:hover{
                    color: $base__heading__color--dark;
                }
            }
        }
        &__img{
            display: block;
            margin: auto;
            text-align: center;
        }
    }
    &__button__prev{
        left: 0;
    }
    &__button__next{
        right: 0;
    }
}
@include mq(medium-up) {
    .slides__carousel{
        padding-left: rem-calc(45);
        padding-right: rem-calc(45);
        &__column{
        }
        &__heading{
        }
        &__slide{
            &--large-3-column{
                @include grid-column($columns:6, $padding: false, $float:false);
            }
            &--large-4-column{
                @include grid-column($columns:6, $padding: false, $float:false);
            }
            &__heading{
                font-size: 22px;
            }
            &__img{
            }
        }
    }
}

@include mq(large-up) {
    .slides__carousel{
        &__column{
        }
        &__heading{
        }
        &__slide{
            &--large-3-column{
                @include grid-column($columns:3, $padding: false, $float:false);
            }
            &--large-4-column{
                @include grid-column($columns:4, $padding: false, $float:false);
            }
            &__heading{
                font-size: 24px;
            }
            &__img{
            }
        }
    }
}
