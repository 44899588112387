.main-content__container{
  position: relative;
  margin-bottom: $app-grid__column-gutter / 2;
}
.main-content__row{
  @extend %extended_clearfix;
  width: 100%;
  max-width: $app-grid__row-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $app-grid__column-gutter / 2;
  padding-right: $app-grid__column-gutter / 2;
  &--force-full-width{
    @include force-full-width();
  }
}

.main-content{
  padding-top: $app-grid__column-gutter / 2;
}

@include mq(medium-up){
  .main-content__fixed-width__275{
    float: left;
    width: rem-calc(275);
  }
  .main-content__push-right__275{
    margin-left: rem-calc(290);
    width: auto;
  }
}

@include mq(large-up){
  .main-content__container {
    min-height: rem-calc(540);
  }
}

