$app-help__category__topic__border-color: #cccccc !default;
$app-help__category__topic__border: 1px solid $app-help__category__topic__border-color !default;
$app-help__category__topic__img__border-color: #666666 !default;
$app-help__category__topic__img__border: 1px solid $app-help__category__topic__img__border-color !default;


.help{
  &__section__category{
    margin: $app-grid__column-gutter / 2 0;
    &__heading{

    }
  }
  &__category__article{

  }
  &__category__topic{
    padding: 1rem;
    border: $app-help__category__topic__border;
    &__name{

    }
    &__heading{

    }
    &__subhead{

    }
    &__content{

    }
    &__img{
      border: $app-help__category__topic__img__border;
    }
  }
}