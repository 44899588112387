.software-download{
  &__advantages__list{
    list-style: none;
    margin: 0 0 $app-grid__column-gutter 0;
  }
  &__advantages__list-item{
    padding: 1rem 1rem 1rem 2.5rem;
    background: no-repeat url("/assets/img/style/icon-checkmark-1.png");
    background-size: 34px;
    background-position: 0 10px;
  }
  &__advantages__list-item__title{

  }
  &__panel{
    margin-bottom: $app-grid__column-gutter;
    padding: $app-grid__column-gutter / 2;
    border: 1px solid $base__light-gray__border-color;
    background: $base__secondary-color;
    border-radius: $base__border-radius;
    text-align: center;
  }
  &__panel__list{
    list-style: none;
    margin: 0;
  }
  &__panel__list-item{
    padding: $app-grid__column-gutter / 4;
    border-bottom: 1px solid $base__light-gray-color;
    &:last-child{
      border-bottom: none;
    }
  }
  &__panel__list-item__text{

  }
}

@include mq(medium-up){
  .software-download{
    &__advantages__list{
      display: flex;
      flex-wrap: wrap;
    }
    &__advantages__list-item{
      flex: 1 1 50%;
    }
  }
}
