$base__body__background-color: #ffffff !default;
$base__font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !default;
$base__font-size: 16px !default;
$base__font-color: #000000 !default;
$base__font-weight: normal !default;
$base__font-style: normal !default;
$base__font-line-height: 1.5 !default;
$base__list__margin-left: rem-calc(17.6) !default;
$base__list__margin-bottom: rem-calc(20) !default;
$base__list__list-style-position: outside !default;
$base__list__font-family: inherit !default;
$base__list__font-size: rem-calc(16) !default;
$base__list__line-height: 1.6 !default;
$base__list-nested-margin: rem-calc(20) !default;

html, body {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  font-size: 100%;
}

body {
  background: $base__body__background-color;
  color: $base__font-color;
  padding: 0;
  margin: 0;
  font-family: $base__font-family;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  position: relative;
  cursor: auto;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin:0;
  padding:0;
}

ul,
ol{
  margin-left: $base__list__margin-left;
  margin-bottom: $base__list__margin-bottom;
  list-style-position: $base__list__list-style-position;
  font-size: $base__list__font-size;
  line-height: $base__list__line-height;
  font-family: $base__list__font-family;
  & li{
    ul,
    ol{
      margin-left: $base__list-nested-margin;
      margin-bottom: 0;
    }
  }
}


a {
  color: $base__anchor__font-color;
  line-height: inherit;
  text-decoration: $base__anchor__text-decoration;
  &:hover,
  &:focus {
    cursor: pointer;
    color: $base__anchor__font-color--hover;
    text-decoration: $base__anchor__text-decoration--hover;
  }
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
  border:none;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}
