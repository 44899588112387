$app-cart-summary__off-canvas__list-item__total__background-color: #351f04 !default;
$app-cart-summary__off-canvas__list-item__total__background: $app-cart-summary__off-canvas__list-item__total__background-color !default;
$app-cart-summary__off-canvas__list-item__total__font-color: #986e3d !default;
$app-cart-summary__off-canvas__list-item__total__padding: rem-calc(10) $app-grid__column-gutter/2 !default;
$app-cart-summary__off-canvas__line-item__description__name__font-size: rem-calc(13) !default;
$app-cart-summary__off-canvas__line-item__description__name__line-height: 1.3 !default;
$app-cart-summary__off-canvas__line-item__description__name__color--hover: #aaaaaa !default;
$app-cart-summary__large__container__background-color: #452702 !default;
$app-cart-summary__large__container__background: $app-cart-summary__large__container__background-color !default;
$app-cart-summary__large__container__border-color: #ccb59b !default;
$app-cart-summary__large__container__border: 1px solid $app-cart-summary__large__container__border-color !default;
$app-cart-summary__large__container__border-radius: $base__border-radius !default;
$app-cart-summary__cart-empty__heading__font-color: $app-cart-summary__off-canvas__list-item__total__font-color;

.cart-summary__large__container {
  display: none;
}
.cart-summary__small__container {

}
.cart-summary__cart-empty__heading{
  text-align: center;
  color: $app-cart-summary__cart-empty__heading__font-color;
}
.cart-summary__list{
  list-style: none;
  margin: 0;
}

.cart-summary__quick-order{
  &__form{
    margin: 0;
  }
  &__form__input-column__product-id,
  &__form__input-column__submit{
    @include grid-column(6, $padding: false);
  }
}

.cart-summary__off-canvas__list-item__total{
  background: $app-cart-summary__off-canvas__list-item__total__background;
}
.cart-summary__off-canvas__total{
  margin: 0;
  padding: $app-cart-summary__off-canvas__list-item__total__padding;
  text-align: center;
  color: $app-cart-summary__off-canvas__list-item__total__font-color;
}

.cart-summary__off-canvas__line-item__description__name{
  display: block;
  font-size: $app-cart-summary__off-canvas__line-item__description__name__font-size;
  line-height: $app-cart__line-item__description__name-small__line-height;
  &:hover,
  &focus{
    color: $app-cart-summary__off-canvas__line-item__description__name__color--hover;
  }
}


@include mq(medium-up){
  .cart-summary__large__container {
    @include single-transition(all, $speed: 200ms);
    visibility: hidden; // fixes bug so browser doesn't let visitor tab to off screen content with form elements
    display: block;
    width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    max-height: 100%;
    top: 0;
    margin: auto 0 auto auto;
    padding-bottom: $app-grid__column-gutter / 2;
    right: -300px;
    background: $app-cart-summary__large__container__background;
    border-bottom: $app-cart-summary__large__container__border;
    border-left: $app-cart-summary__large__container__border;
    border-bottom-left-radius: $app-cart-summary__large__container__border-radius;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transform: translateX(0);
    z-index: 3;
    &--open {
      visibility: visible;
      @include shadows(2);
      transform: translateX(-100%);
    }
  }
}
