.spotlight__products{
  &__section{

  }
  &__container{
    padding-top: $app-grid__column-gutter / 2;
    margin-bottom: $app-grid__column-gutter;
  }
  &__row,
  &__video__row,
  &__candidate__row{
    display: flex;
    align-items: center;
    //padding-top: $app-grid__column-gutter / 2;
    padding-bottom: $app-grid__column-gutter / 2;
  }
  &__video__row{
    flex-direction: column-reverse;
  }
  &__candidate__row{
    align-items: flex-start;
  }
  &__candidate__row--show-after-vote{
    display: none;
    @at-root .ballot-cast &{
      display: flex;
    }
  }
  &__hero__video__column,
  &__description__column,
  &__ballot__column,
  &__ballot__description__column{
    flex: 1 1 auto;
    @include grid-column-padding();
  }
  &__hero__video__column{
    width: 100%;
  }
  &__hero__video__container{
    border: 1px solid $base__light-gray__border-color;
  }
  &__description__column,
  &__ballot__description__column{
    flex: 0 1 25%;
    @include grid-column-padding();
  }
  &__ballot__description__column{
    align-self: flex-start;
    display: none;
  }
  &__candidate__row{

  }
  &__candidate__column{
    flex: 1 1 auto;
    @include grid-column-padding();
  }
  &__candidate__name,
  &__candidate__vote__count{
    font-size: rem-calc(20);
    margin-bottom: 0;
    padding: rem-calc(9) $app-grid__column-gutter / 2;
  }
  &__candidate__voting__row{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: $app-grid__column-gutter / 2;
    flex-direction: column-reverse;
  }
  &__candidate__vote__btn{
    background-color: #ffffff;
    font-size: rem-calc(25);
    font-weight: $base__font-weight--bold;
    padding: 0; //rem-calc(5) rem-calc(15) rem-calc(6) rem-calc(15);
    margin: auto;
    &__heading{
      display: block;
      font-size: rem-calc(14);
      font-weight: $base__font-weight--medium;
      padding: rem-calc(3) rem-calc(15) rem-calc(4) rem-calc(15);
      background-color: $base__secondary-color;
      border-bottom: 1px solid $base__light-gray__border-color;
    }
    &__content{
      display: block;
      padding: rem-calc(1) rem-calc(15) rem-calc(2) rem-calc(15);
    }
  }
  &__candidate__vote__count__column{
    display: none;
    margin: auto;
    text-align: center;
    background: $base__secondary-color;
    border-top-right-radius: $base__border-radius;
    border-bottom-right-radius: $base__border-radius;
    border-top: 1px solid $base__light-gray__border-color;
    border-right: 1px solid $base__light-gray__border-color;
    border-bottom: 1px solid $base__light-gray__border-color;
  }
  &__candidate__vote__count{
    display: block;
    padding: rem-calc(8)  $app-grid__column-gutter / 2;
  }
  &__candidate__vote__count__description{
    display: block;
    font-size: rem-calc(14);
    font-weight: $base__font-weight--medium;
    padding: rem-calc(3) rem-calc(15) rem-calc(4) rem-calc(15);
    border-top: 1px solid $base__light-gray__border-color;
    background: $base__white;
  }
  &__candidate__logo{
    width: 100%;
    padding: 0 $app-grid__column-gutter / 3;
    margin: $app-grid__column-gutter / 2 auto 0 auto;
  }
  &__candidate__logo__img{

  }
  &__candidate__feature__row{
    //margin-bottom: $app-grid__column-gutter / 2;
  }
  &__candidate__thanks{
    &__row{
      margin-bottom: $app-grid__column-gutter / 2;
    }
    &__heading{
      font-weight: $base__font-weight--bold;
      font-style: italic;
      text-align: center;
    }
    &__subhead{
      text-align: center;
    }
    &__feature,
    &__feature__img,
    &__logo{
      margin: auto;
    }
    &__feature,
    &__logo{
      max-width: rem-calc(275);
      &--smaller{
        max-width: rem-calc(225);
      }
    }
    &__description{

    }
    &__footnote{
      padding-top: $app-grid__column-gutter / 2;
      text-align: center;
      border-top: 1px solid $base__light-gray__border-color;
      color: $base__gray-color;
    }
    &__spokesperson__container{
      margin: auto;
      max-width: rem-calc(275);
      border: 1px solid $base__light-gray__border-color;
      border-radius: $base__border-radius;
    }
    &__spokesperson__img{

    }
  }
}

.ballot-cast,
.show-results{
  & .spotlight__products__candidate__vote__count__column{
    display: block;
    align-self: flex-start;
  }
}
.ballot-cast{
  & .spotlight__products__candidate__vote__btn{
    display: none;
  }
  & .spotlight__products__candidate__vote__count__column{
    border-radius: $base__border-radius;
    border: 1px solid $base__light-gray__border-color;
  }
}
.show-results{
  & .spotlight__products__candidate__vote__count__column{
    display: block;
  }
}

.no-js,
.no-flexbox{
  &__row,
  &__video__row,
  &__candidate__row{
    @include clearfix();
  }
  &__candidate__column{
    @include grid-column(6, $padding: false);
  }
}

.spotlight__products__candidate__vote__btn{
  @include single-transition(all);
  animation-duration: 1s;
  animation-fill-mode: both;
  &.animated,
  &:hover,
  &:focus{
    background-color: $base__secondary-color--dark;
    border-color: shade($base__secondary-color--dark, $base__color-shift);
    & .spotlight__products__candidate__vote__btn__content{
      color: $base__white;
    }
  }
  &.animated{
    animation-name: spotlightProductsCandidateTada;
  }
  &:hover,
  &:focus{
    transform: scale3d(1.07, 1.07, 1.07);
  }
}

@keyframes spotlightProductsCandidate {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes spotlightProductsCandidateTada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@include mq(medium-up) {
  .spotlight__products {
    &__video__row,
    &__candidate__voting__row{
      flex-direction: row;
    }
    &__ballot__description__column{
      display: block;
    }
    &__candidate__vote__btn,
    &__candidate__vote__count__column{
      margin: 0;
    }
    &__candidate__logo{
      width: 50%;
      padding: 0 $app-grid__column-gutter / 2;
      margin: 0;
    }
  }
  .no-js,
  .no-flexbox {
    .spotlight__products{
      &__hero__video__column,
      &__hero__ballot__column{
        @include grid-column(8, $padding: false);
      }
      &__description__column,
      &__ballot__description__column{
        @include grid-column(4, $padding: false);
      }
    }
  }
}
@include mq(large-up) {
  .spotlight__products{}
}