$app-header-main__search__input__background-color: $app-header-main__background--color !default;
$app-header-main__search__input__background: $app-header-main__search__input__background-color !default;
$app-header-main__search__input__color: $base__heading__color--dark !default;
$app-header-main__search__input__color--placeholder: null !default;
$app-header-main__search__input__font-weight: $base__font-weight--light !default;
$app-header-main__search__input__font-size: rem-calc(20), rem-calc(20), rem-calc(25) !default;
$app-header-main__search__input__font-size--placeholder: rem-calc(14), rem-calc(14), rem-calc(16) !default;

$app-header-main__search__submit-button__background-color: #eee7cc !default;
$app-header-main__search__submit-button__background: $app-header-main__search__submit-button__background-color !default;
$app-header-main__search__submit-button__background-color--hover: #986e3d !default;
$app-header-main__search__submit-button__background--hover: $app-header-main__search__submit-button__background-color--hover !default;
$app-header-main__search__submit-button__border-color: #ccb59b !default;
$app-header-main__search__submit-button__border: 1px solid $app-header-main__search__submit-button__border-color !default;
$app-header-main__search__submit-button__border-radius: $base__border-radius !default;
$app-header-main__search__submit-button__color: #603609 !default;
$app-header-main__search__submit-button__color--hover: #ffffff !default;

.header__main__search{
  &__form, &__input{
    height: 100%;
    width: 100%;
    margin: 0;
  }
  &__form__close__link{
    display: block;
    visibility: hidden;
    @include icon-close($app-header-main__toolbar__toggle__stroke-size,
            $app-header-main__toolbar__toggle__width,
            $app-header-main__toolbar__toggle__color,
            rem-calc(30)
    );
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: rem-calc(50);
  }
  @at-root .header__main__search__column--show-for-small .header__main__search__form__close__link{
    visibility: visible;
  }
}
input[type="search"].header__main__search__input{
  background: $app-header-main__search__input__background;
  color: $app-header-main__search__input__color;
  margin: 0;
  padding: rem-calc(12);
  border: none;
  outline: 0;
  font-size: nth($app-header-main__search__input__font-size, 1);
  line-height: 0;
  font-weight: $app-header-main__search__input__font-weight;
  &:focus{
    border: none;
    box-shadow: none;
  }
  &::placeholder{
    color: $app-header-main__search__input__color--placeholder;
    font-size: nth($app-header-main__search__input__font-size--placeholder, 1);
    text-transform: uppercase;
    vertical-align: middle;
    line-height: 2.5;
    text-overflow: ellipsis;
    padding: 0;
  }
  &::-moz-placeholder{
    color: $app-header-main__search__input__color--placeholder;
    font-size: nth($app-header-main__search__input__font-size--placeholder, 1);
    text-transform: uppercase;
    vertical-align: middle;
    line-height: 2.8;
    text-overflow: ellipsis;
    padding: 0;
  }
  &:-webkit-autofill,
  &input:-webkit-autofill:hover,
  &input:-webkit-autofill:focus{
    background-color: $app-header-main__search__input__background;
    transition: background-color 600000s 0s, color 600000s 0s;
    border: none;
    -webkit-text-fill-color: $app-header-main__search__input__color;
    -webkit-box-shadow: 0 0 0 1000px $app-header-main__search__input__background inset;
  }
}

.flexbox .header__main__search{
  &__form, &__input{
    flex: 1;
  }
}
@include mq(medium-up){
  .header__main__search{
    &__form__close__link{
      display: none;
    }
    @at-root .header__main__search__column--show-for-small .header__main__search__form__close__link{
      display: block;
    }
  }
  input[type="search"].header__main__search__input{
    display: inline-block;
    &::-moz-placeholder{
      line-height: 4.0;
    }
  }
}
@include mq(medium-alt-up){
  .header__main__search{
    &__form__close__link{
      display: none;
    }
  }
  input[type="search"].header__main__search__input{
    padding: rem-calc(15) rem-calc(12);
    font-size: nth($app-header-main__search__input__font-size, 2);
    &::placeholder{
      font-size: nth($app-header-main__search__input__font-size--placeholder, 2);
    }
    &::-moz-placeholder{
      font-size: nth($app-header-main__search__input__font-size--placeholder, 2);
      line-height: 3.5;
    }
  }
}
@include mq(large-up){
  input[type="search"].header__main__search__input{
    padding: rem-calc(20) rem-calc(12);
    font-size: nth($app-header-main__search__input__font-size, 3);
    &::placeholder{
      font-size: nth($app-header-main__search__input__font-size--placeholder, 3);
    }
    &::-moz-placeholder{
      font-size: nth($app-header-main__search__input__font-size--placeholder, 3);
      line-height: 2.5;
    }
  }
}
