.cart__product-recommendations .product-recommendations{
  &__list{
    &-item{
    }
  }
}

@include mq(medium-up){
  .cart__product-recommendations .product-recommendations{
    &__list{
      &-item{
      }
    }
  }
}

@include mq(large-up){
  .cart__product-recommendations .product-recommendations{
    &__list{
      &-item{
        @include grid-column( 3, $padding: false, $float: false);
      }
    }
  }
}