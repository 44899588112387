.svg-icon {
    width: 1em;
    height: 1em;
    & path,
    & polygon,
    & rect{
        fill: $base__icon-color;
    }
    & circle {
        stroke: $base__icon-color;
        stroke-width: 1;
    }
    &--checkmark.is-success path,
    &--checkmark.is-success polygon,
    &--checkmark.is-success rect,{
        fill: $base__alt-success-color;
        stroke: $base__alt-success-color;
    }
}
