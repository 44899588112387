.mostad__sign-on__register__notice{
  margin-top: $app-grid__column-gutter;
  padding-top: $app-grid__column-gutter / 2;
  border-top: 1px solid $app-heading__h1__border-color;
  &__headline__text-chunk{
    display: inline-block;
    vertical-align: middle;
  }
  &__headline__logo-container{
    max-width: 6rem;
  }
  &__headline__logo{
    width:100%;
  }
}

.mostad__header__main__logo__column{
  max-width: rem-calc(92);
}

.header__main__logo__img__mostad {
  margin-top: 14px;
  max-width: 80px;
  float: left;
}

.mostad-tenenz-modal {
  width: 100%;
  min-width: 620px;
  display: block;
}

.mostad__tenenz__logo--left {
  margin: 0.2rem 0.4rem;
  width: 100%;
  max-width: 110px;
  height: auto;
  max-height: 50px;
}

.mostad__tenenz__logo--right {
  margin: 0.2rem 0;
  width: 100%;
  max-width: 160px;
  height: auto;
}

.mostad-tenenz-modal__headline {
  line-height: 2em;
}

.mostad-tenenz-modal__list {
  margin-left: 24%;
}

.mostad-tenenz-modal__learn-more {
  text-align: right;
}

.mostad-tenenz-modal__product-search {
  text-align: left;
}

a.header__main__nav__list-item__link--mostad {

}
a.header__main__nav__list-item__link--mostad:hover {
  color: #2562a5;
}

.pulse-nav {
  animation: pulse;
  animation-delay: 100ms;
  animation-duration: 900ms;
  animation-iteration-count: 3;
  transition-timing-function: ease-out;
}

@keyframes pulse {
  0% {
    color: inherit;
  }
  50% {
    color: #2562a5;
  }
  100% {
    color: inherit;
  }
}

.about__mostad__tenenz__heading {
  padding: 1em 0;
  border-bottom: 1px solid #ccb59b;
  margin-bottom: 12px;
}

div.about__mostad__tenenz__heading img {
  max-width: 100%;
  height: auto;
  float: left;
}

div.about__mostad__tenenz__heading img.about_mostad_tenenz--img--mostad {
  height: 100%;
  max-height: 50px;
  width: auto;
  margin: 10px 14px 0 14px;
}

div.about__mostad__tenenz__heading img.about_mostad_tenenz--img--tenenz {
  height: 100%;
  max-height: 60px;
  width: auto;
}

div.about__mostad__tenenz__heading ul {
  margin-top: 0.3em;
}

.about__mostad__tenenz--links {
  text-align: center;
}

.about__mostad__tenenz__heading .btn__alt-blue.about__mostad__tenenz--shopping {
  width: 80%;
  margin-top: 14px;
  padding: 10px;
}

.about__mostad__tenenz--introduction {
  font-size: 110%;
}

.about_mostad_tenenz_faq--container {
  border: 1px solid #cbcbcb;
  border-radius: 1rem;
  background: #fafafa;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

ul.about_mostad_tenenz_faq {
  color: #777777;
  margin: 0 0 0 2rem;
}

ul.about_mostad_tenenz_faq li {
  position: relative;
  margin-bottom: 10px
}

ul.about_mostad_tenenz_faq_detail {
  margin: -1em 0 0 2rem;
}

.column__third-width__medium-up {
  width: 33%;
  float: left;
}

div.toggle-content {
  display: none;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: (height 350ms ease-in-out, opacity 400ms ease-in-out);
}

div.toggle-content.is-visible {
  display: block;
  height: auto;
  opacity: 1;
  margin-bottom: 0.4em;
  margin-left: 1em;
}

div.container__mostad-faq {
  padding: 0.4em 0.4em 0 0.4em;
  border-bottom: 1px solid #ddd;
  margin: auto 1em auto 1em;
}

div.row.dropdown--open {
  padding-left: 18rem;
}

div.container__mostad_product_guide {
  margin-bottom: 1em;
}

div.container__mostad__product__lookup__results {
  padding-top: 1rem;
  border-top: 1px dotted #eeeeee;
}

.mostad-product-lookup__form__input__search__column {
  float: left;
  width: 78%;
}

button.btn__secondary.mostad__product__lookup__form-input--postfix {
  padding-bottom: 12px;
}

.container__mostad-product-lookup__popular-items {
  margin-bottom: 0.4rem;
}

div.mostad-product-lookup__popular-items--heading {
  margin: -2rem auto auto auto;
  font-size: 1.2rem;
  font-weight: 100;
  font-style: italic;
  color: #777777;
  background-color: #ffffff;
  width: auto;
  max-width: 40%;
  min-width: 30%;
}

.container__mostad-product-lookup__popular-items p {
  padding-top: 0.6rem;
  font-size: 1.1rem;
  font-style: italic;
  color: #555555;
  font-weight: 100;
}

.mostad-product-lookup__popular-items {
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  padding: 0.8rem 0.4rem 0.6rem 0.4rem;
}

.mostad-product-lookup__form__input--search {
  border-radius: 3px 0 0 3px;
}


@media (max-width: 640px) {

  div.row.dropdown--open {
    padding-left: 0;
  }

}

@media (max-width: 700px) {

  .mostad-product-lookup__form__input__search__column {
    width: 100%;
  }

  .mostad-product-lookup__popular-items .btn {
    width: 100%;
  }

  form#mostad-product-guide .mostad-product-lookup__form__input--search {
    clear: both;
    width: 100%;
    min-width: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  form#mostad-product-guide .btn__secondary__form-input--postfix {
    clear: both;
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #a7cae2;
  }

}

@media (max-width: 705px) {

  .mostad-product-lookup__form__input__search__column {
    width: 100%;
  }

  .mostad-product-lookup__popular-items .btn {
    width: 100%;
  }

  form#mostad-product-guide .mostad-product-lookup__form__input--search {
    clear: both;
    width: 100%;
    min-width: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  form#mostad-product-guide .btn__secondary__form-input--postfix {
    clear: both;
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #a7cae2;
  }

}

@media (max-width: 994px) {

  div.row.dropdown--open .mostad-product-lookup__form__input__search__column {
    width: 100%;
  }

  div.row.dropdown--open .mostad-product-lookup__popular-items .btn {
    width: 100%;
  }

  div.row.dropdown--open form#mostad-product-guide .mostad-product-lookup__form__input--search {
    clear: both;
    width: 100%;
    min-width: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  div.row.dropdown--open form#mostad-product-guide .btn__secondary__form-input--postfix {
    clear: both;
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #a7cae2;
  }

  .column__third-width__medium-up {
    width: 100%;
    float: left;
  }

  .mostad-tenenz-modal {
    min-width: 100px;
  }

  div.modal__content img.mostad__tenenz__logo--left,
  div.modal__content img.mostad__tenenz__logo--right,
  div.about__mostad__tenenz__heading img.about_mostad_tenenz--img--left {
    display: none;
  }

  div.modal__content h3 {
    line-height: 1.3em;
  }

  div.container__mostad-faq {
    margin: 0;
  }

  p.mostad-tenenz-modal__learn-more, p.mostad-tenenz-modal__product-search {
    text-align: center;
  }

  div.mostad-product-lookup__popular-items--heading {
    min-width: 80%;
    max-width: 90%;
  }

  .mostad-product-lookup__popular-items {
    margin-bottom: 1rem;
  }

  .container__mostad-product-lookup__popular-items a {
    margin: 0.5rem auto;
  }

  .mostad__product__lookup__form__input__search__column {
    float: left;
    width: 70%;
  }

}

@media (min-width: 1800px) {

  div.row.dropdown--open {
    padding-left: 0;
  }

}