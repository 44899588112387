.account-register{
  &__column{

  }
  &__form{
    &-container{

    }
  }
}

@include mq(medium-up){
  .account-register{
    &__column{
      @include grid-column(7);
    }
    &__form{
      &-container{

      }
    }
  }
}

@include mq(large-up){
  .account-register{
    &__column{
    }
    &__form{
      &-container{

      }
    }
  }
}