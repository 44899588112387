@mixin label(
  $font-family: $app-label__font-family,
  $font-size: $app-label__font-size,
  $padding: $app-label__padding,
  $border-radius: $app-label__border-radius
){
  font-weight: normal;
  font-family: $font-family;
  font-size: $font-size;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-bottom: inherit;
  padding: $padding;
  border-radius: $border-radius;
}