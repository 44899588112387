$app-product-list__product-preview__bottom-arrow__height: 30px !default;
$app-product-list__product-preview__bottom-arrow__border-width: 2px !default;
$app-product-list__product-preview__bottom-arrow__border-color: #b0c2cf !default;
$app-product-list__product-preview__bottom-arrow__background-color: #f0f6fa !default;
$app-product-list__product-preview__product-image__link__padding: rem-calc(16) !default;
$app-product-list__product-preview__product-image__link__border: 1px solid #b9b9b9 !default;
$app-product-list__product-preview__product-image__link__border-radius: $base__border-radius !default;
$app-product-list__product-preview__product-image__link__background-color: #ffffff !default;
$app-product-list__product-preview-expander__content__padding: rem-calc(10) rem-calc(30) !default;
$app-product-list__product-preview-expander__content__max-width: $app-grid__row-width !default;
$app-product-list__product-preview-expander__content__background-color: #f0f6fa !default;
$app-product-list__product-preview-expander__content__border-top-color: #d8d8d8 !default;
$app-product-list__product-preview-expander__content__border-top: 2px solid $app-product-list__product-preview-expander__content__border-top-color !default;
$app-product-list__product-preview-expander__content__border-bottom-color: #C7C7C7 !default;
$app-product-list__product-preview-expander__content__border-bottom: 1px solid $app-product-list__product-preview-expander__content__border-bottom-color !default;
$app-product-list__product-preview__action-button__color: #a7cae2 !default;
$app-product-list__product-preview__action-button__color--hover: #588bae !default;
$app-product-list__product-preview__action-button__background: null !default;
$app-product-list__product-preview__action-button__background--hover: null !default;
$app-product-list__product-preview__action-button-disabled__color: #cccccc !default;
$app-product-list__product-preview__action-button-disabled__color--hover: #cccccc !default;
$app-product-list__product-preview__action-button-close__font-size: rem-calc(16) !default;
$app-product-list__product-preview__action-button-close__padding-left: rem-calc(5.6) !default;
$app-product-list__product-preview__action-button-close__padding-right: rem-calc(7.2) !default;
$app-product-list__product-preview__action-button-close__large__font-size: rem-calc(20) !default;
$app-product-list__product-preview__action-button-close__large__padding-left: rem-calc(25) !default;
$app-product-list__product-preview__action-button-close__large__padding-right: rem-calc(25) !default;
$app-product-list__product-preview__action-button-icon__font-size: rem-calc(96) !default;
$app-product-list__product-preview__action-button-icon__width: rem-calc(22.4) !default;
$app-product-list__product-preview__action-button-icon__left: - rem-calc(40) !default;
$app-product-list__product-preview__action-button-icon__large__font-size: rem-calc(128) !default;
$app-product-list__product-preview__action-button-icon__large__width: rem-calc(64) !default;
$app-product-list__product-preview__action-button-icon__large__left: - rem-calc(38) !default;
$app-product-list__product-preview__action-button__add-to-cart__background-color: #a7cae2 !default;
$app-product-list__product-preview__action-button__add-to-cart__background-color--hover: #588bae !default;
$app-product-list__product-preview__action-button__add-to-cart__border-color: #588bae !default;
$app-product-list__product-preview__action-button__add-to-cart__border: 1px solid $app-product-list__product-preview__action-button__add-to-cart__border-color !default;

.product-list__preview__bottom-arrow{
  display: none;
  position: relative;
}

.product-list__preview--expanded {
  height: auto;
  .product-list__preview__bottom-arrow {
    display: block;
    top: 12px;
    z-index: 2;
    @include triangle(
                    top,
                    50%,
            $height: $app-product-list__product-preview__bottom-arrow__height,
            $border-width: $app-product-list__product-preview__bottom-arrow__border-width,
            $color: $app-product-list__product-preview__bottom-arrow__background-color,
            $border-color: $app-product-list__product-preview__bottom-arrow__border-color
    );
  }
  & .product-list-preview-expander {
    height: auto;
    left: 0;
  }
}

.product-list-preview-expander{
  height: 0;
  width: 100%;
  position: absolute;
  top: auto;
  left: -999rem;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
  z-index: 1;
}

.product-list-preview-expander-content{
  @include clearfix();
  padding: $app-product-list__product-preview-expander__content__padding;
  height: 100%;
  position: relative;
  margin: auto;
  max-width: $app-product-list__product-preview-expander__content__max-width;
  background:$app-product-list__product-preview-expander__content__background-color;
  border-top: $app-product-list__product-preview-expander__content__border-top;
  border-bottom: $app-product-list__product-preview-expander__content__border-bottom;
}

.product-list__product-preview__product-display__column,
.product-list__product-preview__product-information__column{
  @include grid-column-padding();
}
.product-list__product-preview__product-display__column{
  margin-bottom: $app-grid__column-gutter / 2;
}

.product-list__product-preview__form__quantity-input{

}
.product-list__product-preview__form__submit-button{

}

%product-list__product-preview__action-button{
  @include single-transition(all);
  position: absolute;
  top: 0;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  color: $app-product-list__product-preview__action-button__color;
  background: $app-product-list__product-preview__action-button__background;
  &:hover{
    color: $app-product-list__product-preview__action-button__color--hover;
    background: $app-product-list__product-preview__action-button__background--hover;
  }
  &:before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    font-size: 0;
  }
}
%product-list__product-preview__action-button--disabled{
  color: $app-product-list__product-preview__action-button-disabled__color;
  &:hover, &:focus{
    color: $app-product-list__product-preview__action-button-disabled__color--hover;
  }
}
.product-list__product-preview__action-button--previous{
  @extend %product-list__product-preview__action-button;
  left: 0;
  bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid transparent;
  &:hover,&:focus{
    border-right-color: shade($app-product-list__product-preview__action-button__background--hover, $base__color-shift / 2);
  }
}
.disabled.product-list__product-preview__action-button--previous {
  @extend %product-list__product-preview__action-button--disabled;
}
.product-list__product-preview__action-button--next{
  @extend %product-list__product-preview__action-button;
  right: 0;
  bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border-left: 1px solid transparent;
  &:hover,&:focus{
    border-left-color: shade($app-product-list__product-preview__action-button__background--hover, $base__color-shift / 2);
  }
}
.disabled.product-list__product-preview__action-button--next {
  @extend %product-list__product-preview__action-button--disabled;
}
.product-list__product-preview__action-button--close{
  @extend %product-list__product-preview__action-button;
  color: $app-btn__secondary__font-color;
  right: 0;
  font-size: $app-product-list__product-preview__action-button-close__font-size;
  padding-left: $app-product-list__product-preview__action-button-close__padding-left;
  padding-right: $app-product-list__product-preview__action-button-close__padding-right;
  border-right: $app-btn__secondary__border;
  border-bottom: $app-btn__secondary__border;
  border-left: $app-btn__secondary__border;
  border-bottom-left-radius: $base__border-radius;
  background: $app-btn__secondary__background;
  &:hover, &:focus{
    color: shade($app-btn__secondary__font-color, $base__color-shift);
    background: $app-btn__secondary__background--hover;
  }
}
.product-list__product-preview__action-button-icon{
  font-size: $app-product-list__product-preview__action-button-icon__font-size;
  width: $app-product-list__product-preview__action-button-icon__width;
  position: relative;
  left: $app-product-list__product-preview__action-button-icon__left;
  display: inline-block;
}
.product-list__product-preview__action-button__add-to-cart{
  background: $app-product-list__product-preview__action-button__add-to-cart__background-color;
  border: $app-product-list__product-preview__action-button__add-to-cart__border;
  border-left: 0;
  width: 100%;
  &:hover, &:focus{
    background: $app-product-list__product-preview__action-button__add-to-cart__background-color--hover;
  }
}

.product-list__product-preview__image__link{
  display: block;
  margin-bottom: $app-grid__column-gutter/4;
  padding: $app-product-list__product-preview__product-image__link__padding;
  border: $app-product-list__product-preview__product-image__link__border;
  border-radius: $app-product-list__product-preview__product-image__link__border-radius;
  background: $app-product-list__product-preview__product-image__link__background-color;
  &__image{

  }
}

@include mq(medium-up) {
  .product-list__product-preview__medium-up__2-columns{
    .product-list__product-preview__product-display__column{
      @include grid-column(5, $padding: false, $push: 1);
      margin-bottom: 0;
    }
    .product-list__product-preview__product-information__column{
      @include grid-column(5, $padding: false, $pull: 1, $float: right);
    }
  }

  .product-list__product-preview__action-button--close{
    font-size: $app-product-list__product-preview__action-button-close__large__font-size;
    padding-left: $app-product-list__product-preview__action-button-close__large__padding-left;
    padding-right: $app-product-list__product-preview__action-button-close__large__padding-right;
  }
  .product-list__product-preview__action-button-icon{
    font-size: $app-product-list__product-preview__action-button-icon__large__font-size;
    width: $app-product-list__product-preview__action-button-icon__large__width;
    left: $app-product-list__product-preview__action-button-icon__large__left;
  }
}

@include mq(large-up){

}