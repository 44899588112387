$app-pagination__list-item__color: #222222 !default;
$app-pagination__list-item__font-size: rem-calc(14) !default;
$app-pagination__list-item__margin-left: rem-calc(5) !default;
$app-pagination__link__padding: rem-calc(1) rem-calc(10) !default;
$app-pagination__link__color: #999999 !default;
$app-pagination__link__font-size: $app-pagination__list-item__font-size !default;
$app-pagination__link__cursor: pointer !default;
$app-pagination__link__background-color: transparent !default;
$app-pagination__link__background-color--hover: #e6e6e6 !default;
$app-pagination__link-current__color: #ffffff !default;
$app-pagination__link-current__background-color: #986e3d !default;
$app-pagination__link-current__background-color--hover: $app-pagination__link-current__background-color !default;
$app-pagination__link-disabled__background-color--hover: transparent !default;

.pagination{

}

.pagination__flex__list{
  display: flex;
  list-style: none;
  margin-left: 0;
  border: 1px solid $base__light-gray-color;
  border-radius: $base__border-radius;
  &-item{
    border-right: 1px solid $base__light-gray__border-color;
    &:last-child{
      border-right: none;
    }
  }
  &-item__link{
    @include single-transition(background-color);
    display: block;
    padding: .3rem 1rem;
    color: $app-pagination__link__color;
    background: $app-pagination__link__background-color;
    font-weight: normal;
    font-size: $app-pagination__link__font-size;
    line-height: inherit;
    &:focus, &:hover{
      cursor: $app-pagination__link__cursor;
      background: $app-pagination__link__background-color--hover;
    }
    &--active{
      color: shade($app-pagination__link__color, $base__color-shift);
      background: #eeeeee;
    }
    @at-root .pagination__flex__list-item--disabled .pagination__flex__list-item__link{
      opacity: .5;
      &:hover,
      &focus{
        cursor: not-allowed;
      }
    }
  }
}

.pagination__list{
  list-style: none;
  margin-left: 0;
  overflow: hidden;
  &-item{
    float: left;
    height: rem-calc(24);
    color: $app-pagination__list-item__color;
    font-size: $app-pagination__list-item__font-size;
    margin-left: $app-pagination__list-item__margin-left;
    display: block;
    &:first-child{
      margin-left: 0;
    }
    &--previous{

    }
    &--next{

    }
    &--current{

    }
    &--disabled {

    }
    &--description{

    }
  }
}

.pagination__link{
  display: block;
  padding: $app-pagination__link__padding;
  color: $app-pagination__link__color;
  background: $app-pagination__link__background-color;
  border-radius: $base__border-radius;
  font-weight: normal;
  font-size: $app-pagination__link__font-size;
  line-height: inherit;
  @include single-transition(background-color);
  &:hover,
  &:focus{
    cursor: $app-pagination__link__cursor;
    background: $app-pagination__link__background-color--hover;
  }
  &--disabled{
    color: tint($app-pagination__link__color, $base__color-shift);
    &:hover,
    &:focus{
      cursor: default;
      color: tint($app-pagination__link__color, $base__color-shift);
      background: $app-pagination__link-disabled__background-color--hover;
    }
  }
  &-page {
    @extend .pagination__link;
    &--current {
      color: $app-pagination__link-current__color;
      background: $app-pagination__link-current__background-color;
      &:hover,
      &:focus{
        cursor: default;
        color: $app-pagination__link-current__color;
        background: $app-pagination__link-current__background-color--hover;
      }
    }
  }
  &-previous{
    @extend .pagination__link;
    &--disabled{
      @extend .pagination__link--disabled;
    }
  }
  &-next{
    @extend .pagination__link;
    &--disabled{
      @extend .pagination__link--disabled;
    }
  }
}