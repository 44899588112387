/*.product-category__vertical-theme.product-category__content {
  &-130{
    & .product-category__header__text-container{
      position: absolute;
      top: $app-grid__column-gutter / 2;
      left: $app-grid__column-gutter;
    }
    & .product-category__header__headline {
      max-width: rem-calc(500);
      font-size: nth($app-heading__h1__font-sizes, 1);
      line-height: nth($app-heading__h1__font-sizes, 1);
      margin-bottom: $app-grid__column-gutter / 2;
      border-bottom: none;
    }
    & .product-category__header__subhead {
      max-width: rem-calc(200);
      font-size: nth($app-heading__h4__font-sizes, 1);
      line-height: nth($app-heading__h4__font-sizes, 1);
    }
  }
}
*/
.product-category__content{
  &-130 {
    & .product-category__sub-categories__list-item__link__name {
      font-size: rem-calc(24);
    }
    & .product-category__sub-category-rows__list-item__subcategory__link__name{
      display: none;
    }
  }
}

@include mq(medium-up){
  .product-category__vertical-theme.product-category__content {
    &-130{
      /*
      & .product-category__header__headline {
        max-width: rem-calc(500);
        font-size: nth($app-heading__h1__font-sizes, 2);
        line-height: nth($app-heading__h1__font-sizes, 2);
      }
      & .product-category__header__subhead {
        max-width: rem-calc(200);
        font-size: nth($app-heading__h4__font-sizes, 2);
        line-height: nth($app-heading__h4__font-sizes, 2);
      }
      */
    }
  }
  .product-category__content{
    &-130 {
      & .product-category__sub-category-rows__list-item{
        //@include clearfix();
      }
      & .product-category__sub-category-rows__list-item__subcategory__column{
        text-align: right;
        width: 215px;
        float: left;
      }
      & .product-category__sub-category-rows__list-item__child-category__column{
        float: none;
        margin-left: 215px;
        width: auto;
      }
      & .product-category__sub-category-rows__list-item__child-category__name{
        @include grid-column(6);
        text-align: center;
      }
    }
  }
}

@include mq(large-up){
  .product-category__vertical-theme.product-category__content {
    &-130{
      /*
      & .product-category__header__headline {
        max-width: rem-calc(500);
        font-size: nth($app-heading__h1__font-sizes, 2);
        line-height: nth($app-heading__h1__font-sizes, 2);
      }
      & .product-category__header__subhead {
        max-width: rem-calc(200);
        font-size: nth($app-heading__h4__font-sizes, 2);
        line-height: nth($app-heading__h4__font-sizes, 2);
      }
      */
    }
  }
  .product-category__content{
    &-130 {
      & .product-category__sub-category-rows__list-item__child-category__name{
        @include grid-column(3, $padding: false);
      }
    }
  }
}
