$app-tab__list__border-color: #986e3d !default;
$app-tab__list__border: 1px solid $app-tab__list__border-color !default;
$app-tab__list__box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.4) !default;
$app-tab__list-item__border-color: $app-tab__list__border-color !default;
$app-tab__list-item__border: 1px solid $app-tab__list-item__border-color !default;
$app-tab__list-item__link__border-radius: $base__border-radius !default;
$app-tab__list-item__link__background: null !default;
$app-tab__list-item__link__color: null !default;
$app-tab__list-item__link__background--open: #986e3d !default;
$app-tab__list-item__link__color--open: #ffffff !default;


.tab{
  &__list{
    overflow: hidden;
    border-bottom: $app-tab__list__border;
    box-shadow: $app-tab__list__box-shadow;
    position: relative;
    margin: 0;
    list-style: none;
    &-item{
      float: left;
      border-left: $app-tab__list-item__border;
      &:first-child{
        border-left: none;
      }
      &--open,
      &--open + & {
        border-left: none;
      }
    }
    &-item__link{
      @include single-transition('background');
      padding: .5rem 1.385rem;
      display: block;
      border-top-right-radius: $app-tab__list-item__link__border-radius;
      border-top-left-radius: $app-tab__list-item__link__border-radius;
      background: $app-tab__list-item__link__background;
      color: $app-tab__list-item__link__color;
      @at-root .tab__list-item--open .tab__list-item__link{
        background: $app-tab__list-item__link__background--open;
        color: $app-tab__list-item__link__color--open;
      }
      &--open{

      }
    }
  }
  &__content{
    display: none;
    &--open{
      display: block;
    }
    &--seo-friendly{
      @include element-invisible();
      & .tab__content--open{
        @include element-invisible-off();
      }
    }
  }
}