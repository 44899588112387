$app-confirm-it__dialog__background-color: rgba(255,255,255, .8) !default;
$app-confirm-it__dialog__background: $app-confirm-it__dialog__background-color !default;
$app-confirm-it__dialog__padding: $app-grid__column-gutter / 2 !default;
$app-confirm-it__footer__margin: ($app-grid__column-gutter / 4) auto !default;
$app-confirm-it__button__padding: rem-calc(8) rem-calc(16) !default;
$app-confirm-it__button__side-margin: $app-grid__column-gutter / 2 !default;

.confirm-it__dialog{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $app-confirm-it__dialog__background;
  padding: $app-confirm-it__dialog__padding;
  text-align: center;
}

.confirm-it__content{

}

.confirm-it__footer{
  margin: $app-confirm-it__footer__margin;
}

.confirm-it__footer__link-yes,
.confirm-it__footer__link-cancel{
  @include button-base($padding: $app-confirm-it__button__padding);
}

.confirm-it__footer__link-yes{
  border: $app-btn__primary__border;
  background: $app-btn__primary__background;
  color: $app-btn__primary__font-color;
  margin-right: $app-confirm-it__button__side-margin;
  &:hover, &:focus{
    color: $app-btn__primary__font-color;
    background: $app-btn__primary__background--hover;
  }
}

.confirm-it__footer__link-cancel{
  border: $app-btn__secondary-outline__border;
  color: $app-btn__secondary-outline__font-color;
  &:hover, &:focus{
    color: shade($app-btn__secondary-outline__font-color, $base__color-shift);
    border-color: $app-btn__secondary-outline__border-color--hover;
    background: $app-btn__secondary-outline__background--hover;
  }
}
