$app-testimonial__quote__font-family: null !default;
$app-testimonial__quote__font-style: null !default;
$app-testimonial__quote__font-size: null !default;
$app-testimonial__quote__font-weight: null !default;
$app-testimonial__quote__line-height: null !default;
$app-testimonial__quote__color: null !default;
$app-testimonial__quote__border-left-color: null !default;
$app-testimonial__quote__border-left: null !default;
$app-testimonial__quote__padding-left: null !default;
$app-testimonial__quote__margin: null !default;
$app-testimonial__quote__mark__font-family: null !default;
$app-testimonial__quote__mark__font-weight: null !default;
$app-testimonial__quote__mark__font-style: null !default;
$app-testimonial__quote__mark__font-size: null !default;
$app-testimonial__quote__mark__line-height: null !default;
$app-testimonial__author__color: null !default;

.testimonial{
  &__quote{
    margin: $app-testimonial__quote__margin;
    font-family: $app-testimonial__quote__font-family;
    font-size: nth($app-testimonial__quote__font-size, 1);
    font-weight: $app-testimonial__quote__font-weight;
    font-style: $app-testimonial__quote__font-style;
    line-height: nth($app-testimonial__quote__line-height, 1);
    color: $app-testimonial__quote__color;
    border-left: $app-testimonial__quote__border-left;
    padding-left: $app-testimonial__quote__padding-left;
  }
  &__quote__mark{
    font-family: $app-testimonial__quote__mark__font-family;
    font-weight: $app-testimonial__quote__mark__font-weight;
    font-style: $app-testimonial__quote__mark__font-style;
    font-size: $app-testimonial__quote__mark__font-size;
    line-height: $app-testimonial__quote__mark__line-height;
  }
  &__author{
    padding-right: $app-grid__column-gutter / 2;
    text-align: right;
    color: $app-testimonial__author__color;
  }
}

@include mq(medium-up){
  .testimonial {
    &__quote {
      font-size: nth($app-testimonial__quote__font-size, 2);
      line-height: nth($app-testimonial__quote__line-height, 2);
    }
  }
}

@include mq(large-up){
  .testimonial {
    &__quote {
      font-size: nth($app-testimonial__quote__font-size, 3);
      line-height: nth($app-testimonial__quote__line-height, 3);
    }
  }
}
