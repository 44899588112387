.data-list__tool-bar{
  @include clearfix();
  margin-bottom: rem-calc(8);
}
.data-list__search-input,
.data-list__select--records-per-page{
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
.data-list__search-input[type="search"]{
  width: 35%;
}
select.data-list__select--records-per-page{
  width: 20%;
}
.data-list__button--previous, .data-list__button--next{
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
}
.data-list__sort{
  position: relative;
  &.asc:before, &.desc:before{
    @include icon-font-defaults($font-size: 2.3em, $line-height: 0);
    position: absolute;
    top: rem-calc(8);
    right: 0;
    display: block;
  }
  &.asc:before{
    content: "\e002";
  }
  &.desc:before{
    content: "\e003";
  }
}
