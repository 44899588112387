$app-body__content__min-width: null !default;

.body-content{
  min-width: $app-body__content__min-width;
  overflow: hidden;
}
.column__full-width{
  @include grid-column-padding();
  width: 100%;
}
.column__half-width__medium-up,
.column__quarter-width__medium-up{
  padding-left: $app-grid__column-gutter / 2;
  padding-right: $app-grid__column-gutter / 2;
}


.row{
  @extend %extended_clearfix;
  width: 100%;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  &--padded{
    @extend %extended_clearfix;
    @include grid-column-padding();
    width: 100%;
    margin: 0;
  }
  &--max-width{
    @extend %extended_clearfix;
    max-width: $app-grid__row-width;
    margin-left: auto;
    margin-right: auto;
  }
}

@include mq('medium-up'){
  .column__half-width__medium-up{
    @include grid-column(6, $padding: false)
  }
  .column__quarter-width__medium-up{
    @include grid-column(3, $padding: false)
  }

  .row--flex, .flex__row{
    display: flex;
  }
  .flex {
      &--centered {
          justify-content: center;
          align-items: center;
      }
  }
    .flex--v-center{
        align-items: center;
    }
}
