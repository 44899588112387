.page-feature{
    &__column{

    }
    &__list{
        list-style:none;
        padding-left: 1em;
        margin-left: 0;
    }
    &__list-item{
        &:before{
            content: '';
            display: inline-block;
            height: 1em;
            width: 1em;
            background-image: url('/assets/img/style/icon-checkmark-2.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin-right:0.5em;
        }
    }
}
