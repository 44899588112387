$app-checkout__progress__list__padding: rem-calc(9) rem-calc(14) rem-calc(9) !default;
$app-checkout__progress__list__border-color: #D8D8D8 !default;
$app-checkout__progress__list__border: 1px solid $app-checkout__progress__list__border-color !default;
$app-checkout__progress__list__border-radius: $base__border-radius * 2 !default;
$app-checkout__progress__list__background-color: #f0f6fa !default;
$app-checkout__progress__list__background: $app-checkout__progress__list__background-color !default;
$app-checkout__progress__list-item__border-color: $app-checkout__progress__list__background-color !default;
$app-checkout__progress__list-item__border: 1px solid $app-checkout__progress__list-item__border-color !default;
$app-checkout__progress__list-item__border-right-color: $base__secondary-color--dark !default;
$app-checkout__progress__list-item__border-right: 1px solid $app-checkout__progress__list-item__border-right-color !default;
$app-checkout__progress__list-item__small__border-bottom-color: $app-checkout__progress__list__border-color !default;
$app-checkout__progress__list-item__small__border-bottom: 1px solid $app-checkout__progress__list-item__small__border-bottom-color !default;
$app-checkout__progress__list-item--current__border-color: $base__primary-color !default;
$app-checkout__progress__list-item--current__border: 1px solid $app-checkout__progress__list-item--current__border-color !default;
$app-checkout__progress__list-item--completed__border-color: #bcd6df !default;
$app-checkout__progress__list-item__link__padding: rem-calc(10) rem-calc(20) !default;
$app-checkout__progress__list-item__link__font-size: null !default;
$app-checkout__progress__list-item__link__font-color: #777777 !default;
$app-checkout__progress__list-item__link__font-color--hover: #777777 !default;
$app-checkout__progress__list-item__link--current__font-color: $base__anchor-color !default;
$app-checkout__progress__list-item__link--current__font-color--hover: $base__anchor__font-color--hover !default;
$app-checkout__progress__list-item__link--current__background-color: #ffffff !default;
$app-checkout__progress__list-item__link--current__background: $app-checkout__progress__list-item__link--current__background-color !default;
$app-checkout__progress__list-item__link--current__background--hover: null !default;
$app-checkout__progress__list-item__link__text-decoration--hover: null !default;
$app-checkout__progress__list-item__link--completed__font-color: darken($base__success-color, 15%) !default; // original: #444444
$app-checkout__progress__list-item__link--completed__font-color--hover: shade($app-checkout__progress__list-item__link--completed__font-color, $base__color-shift) !default; // original: #222222
$app-checkout__progress__list-item__link--completed__background-color: null !default; // original: screen($base__success-color, 15%) or #bcd6df
$app-checkout__progress__list-item__link--completed__background: $app-checkout__progress__list-item__link--completed__background-color !default; // original: #bcd6df
$app-checkout__progress__list-item__link--completed__background--hover: $app-checkout__progress__list-item__link--completed__background-color !default; // original: #bcd6df
$app-checkout__progress__list-item__link--completed__border-color: $base__success-color !default; // original: $base__secondary-color--dark
$app-checkout__progress__list-item__link--completed__border: null !default;
$app-checkout__progress__list-item__link--completed__border-right-color: darken($base__success-color, 15%) !default; // original: shade($base__secondary-color--dark, $base__color-shift)
$app-checkout__progress__list-item__link--completed__border-right: 1px solid $app-checkout__progress__list-item__link--completed__border-right-color !default;

//current-step
//completed-step

.checkout__progress__list {
  border-bottom-left-radius: $app-checkout__progress__list__border-radius;
  border-bottom-right-radius: $app-checkout__progress__list__border-radius;
  list-style: none;
  border: $app-checkout__progress__list__border;
  background: $app-checkout__progress__list__background;
  width: 100%;
  margin: 0;
  overflow: hidden; // contain the floats

  &-item {
    margin: 0;
    border: $app-checkout__progress__list-item__border;
    border-bottom: $app-checkout__progress__list-item__small__border-bottom;
    &:first-child{
      border-bottom-left-radius: $app-checkout__progress__list__border-radius;
    }
    &:last-child{
      border-right: none;
    }
    &--current{
      border-color:$app-checkout__progress__list-item--current__border-color;
      &:last-child{
        border-right: $app-checkout__progress__list-item--current__border;
      }
    }
    &--completed{
      border: $app-checkout__progress__list-item__link--completed__border;
    }
  }

  &-item__link{
    display: block;
    padding: $app-checkout__progress__list-item__link__padding;
    font-size: $app-checkout__progress__list-item__link__font-size;
    color: $app-checkout__progress__list-item__link__font-color;
    &:hover, &:focus{
      cursor: default;
      color: $app-checkout__progress__list-item__link__font-color--hover;
      text-decoration: $app-checkout__progress__list-item__link__font-color--hover;
    }
    &--current{
      color: $app-checkout__progress__list-item__link--current__font-color;
      background: $app-checkout__progress__list-item__link--current__background;
      &:hover, &:focus{
        color: $app-checkout__progress__list-item__link--current__font-color--hover;
        cursor: pointer;
        text-decoration: $app-checkout__progress__list-item__link__text-decoration--hover;
      }
    }
    &--completed{
      color: $app-checkout__progress__list-item__link--completed__font-color;
      background: $app-checkout__progress__list-item__link--completed__background;
      &:hover, &:focus{
        color: $app-checkout__progress__list-item__link--completed__font-color--hover;
        background: $app-checkout__progress__list-item__link--completed__background--hover;
        cursor: pointer;
      }
    }
  }
}

@include mq(medium-up){
  .checkout__progress__list {
    &-item {
      float: left;
      border-right: $app-checkout__progress__list-item__border-right;
      border-bottom: $app-checkout__progress__list-item__border;
      &--current{
        border-color:$app-checkout__progress__list-item--current__border-color;
      }
      &--completed{
        border-right: $app-checkout__progress__list-item__link--completed__border-right;
      }
    }
  }
}