.payment__form{
  &__fieldset {
    .form__input {
      height: 42px;
    }
  }
  &__input-list{
    &__item{
      &:last-child{
        margin: 0;
      }
    }
  }
  &__input__row{
    display: flex;
  }
  &__input__column{
    flex: 1 1 auto;
    justify-content: space-between;
    margin-right: $app-grid__column-gutter / 2;
    &:last-child{
      margin-right: 0;
    }
    &__cc-type{
      align-self: flex-end;
      max-width: rem-calc(67);
      min-width: rem-calc(35);
    }
    &__cc-number{}
    &__cc-cvv,
    &__cc-expiration{
      min-width: 5rem;
    }
    &__cc-cvv{}
  }
  &__input-list{
    &__item{
    }
  }
}

.braintree-hosted-fields-invalid {
  &,
  &:focus {
    border: 1px solid $base__alert-color;
    background: #ffeee1;
  }
}

@include mq(medium-up) {
  .payment__form{}
}