$app-btn__border-radius: $base__border-radius !default;
$app-btn__text-decoration--hover: none !default;
$app-btn__padding: rem-calc(5) !default;
$app-btn__font: inherit !default;
$app-btn__font-size: null !default;
$app-btn__transition-speed: $base__transition-speed !default;
$app-btn__transition-ease: $base__transition-ease !default;
$app-btn__primary__font-color: null !default;
$app-btn__primary__border--color: null !default;
$app-btn__primary__border: null !default;
$app-btn__primary__background: null !default;
$app-btn__primary__background--hover: null !default;
$app-btn__primary-outline__font-color: $app-btn__primary__font-color !default;
$app-btn__primary-outline__border-color: $app-btn__primary__border-color !default;
$app-btn__primary-outline__border: 1px solid $app-btn__primary__border-color !default;
$app-btn__primary-outline__background--hover: null !default;
$app-btn__primary-outline__border-color--hover: null !default;
$app-btn__secondary__font-color: null !default;
$app-btn__secondary__border--color: null !default;
$app-btn__secondary__border: null !default;
$app-btn__secondary__background: null !default;
$app-btn__secondary__background--hover: null !default;
$app-btn__secondary-outline__font-color: $app-btn__secondary__font-color !default;
$app-btn__secondary-outline__border-color: $app-btn__secondary__border-color !default;
$app-btn__secondary-outline__border: 1px solid $app-btn__secondary__border-color !default;
$app-btn__secondary-outline__background--hover: null !default;
$app-btn__secondary-outline__border-color--hover: null !default;
$app-btn__alert__background-color: null !default;
$app-btn__alert__font-color: null !default;
$app-btn__alert__border-color: null !default;
$app-btn__alert__border: null !default;
$app-btn__alert__background: null !default;
$app-btn__alert__background--hover: null !default;
$app-btn__alert-outline__font-color: null !default;
$app-btn__alert-outline__font-color--hover: null !default;
$app-btn__alert-outline__border-color: null !default;
$app-btn__alert-outline__border: null !default;
$app-btn__alert-outline__background--hover: null !default;
$app-btn__alert-outline__border-color--hover: null !default;

$app-btn__warning__background-color: null !default;
$app-btn__warning__font-color: null !default;
$app-btn__warning__border-color: null !default;
$app-btn__warning__border: null !default;
$app-btn__warning__background: null !default;
$app-btn__warning__background--hover: null !default;

$app-btn__alt-blue__background-color: $base__alt-blue-color;
$app-btn__alt-blue__font-color: #ffffff;
$app-btn__alt-blue__border-color: shade($base__alt-blue-color, $base__color-shift);
$app-btn__alt-blue__border: 1px solid $app-btn__alt-blue__border-color;
$app-btn__alt-blue__background: $app-btn__alt-blue__background-color;
$app-btn__alt-blue__background--hover: shade($base__alt-blue-color, $base__color-shift);
$app-btn__alt-blue-outline__font-color: $base__color-pallette__darker-blue !default;
$app-btn__alt-blue-outline__font-color--hover: $base__color-pallette__darkest-blue !default;
$app-btn__alt-blue-outline__border-color--hover: $base__color-pallette__darkest-blue !default;

$app-btn__alt-teal__background-color: $base__alt-teal-color;
$app-btn__alt-teal__font-color: #ffffff;
$app-btn__alt-teal__border-color: shade($base__alt-teal-color, $base__color-shift);
$app-btn__alt-teal__border: 1px solid $app-btn__alt-teal__border-color;
$app-btn__alt-teal__background: $app-btn__alt-teal__background-color;
$app-btn__alt-teal__background--hover: shade($base__alt-teal-color, $base__color-shift);

$app-btn__red__background-color: $base__red-color;
$app-btn__red__font-color: #ffffff;
$app-btn__red__border-color: shade($base__red-color, $base__color-shift);
$app-btn__red__border: 1px solid $app-btn__red__border-color;
$app-btn__red__background: $app-btn__red__background-color;
$app-btn__red__background--hover: shade($base__red-color, $base__color-shift);

$app-btn__large__padding: rem-calc(20) rem-calc(38) !default;
$app-btn__large__font-size: rem-calc(20) !default;
$app-btn__small__padding: rem-calc(5) rem-calc(9) !default;
$app-btn__small__font-size: rem-calc(16) !default;
$app-btn__tiny__padding: rem-calc(5) rem-calc(9) !default;
$app-btn__tiny__font-size: rem-calc(12) !default;

.btn{
  @include button-base();
  &--large{
    padding: $app-btn__large__padding;
    font-size: $app-btn__large__font-size;
  }
  &--small{
    padding: $app-btn__small__padding;
    font-size: $app-btn__small__font-size;
  }
  &--tiny{
    padding: $app-btn__tiny__padding;
    font-size: $app-btn__tiny__font-size;
  }
  &--round{
    border-radius: 50%;
  }
  &--expand{
    width: 100%;
  }
  &--disabled{
    opacity: .3;
    &:hover,
    &focus{
      cursor: not-allowed;
    }
  }
  &--normalize{
    font-family: $base__font-family;
    font-weight: $base__font-weight--medium;
  }
  &:hover, &:focus{
    text-decoration: $app-btn__text-decoration--hover;
  }
  &__primary{
    @extend .btn;
    border: $app-btn__primary__border;
    background: $app-btn__primary__background;
    color: $app-btn__primary__font-color;
    &:hover, &:focus{
      color: $app-btn__primary__font-color;
      background: $app-btn__primary__background--hover;
    }
      &--outline{
      @extend .btn;
      border: $app-btn__primary-outline__border;
      color: $app-btn__primary-outline__font-color;
      &:hover, &:focus{
        color: shade($app-btn__primary-outline__font-color, $base__color-shift);
        border-color: $app-btn__primary-outline__border-color--hover;
        background: $app-btn__primary-outline__background--hover;
      }
    }
    &__form-input--postfix{
      @extend .btn__primary;
      padding: 0 rem-calc(6);
      text-align: center;
      height: $app-form__input-buttons__match-text-input__height;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }

  }
  &__secondary{
    @extend .btn;
    border: $app-btn__secondary__border;
    background: $app-btn__secondary__background;
    color: $app-btn__secondary__font-color;
    &:hover, &:focus{
      color: $app-btn__secondary__font-color;
      background: $app-btn__secondary__background--hover;
    }
    &--outline{
      @extend .btn;
      border: $app-btn__secondary-outline__border;
      color: $app-btn__secondary-outline__font-color;
      &:hover, &:focus{
        color: shade($app-btn__secondary-outline__font-color, $base__color-shift);
        border-color: $app-btn__secondary-outline__border-color--hover;
        background: $app-btn__secondary-outline__background--hover;
      }
    }
    &__form-input--postfix{
      @extend .btn__secondary;
      padding: 0  rem-calc(6);
      text-align: center;
      height: $app-form__input-buttons__match-text-input__height;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
  &__alert{
    @extend .btn;
    border: $app-btn__alert__border;
    background: $app-btn__alert__background;
    color: $app-btn__alert__font-color;
    &:hover, &:focus{
      color: $app-btn__alert__font-color;
      background: $app-btn__alert__background--hover;
    }
    &--outline{
      @extend .btn;
      border: $app-btn__alert-outline__border;
      color: $app-btn__alert-outline__font-color;
      &:hover, &:focus{
        color: $app-btn__alert-outline__font-color--hover;
        border-color: $app-btn__alert-outline__border-color--hover;
        background: $app-btn__alert-outline__background--hover;
      }
    }
  }
  &__warning{
    @extend .btn;
    border: $app-btn__warning__border;
    background: $app-btn__warning__background;
    color: $app-btn__warning__font-color;
    &:hover, &:focus{
      color: $app-btn__warning__font-color;
      background: $app-btn__warning__background--hover;
    }
  }
  &__alt-blue{
    @extend .btn;
    border: $app-btn__alt-blue__border;
    background: $app-btn__alt-blue__background;
    color: $app-btn__alt-blue__font-color;
    &:hover, &:focus{
      color: $app-btn__alt-blue__font-color;
      background: $app-btn__alt-blue__background--hover;
    }
    &--outline{
      @extend .btn;
      border: $app-btn__alt-blue__border;
      color: $app-btn__alt-blue-outline__font-color;
      background: transparent;
      &:hover, &:focus{
        color: $app-btn__alt-blue-outline__font-color--hover;
        border-color: $app-btn__alt-blue-outline__border-color--hover;
        background: transparent;
      }
    }
    &--has-translucent-text{
      color: rgba($app-btn__alt-blue__font-color, .65);
      &:hover, &:focus{
        color: rgba($app-btn__alt-blue__font-color, .85);
      }
    }
  }
  &__alt-teal{
    @extend .btn;
    border: $app-btn__alt-teal__border;
    background: $app-btn__alt-teal__background;
    color: $app-btn__alt-teal__font-color;
    &:hover, &:focus{
      color: $app-btn__alt-teal__font-color;
      background: $app-btn__alt-teal__background--hover;
    }
  }
  &__red{
    @extend .btn;
    border: $app-btn__red__border;
    background: $app-btn__red__background;
    color: $app-btn__red__font-color;
    &:hover, &:focus{
      color: $app-btn__red__font-color;
      background: $app-btn__red__background--hover;
    }
  }

  &__delete {
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: rgba(10, 10, 10, 0.2);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    height: rem-calc(20);
    max-height: rem-calc(20);
    max-width: rem-calc(20);
    min-height: rem-calc(20);
    min-width: rem-calc(20);
    outline: none;
    position: relative;
    vertical-align: top;
    width: rem-calc(20);
    &--medium{
      height: rem-calc(28);
      max-height: rem-calc(28);
      max-width: rem-calc(28);
      min-height: rem-calc(28);
      min-width: rem-calc(28);
    }
    &--large{
      height: rem-calc(35);
      max-height: rem-calc(35);
      max-width: rem-calc(35);
      min-height: rem-calc(35);
      min-width: rem-calc(35);
    }
  }
  &__delete:before, &__delete:after {
    background-color: #ffffff;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }
  &__delete:before {
    height: rem-calc(2);
    width: 50%;
  }
  &__delete:after {
    height: 50%;
    width: rem-calc(2);
  }
  &__delete:hover, &__delete:focus {
    background-color: rgba(10, 10, 10, 0.3);
  }
  &__delete:active {
    background-color: rgba(10, 10, 10, 0.4);
  }

}

.input{
  &__secondary{
    &__input-postfix{

    }
    &__input-prefix{

    }
  }
}
