$app-code__block__container__padding: $app-grid__column-gutter/2 !default;
$app-code__block__container__background-color: $base__lightest-gray-color !default;
$app-code__block__container__border-color: $base__light-gray__border-color !default;
$app-code__block__container__border: 1px solid $app-code__block__container__border-color !default;
$app-code__block__container__border-radius: $base__border-radius !default;

$app-code__block__pre__background-color: null !default;
$app-code__block__pre__color: $base__dark-gray-color !default;
$app-code__block__pre__padding: null !default;
$app-code__block__pre__white-space: pre !default;
$app-code__block__pre__word-wrap: normal !default;

$app-code__block__code__background-color: null !default;
$app-code__block__code__color: $base__dark-gray-color !default;
$app-code__block__code__font-size: rem-calc(14) !default;
$app-code__block__code__font-weight: normal !default;
$app-code__block__code__padding: null !default;

.code__block{
    padding: $app-code__block__container__padding;
    border: $app-code__block__container__border;
    border-radius: $app-code__block__container__border-radius;
    background-color: $app-code__block__container__background-color;
    overflow: auto;
    &__pre, &__code{
        font-size: $app-code__block__code__font-size;
    }
    &__pre{
        padding: $app-code__block__pre__padding;
        background-color: $app-code__block__pre__background-color;
        color: $app-code__block__pre__color;
        white-space: $app-code__block__pre__white-space;
        word-wrap: $app-code__block__pre__word-wrap;
    }
    &__code{
        padding: $app-code__block__code__padding;
        background-color: $app-code__block__code__background-color;
        color: $app-code__block__code__color;
        font-weight: $app-code__block__code__font-weight;
    }
}
