.slides__full-screen{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    z-index: 8888;
    width: 100%;
    height: 100%;
    & .slide__image--not-magnified{
        margin: auto;
        max-width: 100%;
        width: auto !important;
        max-height: 100%;
        height: auto;
        display: block;
    }
    &__inner{
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }
    &__content{
        height: 100vh;
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 100%;
    }
    &__main-content{
        overflow: hidden;
    }
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $base__light-gray__border-color;
        padding: .5rem 1rem;
    }
    &__toolbar{
        &__current-slide{
            color: $base__gray-color;
            margin-bottom: 0;
        }
        &__action-link{}
    }
    &__thumbnail{
        max-width: rem-calc(100);
        height: 100%;
    }
    @at-root .no-cssgrid .slides__full-screen{
        &__content{
            display: flex;
            flex-direction: column;
        }
        &__header{
            height: 15%;
        }
        &__main-content{
            height: 80%;
            overflow: auto;
        }
        &__footer{
            display: none;
        }
    }
}
@include mq(medium-up) {
    .slides__full-screen{
        &__thumbnail{
            max-width: rem-calc(120);
        }
    }
}
@include mq(large-up) {
    .slides__full-screen{
        &__thumbnail{
            max-width: rem-calc(140);
        }
    }
}
