$app-product-images__list-item__caption__padding: $app-grid__column-gutter / 2 !default;
$app-product-images__list-item__caption__text-align: center !default;
$app-product-images__list-item__caption__background: rgba(255,255,255, .75) !default;
$app-product-images__list-item__caption__font-size: rem-calc(17) !default;

.product-images__list{
  list-style: none;
    &-item__img{
        display: block;
        margin: auto;
    }
}
.product-images__list-item__caption{
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: $app-product-images__list-item__caption__padding;
  text-align: $app-product-images__list-item__caption__text-align;
  background: $app-product-images__list-item__caption__background;
  font-size: $app-product-images__list-item__caption__font-size;
}
