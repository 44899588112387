$app-loading__font-size: rem-calc(22) !default;
$app-loading__font-color: #777777 !default;
$app-loading__font-style: italic !default;
$app-loading__background-color: #b4b197 !default;
$app-loading__background: $app-loading__background-color !default;
$app-loading__width: $app-loading__font-size !default;
$app-loading__height: $app-loading__font-size !default;
$app-loading__animation-speed: 700ms !default;
$app-loading__animation-ease: linear !default;

.loading {
  display: block;
  overflow: hidden;
  margin: 0 auto;
  color: $app-loading__font-color;
  font-size: $app-loading__font-size;
  font-style: $app-loading__font-style;
  z-index: 1;
  &:before{
    display: block;
    float: left;
    content: '';
    width: $app-loading__width;
    height: $app-loading__height;
    margin-right: $app-loading__width/2;
    margin-top: rem-calc(4);
    background: $app-loading__background;
    transform-origin: center;
    transform: scale(1,0);
    animation: app-frames__loading $app-loading__animation-speed infinite $app-loading__animation-ease;
  }
    &--small{
        &:before{
            width: $app-loading__width / 1.5;
            height: $app-loading__height / 1.5;
        }
    }
  &--w-flip{
    &:before{
      animation: app-frames__loading__test 1.2s infinite ease-in-out;
    }
  }
  &--center,
  &--center-flex{
    &:before{
      display: inline-block;
      float: none;
    }
  }
  &--center-flex{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--v-top-flex{
    align-items: start;
  }
  &--cover{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255, .75);
  }
  &--no-bg{
    background: transparent;
  }
  &--no-style{
    color: inherit;
    font-size: inherit;
    font-style: inherit;
  }
}

@keyframes app-frames__loading {
  50% {
    transform: scale(1,1);
  }
}
@keyframes app-frames__loading-color {
  50% {
    color: tint($app-loading__font-color, 30%);
  }
}

@keyframes app-frames__loading__test {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
