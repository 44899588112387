$app-note__font-size: rem-calc(13.5) !default;
$app-note__line-height: 1.3 !default;
$app-note__color: #4d4d4d !default;

.note{
    font-size: $app-note__font-size;
    line-height: $app-note__line-height;
    color: $app-note__color;
    &--lighter{
        color: tint($app-note__color, $base__color-shift);
    }
    &__list__item{
        margin-bottom: $app-grid__column-gutter / 2;
            &:last-child{
                 margin-bottom: 0;
            }
    }
}
