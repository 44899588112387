$app-contact__border-color: #dddddd !default;
$app-contact__border: 1px solid $app-contact__border-color !default;

.contact__information{
   &__column{
   }
 }

.contact__form{
  &__column{
  }
}

@include mq(medium-up){
  .contact__information{
    &__column{
      @include grid-column(3, $padding: false);
      padding-right: $app-grid__column-gutter / 2;
    }
  }

  .contact__form{
    &__column{
      @include grid-column(9, $padding: false);
      padding-left: $app-grid__column-gutter;
      border-left: $app-contact__border;
    }
  }
}
