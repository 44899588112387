.quick-order__product-search__column,
.quick-order__form__column{

}
.quick-order__product-search__column{
  padding-right: $app-grid__column-gutter;
}
.quick-order__form__column{

}

.quick-order__form__input__search__column{
  float: left;
  width: 85%;
}
.quick-order__form__button__clear-search__column{
  float: left;
  width: 15%;
}
.quick-order__form__button__clear-search{
}

.quick-order__form__line-item__list{
  //display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  //border-collapse: collapse;
  &-item{
    //display: table-row;
    overflow: hidden;
    border-bottom: 1px solid #dddddd;
    padding: rem-calc(10) 0;
    &:last-child{
      border-bottom: none;
    }
  }
  &-item__detail{
    font-size: rem-calc(14);
    position: relative;
    padding: rem-calc(8) rem-calc(16) rem-calc(8) rem-calc(32);
  }
  &-item__detail__close-btn{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: rem-calc(8);
  }
}
.quick-order__form__actions__column,
.quick-order__form__line-item__product-id__column,
.quick-order__form__line-item__quantity-input__column,
.quick-order__form__estimate__column {
  //display: table-cell;
  //float: left;
  //padding: $app-grid__column-gutter / 2;
  display: inline-block;
  vertical-align: middle;
  padding-right: rem-calc(10);
  width: rem-calc(90);
}
.quick-order__form__actions__column{
  width: auto;
}
.quick-order__form__estimate__column{
  padding-right: 0;
  text-align: right;
}
.quick-order__form__actions__link{
  padding-right: 0;
  padding-left: 0;
}
.quick-order__search-results__list-item__button{
  background: transparent;
  border: none;
  width: 100%;
  color: $base__anchor__font-color;
  border: 1px solid transparent;
  font-size: rem-calc(16);
  text-align: left;
  &:hover,
  &:focus{
    color: $base__anchor__font-color--hover;
    border-color: $base__anchor__font-color;
  }
};
.quick-order__form__line-item__product-id__column,
.quick-order__form__line-item__quantity-input__column,
.quick-order__form__estimate__column{

}

.quick-order__form__line-item__product-id__detail-link{
  display: block;
}

.quick-order__search-results__list{
  @include shadows(1);
  margin: 0;
  list-style: none;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  max-height: rem-calc(300);
  overflow: auto;
  &__top-arrow {
    display: block;
    position: relative;
    @include triangle(top,
            18px, $height: 10px);
  }
  &-item{
    border-bottom: 1px solid #dddddd;
    &:last-child{
      border-bottom: none;
    }
  }
  &-item__link{
    padding: rem-calc(6);
    display: block;
    &__sku-label{
      display: inline-block;
      padding: 0 $app-grid__column-gutter / 4;
      font-size: rem-calc(14);
      color: #444444;
      &:hover,
      &:focus{
        color: #444444;
      }
    }
  }
}


@include mq(medium-up){
  .quick-order__product-search__column,
  .quick-order__form__column{
    @include grid-column(6, $padding: false);
  }
  .quick-order__form__column{
    padding-left: $app-grid__column-gutter;
    border-left: 1px solid #dddddd;
  }
}

@include mq(large-up){

}
