.product-options__not-started__modal__list {
  list-style: none;
  margin: 0;
  &-item {
    margin-top: $app-grid__column-gutter / 2;
    border-bottom: 1px solid #dddddd;
    &:last-child{
      border-bottom: none;
    }
  }
  &-item__display__column,
  &-item__info__column,
  &-item__action__column {
    margin-top: $app-grid__column-gutter / 4;
    margin-bottom: $app-grid__column-gutter / 4;
  }
  &-item__info__column {
  }
  &-item__action__column {
  }
}

@include mq(medium-up){
  .product-options__not-started__modal__list {
    display: table;
    border-collapse: collapse;
    &-item {
      display: table-row;
      &:last-child{
      }
    }
    &-item__display__column,
    &-item__info__column,
    &-item__action__column {
      display: table-cell;
      padding-top: $app-grid__column-gutter / 2;
      padding-bottom: $app-grid__column-gutter / 2;
    }
    &-item__info__column {
      padding-right: $app-grid__column-gutter / 2;
      padding-left: $app-grid__column-gutter / 2;
    }
    &-item__action__column {
      width: rem-calc(150);
    }
  }

}