.hide {
  display: none !important;
}

.show{
  display: block;
}

.hide-for-small{
  display: none;
}

.has-max-height--250{
    max-height: rem-calc(250);
    overflow-y: auto;
}

.is-invisible{
  @include element-invisible();
}

@include mq(medium-up) {
  .hide-for-medium-up{
    display: none;
  }
  .hide-for-small{
    display: block;
  }
}
