.feature{
  background: #dadada;
  width: 100%;
  height: 200px;
  margin-top: rem-calc(5);
  margin-bottom: rem-calc(24);
  &-large{
    @extend .feature;
    height: 600px;
  }
}

.feature__show-for{
  &__medium,
  &__large{
    display: none;
  }
  &__small{

  }
}

.feature__12-small__3-medium-up__column{

}
.feature__12-small__9-medium-up__column{

}

@include mq(medium-only){
  .feature__show-for{
    &__medium{
      display: block;
    }
    &__small{
      display: none;
    }
  }
}

@include mq(medium-up){
  .feature__12-small__3-medium-up__column,
  .feature__12-small__9-medium-up__column{
    @include grid-column-padding();
    &:first-child{
      padding-left: 0;
    }
    &:last-child{
      padding-right: 0;
    }
  }
  .feature__12-small__3-medium-up__column{
    @include grid-column(3, $padding: false);
  }
  .feature__12-small__9-medium-up__column{
    @include grid-column(9, $padding: false);
  }
}

@include mq(large-up){
  .feature__show-for{
    &__small{
      display: none;
    }
    &__medium{
      display: none;
    }
    &__large{
      display: block;
    }
  }
}
