$app-product-recommendations__border-color: $base__secondary-color--dark !default;
$app-product-recommendations__border: 1px solid $app-product-recommendations__border-color !default;
$app-product-recommendations__headline__background: #f0f6fa !default;
$app-product-recommendations__headline__border-color: $base__secondary-color--dark !default;
$app-product-recommendations__headline__border: 1px solid $app-product-recommendations__headline__border-color !default;
$app-product-recommendations__headline__padding: rem-calc(8) !default;
$app-product-recommendations__headline__font-size: rem-calc(20) !default;
$app-product-recommendations__headline__font-color: #333333 !default;
$app-product-recommendations__list-item__margin: 0 0 $app-grid__column-gutter/2 0 !default;
$app-product-recommendations__title__margin: rem-calc(5) 0 !default;
$app-product-recommendations__title__padding: rem-calc(5) 0 0 0 !default;
$app-product-recommendations__title__border-top-color: #dddddd !default;
$app-product-recommendations__title__border-top: 1px solid $app-product-recommendations__title__border-top-color !default;
$app-product-recommendations__product-detail-link__font-size: rem-calc(13) !default;
$app-product-recommendations__product-detail-link__font-weight: bold !default;
$app-product-recommendations__product-detail-link__line-height: 1.3 !default;
$app-product-recommendations__product-detail-link__sku-label__font-size: rem-calc(12) !default;
$app-product-recommendations__product-detail-link__sku-label__font-weight: normal !default;

.product-recommendations{
  position: relative;
  border-left: $app-product-recommendations__border;
  border-right: $app-product-recommendations__border;
  overflow: hidden;
  &:last-child{
    border-bottom: $app-product-recommendations__border;
  }
  &__headline{
    margin-top: 0;
    padding: $app-product-recommendations__headline__padding;
    background: $app-product-recommendations__headline__background;
    border-top: $app-product-recommendations__headline__border;
    border-bottom: $app-product-recommendations__headline__border;
    font-size: $app-product-recommendations__headline__font-size;
    color: $app-product-recommendations__headline__font-color;
  }
  &__list{
    list-style: none;
    margin-left: 0;
    &-item{
      @include grid-column( 6, $float: false);
      display: inline-block;
      vertical-align: top;
      margin: $app-product-recommendations__list-item__margin;
      &__title{
        margin: $app-product-recommendations__title__margin;
        padding: $app-product-recommendations__title__padding;
        border-top: $app-product-recommendations__title__border-top;
      }
      &__product-detail-link{
        display: block;
        font-size: $app-product-recommendations__product-detail-link__font-size;
        font-weight: $app-product-recommendations__product-detail-link__font-weight;
        line-height: $app-product-recommendations__product-detail-link__line-height;
        text-align: center;
      }
      &__product-detail-link__sku-label{
        display: inline-block;
        font-size: $app-product-recommendations__product-detail-link__sku-label__font-size;
        font-weight: $app-product-recommendations__product-detail-link__sku-label__font-weight;
        padding-right: rem-calc(10);
      }
      &__img-link{
        display: block;
        text-align: center;
        position: relative;
        max-height: rem-calc(270);
        overflow: hidden;
        &:hover .product-list__product-quick-view__button{
          @include product-list__product-quick-view__button--show();
        }
      }
    }
    &-item__img{

    }
  }
}

@include mq(medium-up){
  .product-recommendations{
    &__list{
      &-item{
      }
    }
  }
}

@include mq(large-up){
  .product-recommendations{
    &__list{
      &-item{
        @include grid-column( 4, $padding: false, $float: false);
      }
    }
  }
}