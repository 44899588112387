$app-dropdown__list__font-size: null !default;
$app-dropdown__list__margin: null !default;
$app-dropdown__list-item__border-color: #dddddd !default;
$app-dropdown__list-item__border: 1px solid $app-dropdown__list-item__border-color !default;
$app-dropdown__list-item__link__padding: rem-calc(8) !default;
$app-dropdown__list-item__link__icon-column__width: rem-calc(50) !default;
$app-dropdown__list-item__padding: rem-calc(5) !default;
$app-dropdown__toolbar__list__background-color: #6C4212 !default;
$app-dropdown__toolbar__list__background: $app-dropdown__toolbar__list__background-color !default;
$app-dropdown__toolbar__list-item__border-color: #986e3d !default;
$app-dropdown__toolbar__list-item__border: 1px solid $app-dropdown__toolbar__list-item__border-color !default;
$app-dropdown__toolbar__list-item__link__padding: rem-calc(13) !default;
$app-dropdown__toolbar__list-item__link__background-color: null !default;
$app-dropdown__toolbar__list-item__link__background: $app-dropdown__toolbar__list-item__link__background-color !default;
$app-dropdown__toolbar__list-item__link__background-color--hover: #6C4212 !default;
$app-dropdown__toolbar__list-item__link__background--hover: $app-dropdown__toolbar__list-item__link__background-color--hover !default;
$app-dropdown__toolbar__list-item__link__color--hover: #ffffff !default;
$app-dropdown__toolbar__list-item__link__color: #eee7cc !default;
$app-dropdown__toolbar__list-item__link__color--hover: #ffffff !default;

.dropdown__list{
  font-size: $app-dropdown__list__font-size;
  list-style: none;
  margin: $app-dropdown__list__margin;
  &-item{
    padding: $app-dropdown__list-item__padding;
    border-bottom: $app-dropdown__list-item__border;
    &:last-child{
      border-bottom: none;
    }
  }
  &-item__link{
    display: block;
    padding: $app-dropdown__list-item__link__padding;
    &-row{
      @include clearfix();
    }
    &-text-column{
      display: block;
      margin-right: $app-dropdown__list-item__link__icon-column__width;
    }
    &-icon-column{
      display: block;
      float: right;
      max-width: $app-dropdown__list-item__link__icon-column__width;
      text-align: center;
    }
    &-icon{

    }
  }
}


.dropdown__toolbar__list{
  @include clearfix();
  list-style: none;
  margin: 0;
  //max-width: rem-calc(275);
  background: $app-dropdown__toolbar__list__background;
  &-item{
    float: left;
    border-right: $app-dropdown__toolbar__list-item__border;
    &:last-child{
      border-right: none;
    }
  }
  &-item__link{
    @include single-transition(background);
    display: block;
    padding: $app-dropdown__toolbar__list-item__link__padding;
    text-align: center;
    background: $app-dropdown__toolbar__list-item__link__background;
    color: $app-dropdown__toolbar__list-item__link__color;
    &:hover,
    &:focus{
      color: $app-dropdown__toolbar__list-item__link__color--hover;
      background: shade($app-dropdown__toolbar__list-item__link__background--hover, $base__color-shift);
    }
    &--alert{
      background: shade($base__alert-color, $base__color-shift);
      &:hover,
      &:focus{
        background: $base__alert-color;
      }
    }
    &-icon{

    }
  }
}
