@mixin element-invisible($important: null) {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  @if($important){
    position: absolute !important;
  }
  @else{
    position: absolute;
  }
  width: 1px;
}

// @mixins
//
// Turns off the element-invisible effect.
@mixin element-invisible-off($important: null, $position: static, $width: auto, $height: auto, $overflow: visible) {
  @if($important){
    position: $position !important;
  }
  @else{
    position: $position;
  }
  height: $height;
  width: $width;
  overflow: $overflow;
  clip: auto;
}
