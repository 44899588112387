$app-product-pricing__border-color: #999999 !default;
$app-product-pricing__border: 1px solid $app-product-pricing__border-color !default;
$app-product-pricing__font-size: rem-calc(14) !default;
$app-product-pricing__qty__background: null !default;
$app-product-pricing__price__background: null !default;
$app-product-pricing__price__font-weight: bold !default;
$app-product-pricing__title__background: #f0f6fa !default;
$app-product-pricing__notes__font-size: rem-calc(14) !default;
$app-product-pricing__notes__color: #444444 !default;
$app-product-pricing__title__product-preview__background: transparent !default;
$app-product-pricing__title__product-preview__border-color: #a7cae2 !default;
$app-product-pricing__title__product-preview__border: 1px solid $app-product-pricing__title__product-preview__border-color !default;

$app-product-pricing__footer__font-sizes: rem-calc(13), rem-calc(13), rem-calc(13) !default;
$app-product-pricing__footer__line-height: 1.1, 1.1, 1.15 !default;
$app-product-pricing__footer__font-style: null !default;
$app-product-pricing__footer__font-weight: $base__font-weight--light !default;
$app-product-pricing__footer__color: #444444 !default;

.product-pricing{
  &__footer{
    font-size: nth($app-product-pricing__footer__font-sizes, 1);
    line-height: nth($app-product-pricing__footer__line-height, 1);
    font-style: $app-product-pricing__footer__font-style;
    font-weight: $app-product-pricing__footer__font-weight;
    color: $app-product-pricing__footer__color;
  }
}
.product-pricing__list{
  list-style: none;
  margin: 0;
  display: table;
  width: 100%;
  //background: #f0f0f0;
  &-item{
    display: inline-block;
    //padding: ;
    text-align: center;
    border-right: $app-product-pricing__border;
    font-size: $app-product-pricing__font-size;
    background: $app-product-pricing__qty__background;
    &:last-child{
      border-right: none;
    }
  }
  &-item__price{
    padding: rem-calc(2) rem-calc(6.5);
    border-top: $app-product-pricing__border;
    margin: 0 auto;
    background: $app-product-pricing__price__background;
    font-weight: $app-product-pricing__price__font-weight;
  }
  &-item__title {
    display: block;
    background: $app-product-pricing__title__background;
    border: none;
  }
  &-item__title__product-preview {
    display: block;
    background: $app-product-pricing__title__product-preview__background;
    border-right: none;
    border-top: $app-product-pricing__title__product-preview__border;
    border-bottom: $app-product-pricing__title__product-preview__border;
  }
}
.product-pricing__notes__list{
  list-style: none;
  margin: 0;
  &-item{
    display: inline-block;
    margin-right: rem-calc(14);
    color: $app-product-pricing__notes__color;
    font-size: $app-product-pricing__notes__font-size;
  }
}
