$app-product-options__child-descriptions__border-color: #a7cae2 !default;
$app-product-options__child-descriptions__border: 1px solid $app-product-options__child-descriptions__border-color !default;
$app-product-options__child-descriptions__background-color: #f0f6fa !default;
$app-product-options__child-descriptions__background: $app-product-options__child-descriptions__background-color !default;
$app-product-options__child-descriptions__padding: rem-calc(16) !default;
$app-product-options__child-descriptions__height: rem-calc(288) !default;
$app-product-options__reveal-img__list__background-color: #ffffff !default; //f8f8f8
$app-product-options__reveal-img__list__border-color: #cccccc !default;
$app-product-options__reveal-img__list__border: 1px solid $app-product-options__reveal-img__list__border-color !default;
$app-product-options__reveal-img__list__padding: rem-calc(16) !default;

.product-option__header{
  @include clearfix();
  margin-top: rem-calc(16);
  &__description-column {
  }
  &__display-column {
    display: none;
  }
}

.product-option__form-container{
}

.product-option__child-descriptions{
  height: $app-product-options__child-descriptions__height;
  padding: $app-product-options__child-descriptions__padding;
  border: $app-product-options__child-descriptions__border;
  overflow: auto;
  background: $app-product-options__child-descriptions__background;
}

.product-option__reveal__form-select{

}
.product-option__reveal-img__list{
  list-style: none;
  background: $app-product-options__reveal-img__list__background-color;
  border-right: $app-product-options__reveal-img__list__border;
  border-bottom: $app-product-options__reveal-img__list__border;
  border-left: $app-product-options__reveal-img__list__border;
  margin-left: 0;
  padding: $app-product-options__reveal-img__list__padding;
  text-align: center;
  &-item{
    @include element-invisible();
    opacity: 0;
    @include single-transition(opacity, $speed: 1s);
    &.selected{
      @include element-invisible-off();
      opacity: 1;
    }
    &__img{

    }
    &__title{
      margin-bottom: 0;
    }
  }
}

@include mq(medium-up){
  .product-option__header {
    &__description-column {
      @include grid-column(8, $padding: false);
      @include grid-column-padding($left: false);
    }
    &__display-column {
      display: block;
      @include grid-column(4, $padding: false);
      @include grid-column-padding($right: false);
    }
  }
  .product-option__form-container{
      margin: auto;
      width: 50%;
  }
}

@include mq(large-up){
  .product-option__form-container{
    width: 35%;
  }
}
