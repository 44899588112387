$base__anchor__light__font-color: null !default;
$base__anchor__light__font-color--hover: null !default;;

a.is-translucent-anchor{
    color: #ffffff;
    opacity: 0.50;
    &:hover, &:focus{
        color: #ffffff;
        opacity: 0.85;
    }
}
a.is-light-anchor{
    color: $base__anchor__light__font-color;
    &:hover,
    &:focus {
        color: $base__anchor__light__font-color--hover;
    }
}
a.is-alert-anchor{
    color: $base__alert-color;
    &:hover,
    &:focus {
        color: shade($base__alert-color, $base__color-shift);
    }
}

a.is-secondary-anchor{
    color: $base__gray-color;
    &:hover,
    &:focus {
        color: $base__dark-gray-color;
    }
}
