$app-slides__pagination__bullet__background--active: #007aff !default;

@import "~swiper/swiper.scss";
//@import "~swiper/components/core/core";
@import "~swiper/components/lazy/lazy.scss";
//@import "~swiper/components/zoom/zoom.scss";
@import "~swiper/components/navigation/navigation.scss";
@import "~swiper/components/pagination/pagination.scss";

/**
swiper overrides
 */

.swiper-wrapper{
    list-style: none;
    margin: 0;
}
.swiper__slide{
    height: auto;
}

.swiper-button-prev{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 10,50 L 60,100 L 70,90 L 30,50 L 70,10 L 60,0 Z' class='arrow' fill='%23333333'%3E%3C/path%3E%3C/svg%3E");
}
.swiper-button-next{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 10,50 L 60,100 L 70,90 L 30,50 L 70,10 L 60,0 Z' class='arrow' transform='translate%28100, 100%29 rotate%28180%29' fill='%23333333'%3E%3C/path%3E%3C/svg%3E");
}
.swiper-button-prev,
.swiper-button-next{
    background-repeat: no-repeat;
    background-color: rgba( 255,255,255, .25 );
    border-radius: $base__border-radius;
    background-position: center;
    &:after{
        display: none;
    }
}
.swiper-pagination-bullet-active{
    background: $app-slides__pagination__bullet__background--active;
}

.swiper-wrapper img{
    //max-width: none;
    //width: 100%;
}
