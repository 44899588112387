$app-product-kit__item__headline__color: null !default;
$app-product-kit__item__subhead__color: #222222 !default;
$app-product-kit__item__body-copy__color: #666666 !default;

.product-kit__item{
  &__main-content{
    padding: $app-grid__column-gutter / 2 0;
  }
  &__title{
    /*
    display: none;
    @at-root .no-js .product-kit__item__title{
      display: block;
    }
    */
  }
  &__headline{
    color: $app-product-kit__item__headline__color;
  }
  &__subhead{
    color: $app-product-kit__item__subhead__color;
  }
  &__body-copy{
    color: $app-product-kit__item__body-copy__color;
  }
}
.product-kit__item__actions__list{
  display: none;
  @at-root .no-js .product-kit__item__actions__list{
     display: block;
  }
}

.product-kit__item__display-column,
.product-kit__item__information-column{
  //@include grid-column-padding();
}

.product-kit__item__display-column{
  max-height: rem-calc(270);
  overflow: hidden;
  &__link{

  }
}

.product-kit__item__information-column{

}



@include mq(medium-up) {
  .product-kit__item__display-column{
    //@include grid-column(3, $padding: false);
  }
  .product-kit__item__information-column{
    //@include grid-column(9, $padding: false);
  }
}