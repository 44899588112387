$app-account__main-navigation__background--hover: $base__lightest-gray-color !default;
$app-account__main-navigation__border: 1px solid $base__light-gray__border-color !default;
$app-account__main-navigation__padding: $app-grid__column-gutter / 4 $app-grid__column-gutter / 2 $app-grid__column-gutter / 4 $app-grid__column-gutter / 2;
$app-account__main-navigation__headline__font-sizes: rem-calc(17), rem-calc(19), rem-calc(22);
$app-account__main-navigation__headline__line-height: 1.15, $base__heading__line-height, $base__heading__line-height;
$app-account__main-navigation__headline__font-style: null !default;
$app-account__main-navigation__headline__font-weight: $base__font-weight--light !default;
$app-account__main-navigation__subhead__font-sizes: rem-calc(16), rem-calc(17), rem-calc(18);
$app-account__main-navigation__subhead__line-height: 1.15, 1.23, 1.23;
$app-account__main-navigation__subhead__font-style: null !default;
$app-account__main-navigation__subhead__font-weight: $base__font-weight--light !default;


.account{
  /*******************************1*/
  &__container{

  }
  &__main-navigation{
    margin-bottom: $app-grid__column-gutter / 2;
    &__column{

    }
    &__column__feature__list{
      margin-bottom: $app-grid__column-gutter;
      &__item{
          border-top: 1px solid $base__light-gray__border-color;
          padding: $app-grid__column-gutter / 2 0 0 0;
          &:last-child{
              border-top: none;
          }
      }
    }
    &__nav__list{
      list-style: none;
      margin: 0;
    }
    &__nav__list-item{

    }
    &__nav__list-item__link{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $app-account__main-navigation__padding;
      border-bottom: $app-account__main-navigation__border;
      &:hover,&:focus{
        background: $app-account__main-navigation__background--hover;
      }
      &--is-active{
        background: $app-account__main-navigation__background--hover;
      }
      &__content{
        display: block;
      }
      &__description{
        display: block;
        color: $base__gray-color;
        font-size: rem-calc(12);
      }
    }
    &__header{
      //padding: 0 $app-grid__column-gutter / 2 $app-grid__column-gutter / 4 0;
        overflow-x: hidden;
    }
    &__header__headline{
      margin-bottom: rem-calc(4);
      font-size: nth($app-account__main-navigation__headline__font-sizes, 1);
      line-height: nth($app-account__main-navigation__headline__line-height, 1);
      font-style: $app-account__main-navigation__headline__font-style;
      font-weight: $app-account__main-navigation__headline__font-weight;
    }
    &__header__subhead{
      margin-bottom: 0;
      font-size: nth($app-account__main-navigation__subhead__font-sizes, 1);
      line-height: nth($app-account__main-navigation__subhead__line-height, 1);
      font-style: $app-account__main-navigation__subhead__font-style;
      font-weight: $app-account__main-navigation__subhead__font-weight;
    }
  }
  &__main-content{
    &__header__navigation__list{
      display: flex;
      margin: 0;
      list-style-type: none;
    }
    &__header__navigation__list-item{

    }
    &__header__navigation__list-item__link{
      display: block;
      padding: $app-account__main-navigation__padding;
      border-radius: $base__border-radius;
      &:hover{
        background: $app-account__main-navigation__background--hover;
      }
      &:focus,&--is-active{
        //background: $app-account__main-navigation__background--hover;
        border: $app-account__main-navigation__border
      }
      &__content,&__count{
        display: inline-block;
      }
      &__count{
        margin-right: $app-grid__column-gutter / 2;
      }
    }
  }
  &__purchase-history{
    &__list-header__row{
      &:first-child .account__purchase-history__list-header__column{
        padding-bottom: 0;
      }
      &:last-child .account__purchase-history__list-header__column{
        padding-top: 0;
      }
    }
    &__list-header__column{
      font-weight: $base__font-weight--medium;
      &--pricing{
        width: rem-calc(235);
      }
      &--add-to-order{
        width: rem-calc(57);
      }
    }
    &__list-item__row{
      &--discontinued .account__purchase-history__list-item__column{
        color: $base__gray-color;
      }
      &--order-item{
        background: $base__secondary-color;
      }
    }
    &__list-item__column{
      vertical-align: center;
      text-align: center;
      &__dc__title{
        display: inline-block;
        border: 1px solid #eee;
        border-radius: $base__border-radius;
        padding: $app-grid__column-gutter / 5;
      }
      &__suggestion__title{
        display: inline-block;
        border: 1px solid $base__light-gray-color;
        border-radius: $base__border-radius;
        padding: $app-grid__column-gutter / 4;
        background: $base__white;
      }
      &--right-divider{
        border-right: $app-account__main-navigation__border;
      }
    }
    &__list-item__pricing__container {
      & .product-pricing__list-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-right: none;
      }
      & .product-pricing__list-item__price {
        margin: 0;
        border-top: none;
      }
    }
    &__list-item__add-item__link{
      display: block;
      border: 1px solid #aaaaaa;
      border-radius: 100%;
      padding: .5rem;
      &:hover, &:focus{
        border: 1px solid #666666;
      }
      &--order-item{
        background: $base__secondary-color;
      }
    }
    &__list__summary__row{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &__list__summary__column{
      margin-right: $app-grid__column-gutter / 2;
      margin-bottom: 0;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &__other-services{
    &__title{
      color: #222222;
      font-weight: $base__font-weight--bold;
    }
    &__order-list__container{
      max-height: $app-grid__column-gutter * 5;
      margin-right: $app-grid__column-gutter;
      overflow: auto;
    }
    &__order-list__header{
      padding: $app-grid__column-gutter / 4;
      background: #eeeeee;
      border: 1px solid $base__light-gray__border-color;
      border-top-left-radius: $base__border-radius;
      border-top-right-radius: $base__border-radius;
    }
    &__list-item{
      border-bottom: 1px solid $base__light-gray-color;
      padding-bottom: $app-grid__column-gutter / 2;
      margin-bottom: $app-grid__column-gutter / 2;
      &:last-child{
        border-bottom: none;
      }
    }
  }
  /*******************************2*/
  &__form{
    &-container{
      @include grid-column-padding();
      margin-bottom: 1rem;
    }
  }
  &__address__line{
    margin-bottom: 0;
  }


  &__detail__row {
    margin-bottom: $app-grid__column-gutter;
  }
  &__detail__columns{}
  &__detail__column{
    padding: 0 $app-grid__column-gutter / 2;
    //border-right: 1px solid #dddddd;
    &:last-child{
      //border-right: none;
    }
    &__header{
      padding-bottom: $app-grid__column-gutter / 4;
      //padding-left: $app-grid__column-gutter / 4;
    }
    &__heading{
      border-bottom: 1px solid #dddddd;
    }
    &--addresses{

    }
    &--products{

    }
    &--orders{

    }
  }
  &__detail__addresses{

  }
  &__detail__address{
    margin-bottom: $app-grid__column-gutter / 2;
    &:last-child{
      margin-bottom: auto;
    }
  }
  &__address__line{
    margin-bottom: 0;
  }
  &__address__list{
    list-style: none;
  }
  &__address__list-item{
    border-bottom: 1px solid #dddddd;
    &:last-child{
      border-bottom: none;
    }
    &--is-hoverable:hover,
    &--is-hoverable:focus{
      background: #eeeeee;
      cursor: pointer;
    }
    &--is-selected{
        background: $base__secondary-color;
    }
    &__column, &__column__address, &__column__actions{
      padding: $app-grid__column-gutter / 4 $app-grid__column-gutter / 3;
    }
    &__column__address{
      opacity: .65;
    }
    &__column__actions{
      white-space: nowrap;
    }
  }

}
.account-detail-row{
  @include clearfix();
}
.account-detail__info__column,
.account-detail__history__column{

}

@include mq(medium-up){
  .account{
    /*******************************1*/
    &__address__list-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__container{
      display: flex;
    }
    &__main-navigation{
      border-radius: $base__border-radius;
      border: $app-account__main-navigation__border;
      &__column{
        flex: 0 0 25%;
        width: 25%;
        margin-right: $app-grid__column-gutter;
        padding-top: $app-grid__column-gutter / 3;
      }
      &__nav{}
      &__nav__list{

      }
      &__nav__list-item{

      }
      &__nav__list-item__link{

      }
      &__header{
        padding: $app-account__main-navigation__padding;
        border-top-right-radius: $base__border-radius;
        border-top-left-radius: $base__border-radius;
        background: $base__light-gray-color;
      }
      &__header__headline{
        font-size: nth($app-account__main-navigation__headline__font-sizes, 2);
        line-height: nth($app-account__main-navigation__headline__line-height, 2);
      }
      &__header__subhead{
        font-size: nth($app-account__main-navigation__subhead__font-sizes, 2);
        line-height: nth($app-account__main-navigation__subhead__line-height, 2);
      }
    }
    &__main-content{
      &__column{
        flex: 1 1 auto;
      }
    }
    /*******************************2*/
    &__column{
      @include grid-column(7);
    }
    &__form{
      &-container{
        width: grid-calc-width(7, $app-grid__total-columns);
        padding-left: 0;
      }
    }

    &__detail__columns,
    &__detail__address__title__row{
      display: flex;
    }
  }
}

@include mq(large-up){
  .account{
    /*******************************1*/
    &__container{}
    &__main-navigation{
      &__column{}
      &__nav__list{

      }
      &__nav__list-item{

      }
      &__nav__list-item__link{

      }
      &__header__headline{
        font-size: nth($app-account__main-navigation__headline__font-sizes, 3);
        line-height: nth($app-account__main-navigation__headline__line-height, 3);
      }
      &__header__subhead{
        font-size: nth($app-account__main-navigation__subhead__font-sizes, 3);
        line-height: nth($app-account__main-navigation__subhead__line-height, 3);
      }
    }
    &__main-content{
      &__column{}
    }

    /*******************************2*/
    &__form{
      &-container{
        width: grid-calc-width(5, $app-grid__total-columns);
      }
    }
  }
  /*******************************1*/

  /*******************************2*/
  .account-detail__info__column{
    @include grid-column(4, $padding: false);
  }
  .account-detail__history__column{
    @include grid-column(7, $padding: false, $float: right);
  }
}
