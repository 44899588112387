$app-address-book__list-item__border-bottom: null !default;
$app-address-book__list-link__background: null !default;
$app-address-book__list-link__background--hover: null !default;
$app-address-book__list-link__padding: rem-calc(10) !default;
$app-address-book__list-link__add-address__font-color: #ffffff !default;
$app-address-book__list-link__add-address__background: $base__primary-color !default;
$app-address-book__list-link__add-address__background--hover: null !default;
$app-address-book__list-link__remove-address__font-color: null !default;
$app-address-book__list-link__remove-address__font-color--hover: #ffffff !default;
$app-address-book__list-link__remove-address__background-color: null !default;
$app-address-book__list-link__remove-address__background: $app-address-book__list-link__remove-address__background-color !default;
$app-address-book__list-link__remove-address__background--hover: $base__alert-color !default;
$app-address-book__dropdown__margin: 0 !default;
$app-address-book__dropdown-triangle__height: 10px !default;
$app-address-book__dropdown-triangle__color: $app-address-book__list-link__add-address__background !default;

.address-book{
  &__dropdown{
    @include dropdown($margin: $app-address-book__dropdown__margin, $width: auto);
    @include triangle(top, rem-calc(20), $app-address-book__dropdown-triangle__height, $color: $app-address-book__dropdown-triangle__color);
  }
  &__list{
    list-style: none;
    margin: 0;
    &-item{
      position: relative;
      border-bottom: $app-address-book__list-item__border-bottom;
      @include single-transition('min-height');
      &:last-child{
        border-bottom: none;
      }
      &.selected{
        background: $app-address-book__list-link__background--hover;
      }
    }
    &-item__address{
      &-actions{
        float: right;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        width: rem-calc(50);
      }
      &-name{
        margin-right: rem-calc(50);
        //border-right: 1px solid #ddd;
      }
    }
    &-link{
      display: block;
      padding: $app-address-book__list-link__padding;
      background: $app-address-book__list-link__background;
      @include single-transition(background);
      &:hover, &:focus{
        @if($app-address-book__list-link__background--hover == null and $app-address-book__list-link__background != null ){
          background: shade($app-address-book__list-link__background, $base__color-shift);
        }
        @else{
          background: $app-address-book__list-link__background--hover;
        }
      }
      &__remove-address {
        @extend .address-book__list-link;
        color: $app-address-book__list-link__remove-address__font-color;
        background: $app-address-book__list-link__remove-address__background;
        &:hover, &:focus{
          background: $app-address-book__list-link__remove-address__background--hover;
          color: $app-address-book__list-link__remove-address__font-color--hover;
        }
      }
      &[data-disabled]{
        color: #bbbbbb;
        cursor: not-allowed;
        &:hover, &:focus{
          background: transparent;
        }
      }
      &__add-address{
        display: block;
        padding: $app-address-book__list-link__padding;
        background: $app-address-book__list-link__add-address__background;
        color: $app-address-book__list-link__add-address__font-color;
        @include single-transition(background);
        &:hover, &:focus{
          color: $app-address-book__list-link__add-address__font-color;
          @if($app-address-book__list-link__add-address__background--hover == null and $app-address-book__list-link__add-address__background != null) {
            background: shade($app-address-book__list-link__add-address__background, $base__color-shift);
          }
          @else{
            background: $app-address-book__list-link__add-address__background--hover;
          }
        }
      }
    }
  }
}