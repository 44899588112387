$app-product-kit__item__progress__title__font-size: rem-calc(14) !default;
$app-product-kit__item__progress__title__color: #666666 !default;
$app-product-kit__item__progress__title__border-color: #d8d8d8 !default;
$app-product-kit__item__progress__title__border: 1px solid $app-product-kit__item__progress__title__border-color !default;
$app-product-kit__item__progress__title__background-color: #f0f6fa !default;
$app-product-kit__item__progress__title__background: $app-product-kit__item__progress__title__background-color !default;
$app-product-kit__item__progress__title__padding: rem-calc(8) !default;
$app-product-kit__item__progress__list__padding: rem-calc(9) rem-calc(14) rem-calc(9) !default;
$app-product-kit__item__progress__list__border-color: #D8D8D8 !default;
$app-product-kit__item__progress__list__border: 1px solid $app-product-kit__item__progress__list__border-color !default;
$app-product-kit__item__progress__list__border-radius: $base__border-radius * 2 !default;
$app-product-kit__item__progress__list__background-color: #f0f6fa !default;
$app-product-kit__item__progress__list__background: $app-product-kit__item__progress__list__background-color !default;
$app-product-kit__item__progress__list-item__border-color: $app-product-kit__item__progress__list__background-color !default;
$app-product-kit__item__progress__list-item__border: 1px solid $app-product-kit__item__progress__list-item__border-color !default;
$app-product-kit__item__progress__list-item__border-right-color: $base__secondary-color--dark !default;
$app-product-kit__item__progress__list-item__border-right: 1px solid $app-product-kit__item__progress__list-item__border-right-color !default;
$app-product-kit__item__progress__list-item__small__border-bottom-color: $app-product-kit__item__progress__list__border-color !default;
$app-product-kit__item__progress__list-item__small__border-bottom: 1px solid $app-product-kit__item__progress__list-item__small__border-bottom-color !default;
$app-product-kit__item__progress__list-item--current__border-color: $base__primary-color !default;
$app-product-kit__item__progress__list-item--current__border: 1px solid $app-product-kit__item__progress__list-item--current__border-color !default;
$app-product-kit__item__progress__list-item--completed__border-color: #bcd6df !default;
$app-product-kit__item__progress__list-item__padding: rem-calc(10) rem-calc(20) !default;
$app-product-kit__item__progress__list-item__font-size: null !default;
$app-product-kit__item__progress__list-item__font-color: #777777 !default;
$app-product-kit__item__progress__list-item--current__font-color: $base__anchor-color !default;
$app-product-kit__item__progress__list-item--current__background-color: #ffffff !default;
$app-product-kit__item__progress__list-item--current__background: $app-product-kit__item__progress__list-item--current__background-color !default;
$app-product-kit__item__progress__list-item--completed__font-color: darken($base__success-color, 15%) !default; // original: #444444
$app-product-kit__item__progress__list-item--completed__background-color: null !default; // original: screen($base__success-color, 15%) or #bcd6df
$app-product-kit__item__progress__list-item--completed__background: $app-product-kit__item__progress__list-item--completed__background-color !default; // original: #bcd6df
$app-product-kit__item__progress__list-item--completed__border-color: $base__success-color !default; // original: $base__secondary-color--dark
$app-product-kit__item__progress__list-item--completed__border: null !default;
$app-product-kit__item__progress__list-item--completed__border-right-color: darken($base__success-color, 15%) !default; // original: shade($base__secondary-color--dark, $base__color-shift)
$app-product-kit__item__progress__list-item--completed__border-right: 1px solid $app-product-kit__item__progress__list-item--completed__border-right-color !default;

//current-step
//completed-step

.product-kit__item__progress{
  &__title{
    font-size: $app-product-kit__item__progress__title__font-size;
    color: $app-product-kit__item__progress__title__color;
    background: $app-product-kit__item__progress__title__background;
    margin: 0;
    border-top: $app-product-kit__item__progress__title__border;
    border-left: $app-product-kit__item__progress__title__border;
    border-right: $app-product-kit__item__progress__title__border;
    padding: $app-product-kit__item__progress__title__padding;
    &__count-label{
      display: inline-block;
      vertical-align: middle;
      padding: 0 rem-calc(8);
      border: 1px solid #bbbbbb;
      border-radius: $base__border-rounded;
    }
  }
  &__list {
    border-bottom-left-radius: $app-product-kit__item__progress__list__border-radius;
    border-bottom-right-radius: $app-product-kit__item__progress__list__border-radius;
    list-style: none;
    border: $app-product-kit__item__progress__list__border;
    background: $app-product-kit__item__progress__list__background;
    width: 100%;
    margin: 0;
    overflow: hidden; // contain the floats

    &-item {
      margin: 0;
      border: $app-product-kit__item__progress__list-item__border;
      border-bottom: $app-product-kit__item__progress__list-item__small__border-bottom;
      padding: $app-product-kit__item__progress__list-item__padding;
      font-size: $app-product-kit__item__progress__list-item__font-size;
      color: $app-product-kit__item__progress__list-item__font-color;
      &:first-child{
        border-bottom-left-radius: $app-product-kit__item__progress__list__border-radius;
      }
      &:last-child{
        border-right: none;
      }
      &--current{
        color: $app-product-kit__item__progress__list-item--current__font-color;
        background: $app-product-kit__item__progress__list-item--current__background;
        border-color:$app-product-kit__item__progress__list-item--current__border-color;
        &:last-child{
          border-right: $app-product-kit__item__progress__list-item--current__border;
        }
      }
      &--completed{
        color: $app-product-kit__item__progress__list-item--completed__font-color;
        background: $app-product-kit__item__progress__list-item--completed__background;
        border: $app-product-kit__item__progress__list-item--completed__border;
      }
    }
  }
}

@include mq(medium-up){
  .product-kit__item__progress__list {
    &-item {
      float: left;
      border-right: $app-product-kit__item__progress__list-item__border-right;
      border-bottom: $app-product-kit__item__progress__list-item__border;
      &--current{
        border-color:$app-product-kit__item__progress__list-item--current__border-color;
      }
      &--completed{
        border-right: $app-product-kit__item__progress__list-item--completed__border-right;
      }
    }
  }
}