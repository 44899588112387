$app-notification__item__color: #4b4b4b !default;
$app-notification__error__heading__color: #964600 !default;
$app-notification__success__heading__color: darken($base__success-color, 15%) !default;

.notification{
  margin: rem-calc(16) auto;
  padding: rem-calc(16);
  border: 1px solid;
  border-radius: $base__border-radius;
  &--absolute{
    position: absolute;
    z-index: 1;
  }
  &--expanded{
    width: 100%;
  }
  &--top{
    top: 0;
    margin-top: 0;
  }
  &--error{
    background: screen($base__alert-color, 15%);
    border-color: $base__alert-color;
  }
  &--success{
    background: screen($base__success-color, 15%);
    border-color: $base__success-color;
    color: darken($base__success-color, 15%);
  }
  &--notice{
    background: screen($base__info-color, 15%);
    border-color: $base__info-color;
  }
}
.notification__heading{
  &--success{
    color: $app-notification__error__heading__color;
  }
  &--success{
    display: inline-block;
    color: $app-notification__success__heading__color;
  }
}
.notification__list{
  margin-top: 0;
  margin-bottom: 0;
  &-item{
    color: $app-notification__item__color;
  }
}

.notification__message{
    &:last-child{
        margin-bottom: 0;
    }
    &--success{

    }
}






