.request-offer{
  &__container{
    @extend %extended_clearfix;
    @include grid-column-padding();
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

@include mq(medium-up){
  .request-offer{
    &__container{
      width: grid-calc-width(8, $app-grid__total-columns);;
    }
  }
}

@include mq(large-up){
  .request-offer{
    &__container{
      width: grid-calc-width(6, $app-grid__total-columns);;
    }
  }
}