$app-is-large-text__font-sizes: rem-calc(17), rem-calc(18), rem-calc(19) !default;
$app-is-large-text__line-height: 1.45, 1.5, 1.55 !default;
$app-is-small-text__font-sizes: rem-calc(12), rem-calc(13), rem-calc(14) !default;
$app-is-small-text__line-height: 1.3, 1.35, 1.4 !default;


.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.on-hover__underline{
  &:hover, &:focus{
    text-decoration: underline;
  }
}
.text__inline-block,
.headline__inline-block{
  display: inline-block;
}
.headline__inline-block{
  margin: 0;
}
.text__center{
  text-align: center;
}
.text__left{
  text-align: left;
}
.text__right{
  text-align: right;
}
.text__reverse-string{
  @include textReverseStringDisplay();
}
.no-wrap{
  white-space: nowrap;
}
.text__weight--light{
  font-weight: $base__font-weight--light;
}
.text-is-capitalized{
  text-transform: capitalize;
}
.is-marginless{
  margin: 0;
}
.is-error{
  color: $base__error-color;
}
.is-alert{
  color: $base__alert-color;
}
.is-warning{
  color: $base__warning-color;
}
.is-success{
  color: $base__alt-success-color;
}
.is-notice{
  color: $base__alt-blue-color;
}
.is-large-text,
.has-large-text{
    font-size: nth($app-is-large-text__font-sizes, 1);
    line-height: nth($app-is-large-text__line-height, 1);
}
.has-small-text{
    font-size: nth($app-is-small-text__font-sizes, 1);
    line-height: nth($app-is-small-text__line-height, 1);
}
.has-text-grey-light{
    color: $base__gray-color;
}
.has-text-grey-lighter{
    color: $base__light-gray-color;
}
@include mq(medium-up){
    .is-large-text{
        font-size: nth($app-is-large-text__font-sizes, 2);
        line-height: nth($app-is-large-text__line-height, 2);
    }
    .has-small-text{
        font-size: nth($app-is-small-text__font-sizes, 2);
        line-height: nth($app-is-small-text__line-height, 2);
    }
}
@include mq(large-up){
    .is-large-text{
        font-size: nth($app-is-large-text__font-sizes, 3);
        line-height: nth($app-is-large-text__line-height, 3);
    }
    .has-small-text{
        font-size: nth($app-is-small-text__font-sizes, 3);
        line-height: nth($app-is-small-text__line-height, 3);
    }
}
