.no-border{
  border: none;
}
.v-middle{
  vertical-align: middle;
}
.is-padded{
  padding: $app-grid__column-gutter / 2;
}
.collapse-bottom{
  padding-bottom: 0;
  margin-bottom: 0;
}
.collapse-top{
  padding-top: 0;
  margin-top: 0;
}
.has--margin-bottom{
    margin-bottom: $app-grid__column-gutter / 2;
}
.no-bottom-border{
    border-bottom: none;
}
