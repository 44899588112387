$app-customer-service__border-color: #dddddd !default;
$app-customer-service__border: 1px solid $app-customer-service__border-color !default;

.customer-service__contact-information{
  &__column{
  }
}

.customer-service__information{
  &__column{
  }
}

.customer-service__sales-tax{
  &__list{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  &__list-item{
    padding: $app-grid__column-gutter / 8 $app-grid__column-gutter / 4;
  }
}

@include mq(medium-up){
  .customer-service__contact-information{
    &__column{
      @include grid-column(3, $padding: false);
      padding-right: $app-grid__column-gutter / 2;
    }
  }

  .customer-service__information{
    &__column{
      @include grid-column(9, $padding: false);
      padding-left: $app-grid__column-gutter;
      border-left: $app-customer-service__border;
    }
  }
}
