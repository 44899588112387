$app-form__label__color: #4d4d4d !default;
$app-form__label__font-size: rem-calc(14) !default;
$app-form__label__line-height: 1.5 !default;
$app-form__label__required__color: #c2a369 !default;
$app-form__label__required__content: "\e024" !default;
$app-form__input__font-size: rem-calc(18) !default;
$app-form__input__border-color: #cccccc !default;
$app-form__input__border: 1px solid $app-form__input__border-color !default;
$app-form__input__border-radius: $base__border-radius !default;
$app-form__input__border-color--focus: #986e3d !default;
$app-form__input__border--focus: 1px solid $app-form__input__border-color--focus !default;
$app-form__input__box-shadow--focus: 0 0 5px rgba(150,150,150, .35) !default;
$app-form__required-icon__content: "\e024" !default;
$app-form__required-icon__color: #c2a369 !default;
$app-form__input-list-item__margin-bottom: rem-calc(16) !default;
$app-form__error__padding: rem-calc(6) rem-calc(9) !default;
$app-form__error__font-size: rem-calc(12) !default;
$app-form__error__font-weight: normal !default;
$app-form__error__font-style: italic !default;
$app-form__error__color: #ffffff !default;
$app-form__error__background-color: $base__alert-color !default;
$app-form__error__border-radius: $base__border-radius !default;
//$app-form__toggle__on__background-color: #58b143 !default;
$app-form__toggle__on__background-color: $base__alt-success-color !default;
$app-form__toggle__border-color: #000000 !default;

form{
  margin: 0 0 rem-calc(16);
}

fieldset{
  // override normalize settings
  border: none;
  padding-right: 0;
  padding-left: 0;
}

.form__fieldset--collapse{
  padding-top: 0;
  padding-bottom: 0;
}

legend{
  margin: 0;
  font-weight: bold;
}
.form--collapse{
  margin: 0;
}
.form__input-list {
  list-style: none;
  margin-left: 0;
  &-item{
    margin-bottom: $app-form__input-list-item__margin-bottom;
    padding: 0;
    &__row{
      @include clearfix();
    }
    &--flex{
      display: flex;
    }
    &__description{
      margin-bottom: $app-form__input-list-item__margin-bottom;
      padding: 0;
      border-bottom: 1px solid #eeeeee;
    }
    &--collapse{
      margin: 0;
    }
  }
}
label{
  font-size: $app-form__label__font-size;
  color: $app-form__label__color;
  cursor: pointer;
  display: block;
  font-weight: normal;
  line-height: $app-form__label__line-height;
  margin-bottom: 0;
}
.label__required{
  &:after {
    @include icon-font-defaults(
            $font-size: 1.5em
    );
    content: $app-form__label__required__content;
    color: $app-form__label__required__color;
  }
}

.form__label{
  &--inline{
    display: inline-block;
    vertical-align: middle;
  }
  &--lighter{
    opacity: 0.65;
  }
  &--smaller{
    font-size: $app-form__label__font-size * 0.825;
  }
  &--required{
    &:after{
      @include icon-font-defaults($font-size: 1.5em);
      content: $app-form__required-icon__content;
      color: $app-form__required-icon__color;
    }
  }
}
input, select, button, textarea, .form__input{
  //font-family: inherit; //this is set by normalize
  font-size: $app-form__input__font-size;
  border-radius: $app-form__input__border-radius;
}
.form__input,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
input[type="tel"],
textarea{
  -webkit-appearance: none;
  border: $app-form__input__border;
  padding: rem-calc(8);
  width: 100%;
  box-sizing: border-box;
  &:focus{
    outline: none;
    border: $app-form__input__border--focus;
    box-shadow: $app-form__input__box-shadow--focus;
  }
}

input[type="checkbox"],
input[type="radio"]{

}

select{
  -webkit-appearance: none;
  border: $app-form__input__border;
  padding: rem-calc(6) rem-calc(8) rem-calc(8) rem-calc(8);
  height: rem-calc(40);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGJhc2VQcm9maWxlPSJ0aW55IiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ1cHgiIGhlaWdodD0iMzkuNzVweCIgdmlld0JveD0iMTMgMTUuNSA0NSAzOS43NSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjxwb2x5Z29uIGZpbGw9IiM1NTU1NTUiIHBvaW50cz0iMzYuMTA1LDQwLjU1OSAyNy44NzEsMzEuNDQyIDQ0LjM0MiwzMS40NDIgMzYuMTA1LDQwLjU1OSAyNy44NzEsMzEuNDQyIDQ0LjM0MiwzMS40NDIgIi8+Cjwvc3ZnPg==);
  background-position: 100% center;
  background-repeat: no-repeat;
  //to correct firefox issues ...
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

textarea{
  //max-width: 100%;
}

.form__input--flat-right{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

input[type="search"].form__input__search--show-cancel-button{
  -webkit-appearance: searchfield;
  &::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
  }
}

.form__note{
  font-size: rem-calc(12.5);
}
.form__error{
  display: block;
  padding: $app-form__error__padding;
  margin-top: - rem-calc(1);
  font-size: $app-form__error__font-size;
  font-weight: $app-form__error__font-weight;
  font-style: $app-form__error__font-style;
  background: $app-form__error__background-color;
  color: $app-form__error__color;
  border-bottom-right-radius: $app-form__error__border-radius;
  border-bottom-left-radius: $app-form__error__border-radius;
}
.form__input--error,
input.form__input--error,
textarea.form__input--error,
select.form__input--error{
  &,
  &:focus{
    border: 1px solid $base__alert-color;
    background: #ffeee1;
  }
}
.form__toggle{
  @include clearfix();
  vertical-align: middle;
  &__checkbox__input{
    display:none;
    &:checked + .form__toggle__checkbox__label{
      background: $app-form__toggle__on__background-color;
      &:before{
        left:18px;
      }
    }
  }
  &__checkbox__label{
    width:40px;
    height:21px;
    box-sizing:border-box;
    border:1px solid;
    float:left;
    border-radius:50px;
    position:relative;
    cursor:pointer;
    transition:.3s ease;
    &:before{
      transition:.3s ease;
      content:'';
      width:21px;
      height:21px;
      position:absolute;
      background:white;
      left:-1px;
      top:-1px;
      box-sizing:border-box;
      border:1px solid;
      color: $app-form__toggle__border-color;
      border-radius:50px;
    }
  }
}

.form__styled__checkbox{
    &__input{
        position: relative;
        margin-right: $app-grid__column-gutter / 4;
        width: 1em;
        height: 1em;
        color: $base__darkest-gray-color;
        border: 1px solid $base__gray-color;
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        &::before {
            position: absolute;
            content: '';
            display: block;
            top: 3px;
            left: 6px;
            width: 5px;
            height: 9px;
            border-style: solid;
            border-color: $base__white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
        }
        &:checked {
            color: $base__white;
            border-color: darken($base__alt-success-color, $base__color-shift);
            background: $base__alt-success-color;
            &::before {
                opacity: 1;
            }
            ~ .form__styled__checkbox__label::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }
    }
    &__label{
        position: relative;
        cursor: pointer;
    }
}

@include mq(medium-up) {
  .form__input{
    &-column {
      float: left;
      &__fname,
      &__lname,
      &__state,
      &__zip,
      &__phone,
      &__phone-ext{
        @include grid-column(6, $padding: false);
      }
      &__fname,
      &__state,
      &__phone{
        padding-right: $app-grid__column-gutter / 2;
      }
      &__lname,
      &__zip,
      &__phone-ext{
        padding-left: $app-grid__column-gutter / 2;
      }
    }
  }
    .form__input-list {
        &-item{
            &--flex-medium-up{
                display: flex;
            }
        }
    }
}
