.video--full-width{
  width: 100%;
}
.video--radius{
  border-radius: $base__border-radius;
}
.video__description{
  &__content{
    padding: rem-calc( $app-grid__column-gutter/2 );
  }
  &__title{

  }
}
.video__responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  border: 1px solid $base__gray-color;
  border-radius: $base__border-radius;
  & iframe,
  & > video{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
  &--max-w-h--560-315{
    max-width: 560px;
    max-height: 315px;
  }
  &__container{
    margin-bottom: rem-calc( $app-grid__column-gutter / 2 );
  }
}
.video__embed__container{
  display: inline-block;
  border: 1px solid $app-btn__primary-outline__border-color;
  border-radius: $base__border-radius;
  margin-bottom: $app-grid__column-gutter / 2;
}

.wistia_popover_overlay{
  background: rgba($app-modal__overlay__background-color, $app-modal__overlay__opacity) !important;
}