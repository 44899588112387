$app-slides__btn__border-color: #a7cae2 !default;
$app-slides__btn__border: 1px solid $app-slides__btn__border-color !default;
$app-slides__btn__border-radius: $base__border-radius !default;
$app-slides__btn__padding: rem-calc(3) !default;
$app-slides__btn__color: #333333 !default;
$app-slides__btn__color--hover: #333333 !default;
$app-slides__btn__background: #f0f6fa !default;
$app-slides__btn__background--hover: #a7cae2 !default;
$app-slides__transition-speed: 1000ms !default;
$app-slides__transition-ease: ease-in-out !default;
$app-slides__thumbnails__list-item__margin: null !default;
$app-slides__thumbnails__list-item__padding: null !default;
$app-slides__thumbnails__list-item__border-color: null !default;
$app-slides__thumbnails__list-item__border: null !default;
$app-slides__thumbnails__list-item__border-radius: null !default;
$app-slides__thumbnails__list-item__border-color--active: null !default;
$app-slides__thumbnails__list-item__border--active: null !default;
$app-slides__thumbnails__list-item__shadow-depth: null !default;


.slides{
  position: relative;
  list-style: none;
  margin-left: 0;
}
.slide{
  position: absolute;
  top: 0;
  left: -999rem;
  opacity: 0;
  display: block;
  transition: opacity $app-slides__transition-speed $app-slides__transition-ease ;
  &--active,
  &--selected{
    //position: static;
    position: relative;
    left: 0;
    opacity: 1;
  }
  &__image{
    margin: auto;
  }
    &__caption__container{
        @include single-transition(all);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(240,246,250, .75);
        border: 1px solid $base__light-gray__border-color;
        border-radius: $base__border-radius;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: $app-grid__column-gutter / 2 $app-grid__column-gutter;
        max-height: rem-calc(200);
        overflow: auto;
        &--is-closed{
            background: rgba(240,246,250, 0.0);
            border-color: transparent;
            left: auto;
            & .slide__caption{
                display: none;
            }
            & .slide__caption__action__link{
                transform: rotate(-45deg);
            }
        }
    }
    &__caption{
        color: $base__dark-gray-color;
        font-size: nth($app-is-small-text__font-sizes, 1);
        line-height: nth($app-is-small-text__line-height, 1);
    }
    &__caption__action__link{
        @include single-transition(transform);
        position: absolute;
        top: $app-grid__column-gutter / 6;
        right: $app-grid__column-gutter / 6;
    }
}

@include mq(medium-up) {
    .slide{
        &__caption{
            font-size: nth($app-is-small-text__font-sizes, 2);
            line-height: nth($app-is-small-text__line-height, 2);
        }
    }
}
@include mq(large-up) {
    .slide{
        &__caption{
            font-size: nth($app-is-small-text__font-sizes, 3);
            line-height: nth($app-is-small-text__line-height, 3);
        }
    }
}
