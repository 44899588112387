$app-cart__affiliate__intro__row__border-radius: $base__border-radius * 3 !default;

.cart__affiliate{
  &__intro__row{
    @include clearfix();
    border-radius: $app-cart__affiliate__intro__row__border-radius;
    &--ccc{
      background: #f9f9f9;
      border: 1px solid #173642;
    }
  }
  &__intro__logo__column{
    padding: rem-calc(10) $app-grid__column-gutter / 2 0;
    text-align: center;
    &--ccc{
      //border-right: 1px solid #173642;
      //border-radius: $app-cart__affiliate__intro__row__border-radius;
    }
  }
  &__intro__logo__img{
    &--ccc{

    }
  }
  &__intro__copy__column{
    padding: $app-grid__column-gutter / 2;
    border-radius: $app-cart__affiliate__intro__row__border-radius;
    &--ccc{
      background: #294a57;
      text-align: center;
      border: 1px solid #173642;
    }
  }
  &__intro__copy__headline{
    &--ccc{
      font-weight: $base__font-weight--bold;
      color: #ffffff;
    }
  }
  &__intro__copy{
    &--ccc{
      margin-bottom: 0;
      color: #ffffff;
    }
  }
}

@include mq(medium-up){
  .cart__affiliate{
    &__intro__row{
      &--ccc{

      }
    }
    &__intro__logo__column{
      @include grid-column( 5, $padding: false, $float:true);
      &--ccc{

      }
    }
    &__intro__logo__img{
      &--ccc{

      }
    }
    &__intro__copy__column{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @include grid-column( 7, $padding: false, $float:true);
      &--ccc{

      }
    }
    &__intro__copy__headline{
      &--ccc{

      }
    }
    &__intro__copy{
      &--ccc{

      }
    }
  }
}

@include mq(large-up){
  .cart__affiliate{
    &__intro__row{
      &--ccc{

      }
    }
    &__intro__logo__column{
      @include grid-column( 3, $padding: false, $float:false);
      &--ccc{

      }
    }
    &__intro__logo__img{
      &--ccc{

      }
    }
    &__intro__copy__column{
      @include grid-column( 9, $padding: false, $float:false);
      &--ccc{

      }
    }
    &__intro__copy__headline{
      &--ccc{

      }
    }
    &__intro__copy{
      &--ccc{

      }
    }
  }
}