$app-pre-header__min-height: null !default;
$app-pre-header__background: null !default;

$app-pre-header__box-shadow: null !default;
$app-pre-header__border-color: null !default;
$app-pre-header__border: null !default;
$app-pre-header__padding: null !default;

$app-pre-header__tagline__font-size: null, null, null !default;
$app-pre-header__tagline__line-height: null !default;
$app-pre-header__tagline__font-style: null !default;
$app-pre-header__tagline__font-weight: null !default;
$app-pre-header__tagline__color: null !default;
$app-pre-header__tagline__opacity: null !default;
$app-pre-header__tagline__padding: null !default;

$app-pre-header__lpg__column__width: null !default;
$app-pre-header__lpg__column__max-width: null !default;

.pre-header{
  @extend %extended_clearfix;
  min-height: $app-pre-header__min-height;
  background: $app-pre-header__background;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  box-shadow: $app-pre-header__box-shadow;
  border-top: $app-pre-header__border;
  border-bottom: $app-pre-header__border;
  padding: $app-pre-header__padding;
  min-width: $app-body__content__min-width;
  &__tagline__column{

  }
  &__tagline{
    margin: 0;
    padding: $app-pre-header__tagline__padding;
    text-align: center;
    font-style: $app-pre-header__tagline__font-style;
    font-weight: $app-pre-header__tagline__font-weight;
    font-size: nth($app-pre-header__tagline__font-size, 1);
    line-height: nth($app-pre-header__tagline__line-height, 1);
    color: $app-pre-header__tagline__color;
    opacity: $app-pre-header__tagline__opacity;
  }
  &__lpg__column{
    width: $app-pre-header__lpg__column__width;
    max-width: $app-pre-header__lpg__column__max-width;
    margin: 0 auto;
  }
  &__lpg__heading{
    margin: 0;
    padding: 0;
    font-size: rem-calc(13);
  }
  &__lpg__img{
    width: 100%;
  }
}
.flexbox .pre-header{
  display: flex;
  align-items: center;
  &__tagline__column{
    flex: 1 1 auto;
  }
  &__lpg__column{
    flex: 1 1 auto;
  }
}
.no-js .pre-header,
.no-flexbox .pre-header{
  display: table;
  //table-layout: fixed;
  width: 100%;
  &__tagline__column,
  &__lpg__column{
    display: table-cell;
    vertical-align: middle;
  }
  &__tagline__column{
  }
  &__lpg__column{
  }
}

@include mq(medium-up) {
  .pre-header,
  .flexbox .pre-header,
  .no-js .pre-header,
  .no-flexbox .pre-header{
    display: none;
  }
}

