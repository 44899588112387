$app-site-error__content__text-align: null !default;
$app-site-error__heading__font-size: null !default;
$app-site-error__heading__margin-bottom: null !default;
$app-site-error__subhead__margin-bottom: null !default;

.site__error{
  &__content{
    text-align: $app-site-error__content__text-align;
  }
  &__heading{
    font-size: $app-site-error__heading__font-size;
    margin-bottom: $app-site-error__heading__margin-bottom;
  }
  &__subhead{
    margin-bottom: $app-site-error__subhead__margin-bottom;
  }
  &__paragraph{

  }
  &__home-page__link{

  }
}