$app-header-main__background--color: null !default;
$app-header-main__background: $app-header-main__background--color !default;
$app-header-main__background-gradient: null !default;
$app-header-main__font-color: null !default;
$app-header-main__top_padding: null !default;
$app-header-main__bottom_padding: null !default;
$app-header-main__card-depth: 1 !default;
$app-header-main__max-width: null !default;
$app-header-main__row__padding: null !default;
$app-header-main__row__height: null, null, null !default;
$app-header-main__logo__column__min-width: rem-calc(225), rem-calc(288), rem-calc(288) !default;

$app-header-main__search__column__border-color: #DDC682 !default;
$app-header-main__search__column__border: null !default;

$app-header-main__search__icon__column__min-width: rem-calc(40) !default;
$app-header-main__search__icon__link__color: $base__anchor-color !default;
$app-header-main__right__column__padding: null !default;
$app-header-main__right__column__request-offers__font-size: null !default;
$app-header-main__right__column__request-offers__font-weight: null !default;
$app-header-main__right__column__request-offers__line-height: null !default;
$app-header-main__right__column__phone-number__font-size: null !default;
$app-header-main__right__column__phone-number__font-weight: null !default;
$app-header-main__right__column__phone-number__line-height: null !default;
$app-header-main__right__column__lpg__column__max-width: null, null, null !default;
$app-header-main__right__column__lpg__column__margin: null !default;
$app-header-main__right__column__lpg__column__padding: null !default;
$app-header-main__right__column__lpg__heading__margin: null !default;
$app-header-main__right__column__lpg__img__max-width: null, null, null !default;
$app-header-main__cart__column__cart-headline__font-size: null !default;
$app-header-main__cart__column__cart-headline__line-height: null !default;
$app-header-main__cart__column__cart-headline__font-weight: null !default;
$app-header-main__box-shadow--override: null !default;
$app-header-main__left__column-fixed__width: rem-calc(285) !default;
$app-header-main__product-category-nav__width: null !default;
$app-header-main__product-category-nav-heading__background: null !default;
$app-header-main__product-category-nav-heading__border-top: null !default;
$app-header-main__product-category-nav-heading__border-right: null !default;
$app-header-main__product-category-nav-heading__border-bottom: null !default;
$app-header-main__product-category-nav-heading__border-left: null !default;
$app-header-main__product-category-nav-heading__border-top-radius: null !default;
$app-header-main__product-category-nav-heading-link__padding: null !default;
$app-header-main__product-category-nav-heading-link__font-size: null !default;
$app-header-main__product-category-nav-heading-link__font-color: null !default;
$app-header-main__product-category-nav-heading-link__line-height: null !default;
$app-header-main__product-category-nav-list__open-class: "open" !default;
$app-header-main__product-category-nav-list__border: null !default;
$app-header-main__product-category-nav-list__top-border-color: null !default;
$app-header-main__product-category-nav-list__background: null !default;
$app-header-main__product-category-nav-list-item__border-bottom: null !default;
$app-header-main__product-category-nav-list-item__background--hover: null !default;
$app-header-main__product-category-nav-list-item__dropdown__background: null !default;
$app-header-main__product-category-nav-list-item-link__padding: null !default;
$app-header-main__product-category-nav-list-item-link__font-color: null !default;
$app-header-main__product-category-nav-list-item-link__background--hover: null !default;
$app-header-main__product-category-nav-list-item-link__icon__color: $base__secondary-color--dark !default;
$app-header-main__product-category-nav-list-item-link__icon__opacity: .5 !default;
$app-header-main__product-category-nav-list-item-link__description__font-size: null !default;
$app-header-main__product-category-nav-list-item-link__description__line-height: null !default;
$app-header-main__product-category-nav-list-item-link__description__font-color: null !default;
$app-header-main__tagline__font-style: null !default;
$app-header-main__tagline__font-weight: null !default;
$app-header-main__tagline__font-size: null, null, null !default;
$app-header-main__tagline__color: null !default;
$app-header-main__phone__font-style: null !default;
$app-header-main__phone__font-weight: null !default;
$app-header-main__phone__font-size: null, null, null !default;
$app-header-main__phone__color: null !default;

$app-header-main__toolbar__toggle__stroke-size: null !default;
$app-header-main__toolbar__toggle__width: null !default;
$app-header-main__toolbar__toggle__color: null !default;
$app-header-main__toolbar__toggle__stroke-spacing: null !default;
$app-header-main__toolbar__link__color: $base__heading__color--dark !default;
$app-header-main__toolbar__link__color--hover: null !default;

$app-header-main__site-nav-list-link__font-size: rem-calc(15) !default;
$app-header-main__nav__row__container__box-shadow: null !default;
$app-header-main__nav__row__container__background-color: null !default;
$app-header-main__nav__row__container__background: $app-header-main__nav__row__container__background-color !default;
$app-header-main__nav__row__container__border-color: null !default;
$app-header-main__nav__row__container__border: null !default;

$app-header-main__nav-list-item__border-color: null !default;
$app-header-main__nav-list-item__border: null !default;
$app-header-main__nav-list-item__link__color: null !default;
$app-header-main__nav-list-item__link--dark__color: null !default;

$app-header-main__nav-list__parent__shadow-depth: 0 !default;
$app-header-main__nav-list__parent__dropdown-triangle__color: null !default;
$app-header-main__nav-list__parent__border-color: null !default;
$app-header-main__nav-list__parent__border: null !default;
$app-header-main__nav-list__parent__border-radius: null !default;


.header__main{
  position: relative;
  z-index: 3;
  &__row, &__nav__row{
    @include clearfix();
    padding: $app-header-main__row__padding;
    max-width: $app-header-main__max-width;
    margin: auto;
    overflow: hidden;
  }
  &__row__container{
    background: $app-header-main__background;
    background: $app-header-main__background-gradient;
  }
  &__row{
    position: relative;
    height: nth($app-header-main__row__height, 1);
  }
  &__logo__column{
    margin: 0;
    padding: 0 0 0 rem-calc(5);
    text-align: center;
    width: nth($app-header-main__logo__column__min-width, 1 );
  }
  &__logo__img{
    width: 100%;
  }
  &__toolbar__search-icon__column,
  &__search-icon__column__medium-up{
    padding-top: 10px;
    text-align: right;
    min-width: $app-header-main__search__icon__column__min-width;
  }
  &__search-icon__column__medium-up{
    display: none;
  }
  &__search__column{
    display: none;
    margin: 0;
    height: 100%;
    overflow: hidden;
    &--show-for-small{
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $app-header-main__background--color;
      padding-left: 35px;
      padding-right: 60px;
    }
  }
  &__toolbar__link{
    display: block;
    color: $app-header-main__toolbar__link__color;
    padding: $app-grid__column-gutter / 3;
  }
  &__toolbar__search-icon__link{
    color: $app-header-main__search__icon__link__color;
    fill: $app-header-main__search__icon__link__color;
  }
  &__toolbar__toggle__link{
    width: rem-calc(55);
    padding: rem-calc(25) rem-calc(10);
  }
  &__right__column{
    display: none;
    width: 100%;
    max-width: rem-calc(375);
    margin: 0;
    padding: 0 $app-grid__column-gutter / 3;
    border-left: $app-header-main__search__column__border;
  }
  &__right__column__tagline__column{
    display: none;
    min-width: rem-calc(160);
    padding-left: $app-grid__column-gutter / 3;
    padding-right: $app-grid__column-gutter / 3;
  }
  &__right__column__tagline{
    text-align: center;
    font-style: $app-header-main__tagline__font-style;
    font-weight: $app-header-main__tagline__font-weight;
    font-size: nth($app-header-main__tagline__font-size, 1);
    line-height: 1.2;
    color: $app-header-main__tagline__color;
  }
  &__right__column__phone__column{
    display: none;
    margin: 0;
    padding: 0;
    padding-left: $app-grid__column-gutter / 3;
    padding-right: $app-grid__column-gutter / 3;
  }
  &__right__column__phone{
    text-align: center;
    font-style: $app-header-main__phone__font-style;
    font-weight: $app-header-main__phone__font-weight;
    font-size: nth($app-header-main__phone__font-size, 1);
    line-height: 1.2;
    color: $app-header-main__phone__color;
  }
  &__right__column__lpg__column{
    margin: $app-header-main__right__column__lpg__column__margin;
    padding: $app-header-main__right__column__lpg__column__padding;
  }
  &__right__column__lpg__heading{
    margin: $app-header-main__right__column__lpg__heading__margin;
  }
  &__right__column__lpg__img{
    max-width: nth($app-header-main__right__column__lpg__img__max-width, 1);
  }
  &__nav__row__container{
    //@include shadows(1);
    //override the standard shadows so we can force it to only display on the bottom of this box
    box-shadow: $app-header-main__nav__row__container__box-shadow;
    background: $app-header-main__nav__row__container__background;
    border-top: $app-header-main__nav__row__container__border;
  }
  &__nav__row{
  }
  &__cart,
  &__nav__cart{
    &-count__label {
      @include label($border-radius: $base__border-rounded);
      border: 1px solid $base__info-color;
      color: $base__info-color;
      display: none;
      &--show {
        display: inline-block;
      }
    }
  }
  &__cart-icon.icon-cart,
  &__nav__cart-icon.icon-cart{
    display: inline-block;
    margin-left: - rem-calc(12);
    margin-right: - rem-calc(6);
    font-size: 3.2em;
  }
  &__toolbar__toggle-icon{
    @include icon-hamburger($app-header-main__toolbar__toggle__stroke-size,
            $app-header-main__toolbar__toggle__width,
            $app-header-main__toolbar__toggle__color,
            $app-header-main__toolbar__toggle__stroke-spacing
    );
  }
  .off-canvas__toggle-button__action--close-canvas{
    & .header__main__toolbar__toggle-icon{
      @include icon-hamburger--close("");
    }
  }
  &__nav__list{
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
    &--left{
      //float: left;
      display: table;
      table-layout: fixed;
      width: auto;
      margin: 0 auto;
    }
    &--right{
      display: none;
      float: right;
    }
    &-item{
      float: left;
      &--left{
        float: none;
        display: table-cell;
        vertical-align: middle;
        border-right: $app-header-main__nav-list-item__border;
        &:last-child{
          //border-right: none;
        }
      }
    }
    &-item__link{
      display: block;
      padding: rem-calc(8);
      font-size: rem-calc(14);
      color: $app-header-main__nav-list-item__link__color;
      &--first{
        white-space: nowrap;
        //padding-left: rem-calc(16);
      }
      &--dark{
        color: $app-header-main__nav-list-item__link--dark__color;
      }
    }
    &-item__account{
      &__link__content__medium-up{
        display: none;
      }
    }
    &-item__quick-order{
      display: none;
    }
    &-item__cart{

    }
    &-item__show-inline__medium-alt-up,
    &-item__show__medium-up{
      display: none;
    }
  }
  &__nav__account{
    &-dropdown {
      @include dropdown($margin: 0, $width: auto, $border: $app-header-main__nav-list__parent__border);
      @include triangle(top, rem-calc(20), 10px, $border-color: $app-header-main__nav-list__parent__dropdown-triangle__color);
    }
    &__nav__list{
      list-style: none;
      margin: 0;
      &-item{
        border-bottom: 1px solid #ccc;
        &:last-child{
          border-bottom: none;
        }
        &__link{
          display: block;
          padding: rem-calc(10) rem-calc(20);
          color: $app-header-main__nav-list-item__link__color;
          &:hover, &:focus{
            background: $base__secondary-color;
          }
        }
      }
    }
  }
  &__product-categories{
    &__column {
    }
    &__nav{
      &-heading {
        &__list {
          list-style: none;
          margin: 0;
        }
        &__item{
          & > .header__main__product-categories__nav-list.#{$app-header-main__product-category-nav-list__open-class},
          // this is for allowing hover to show list &:hover > .header__main__product-categories__nav-list,
          &:focus > .header__main__product-categories__nav-list {
            @include element-invisible-off($position: absolute, $width: ($app-header-main__product-category-nav__width - rem-calc(1)) );
          }
        }
        &__link {
        }
      }
      &-list{
        @include element-invisible();
        //@include shadows(1);//1px 2px 3px rgba(60, 60, 60, 0.6)
        z-index: 2;
        margin: 0;
        width: 100%;
        list-style: none;
        border: $app-header-main__product-category-nav-list__border;
        border-top-color: $app-header-main__product-category-nav-list__top-border-color;
        background: $app-header-main__product-category-nav-list__background;
        &-item{
          border-bottom: $app-header-main__product-category-nav-list-item__border-bottom;
          &:last-child{
            border-bottom: none;
          }
          &.header__main__product-categories__nav-list-item--active {
            background: $app-header-main__product-category-nav-list-item__background--hover;
          }
          &.header__main__product-categories__nav-list-item--active > .header__main__product-categories__nav-list
          {
            @include element-invisible-off($position: absolute, $width: 100%, $overflow: null);
            overflow-y: auto;
            top: 0;
            bottom: 0;
            left: 100%;
            box-shadow: none;
            background: $app-header-main__product-category-nav-list-item__dropdown__background;
            border-top-right-radius: $base__border-radius;
            border-bottom-right-radius: $base__border-radius;
          }
          &-link{
            display: block;
            padding: $app-header-main__product-category-nav-list-item-link__padding;
            color: $app-header-main__product-category-nav-list-item-link__font-color;
            position: relative;
            &:hover,
            &:focus{
              background: $app-header-main__product-category-nav-list-item-link__background--hover;
            }
            @at-root {
              .has-dropdown{
                & > .header__main__product-categories__nav-list-item-link{
                  &:after{
                    @include icon-font-defaults();
                    content: "\e01a";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    opacity: $app-header-main__product-category-nav-list-item-link__icon__opacity;
                    color: $app-header-main__product-category-nav-list-item-link__icon__color;
                  }
                }
              }
            }
          }
          &-link__description{
            display: block;
            margin: 0;
            padding: 0;
            line-height: $app-header-main__product-category-nav-list-item-link__description__line-height;
            font-size: $app-header-main__product-category-nav-list-item-link__description__font-size;
            color: $app-header-main__product-category-nav-list-item-link__description__font-color;
          }
        }
      }
      &-list__parent{
        @include triangle(top, rem-calc(20), $height: 10px, $border-color: $app-header-main__nav-list__parent__dropdown-triangle__color);
        @include shadows($app-header-main__nav-list__parent__shadow-depth);
        border: $app-header-main__nav-list__parent__border;
        border-radius: $app-header-main__nav-list__parent__border-radius;
      }
    }
    &__nav-heading__icon{

    }
  }
  @at-root .no-js .header__main__product-categories__nav-list-item{
    &:hover,
    &:focus {
      background: $app-header-main__product-category-nav-list-item__background--hover;
    }
    // this is for allowing hover to show list & > .header__main__product-categories__nav-list.#{$app-header-main__product-category-nav-list__open-class},
    &:hover > .header__main__product-categories__nav-list,
    &:focus > .header__main__product-categories__nav-list
    {
      @include element-invisible-off($position: absolute, $width: 100%, $overflow: null);
      overflow-y: auto;
      top: 0;
      bottom: 0;
      left: 100%;
      box-shadow: none;
      background: $app-header-main__product-category-nav-list-item__dropdown__background;
      border-top-right-radius: $base__border-radius;
      border-bottom-right-radius: $base__border-radius;
    }
  }
}
.flexbox .header__main{
  &__row,
  &__toolbar__container{
    display: flex;
    align-items: center;
  }
  &__toolbar__container{
    flex: 1 1 auto;
    justify-content: flex-end;
  }
  &__logo__column{
    flex: 0 0 nth($app-header-main__logo__column__min-width, 1);
  }
  &__logo__img{
  }
  &__toolbar__search-icon__column,
  &__search-icon__column__medium-up{
    flex: 0 0 $app-header-main__search__icon__column__min-width;
  }
  &__search__column{
    flex: 1;
    align-items: center;
    min-width: rem-calc(150px);
  }
  &__right__column{
    align-items: center;
    height: 100%;
  }
  &__right__column__tagline__column{
    flex: 1 1 auto;
  }
  &__right__column__tagline{
  }
  &__right__column__phone__column{
    flex: 1 1 auto;
  }
  &__right__column__phone{
  }
  &__right__column__lpg__column{
    flex: 1 1 auto;
  }
  &__right__column__lpg__img{
  }
}
.no-js .header__main,
.no-flexbox .header__main{
  &__row{
    display: block;
  }
  &__toolbar{
    &__container{
      display: table;
      table-layout: fixed;
      width: auto;
      margin: 0 0 0 auto;
    }
    &__column{
         display: table-cell;
         vertical-align: middle;
    }
    &__search-icon__column{

    }
    &__cart-icon__column{

    }
    &__toggle__column{

    }
    &__search__link{
      padding-top: rem-calc(9);
    }
    &__cart__link{
      padding: rem-calc(9) rem-calc(5);
    }
    &__toggle__link{

    }
    &__link{
      text-align: center;
    }
  }
  &__logo__column{
    float: left;
    padding-top: rem-calc(8);
    width: nth($app-header-main__logo__column__min-width, 1);
  }
  &__logo__img{
  }
  &__search-icon__column__medium-up{
    float: left;
    padding-top: rem-calc(29);
  }
  &__search__column{
    float: left;
  }
  &__right__column,
  &__search__column,
  &__search__input,
  &__right__column__phone__column,
  &__right__column__tagline__column{
    height: nth($app-header-main__row__height, 3);
  }
  &__right__column{
    //display: table; //block;
    table-layout: fixed;
    margin-right: 0;
    margin-left: auto;
  }
  &__right__column__tagline__column,
  &__right__column__phone__column{
    display: none;
    vertical-align: middle;
  }
  &__right__column__tagline,
  &__right__column__phone{
  }
  &__right__column__phone__column{
  }
  &__right__column__phone{
  }
  &__right__column__lpg__column{
    display: table-cell;
    vertical-align: middle;
  }
  &__right__column__lpg__img{
  }
}
@include mq(medium-up) {
  .header__main{
    &__row{
      height: nth($app-header-main__row__height, 3);
    }
    &__logo__column{
      padding-left: $app-grid__column-gutter / 3;
      width: nth($app-header-main__logo__column__min-width, 3);
    }
    &__toolbar__container{
      display: none;
    }
    &__search__column,
    &__search-icon__column__medium-up{
      display: block;
    }
    &__right__column__tagline{
      font-size: nth($app-header-main__tagline__font-size, 2);
    }
    &__nav__list{
      &--left{
        display: block;
        float: left;
      }
      &-item{
        &--left{
          display: block;
          float: left;
        }
      }
      &-item__link{
        padding: rem-calc(9) rem-calc(15);
        font-size: $base__font-size;
        &--first{
          padding-left: rem-calc(25);
        }
      }
      &-item__hide__medium-up{
        display: none;
      }
      &--right,
      &-item__show__medium-up{
        display: block;
      }
    }
  }
  .flexbox .header__main{
    &__toolbar__container{
      display: none;
    }
    &__right__column{
      display: flex;
      justify-content: right;
    }
    &__right__column__lpg__column{
      max-width: nth($app-header-main__right__column__lpg__column__max-width, 2);
    }
    &__logo__column{
      flex: 0 0 nth($app-header-main__logo__column__min-width, 3);
    }
  }
  .no-js .header__main,
  .no-flexbox .header__main{
    &__toolbar__container{
      display: none;
    }
    &__right__column{
      display: table;
    }
    &__logo__column{
      width: nth($app-header-main__logo__column__min-width, 3);
    }
  }
}
@include mq(medium-alt-up) {
  .header__main{
    &__right__column__tagline__column{
      display: block;
    }
    &__right__column__lpg__img{
      max-width: nth($app-header-main__right__column__lpg__img__max-width, 2);
    }
    &__nav__list{
      &-item__link{
        padding-right: $app-grid__column-gutter / 2;
        padding-left: $app-grid__column-gutter / 2;
        &--first{
          padding-left: rem-calc(26);
        }
      }
      &-item__account{
        &__link__content{
          display: none;
        }
        &__link__content__medium-up{
          display: block;
        }
      }
      &-item__show-inline__medium-alt-up{
        display: inline;
      }
    }
  }
  .flexbox .header__main{
    &__right__column{
      //flex: 1 1 auto;
    }
  }
  .no-js .header__main,
  .no-flexbox .header__main{
    &__right__column__tagline__column{
      display: table-cell;
    }
  }
}
@include mq(large-up) {
  .header__main{
    &__right__column__tagline__column{
      min-width: rem-calc(200);
    }
    &__right__column__tagline{
      font-size: nth($app-header-main__tagline__font-size, 3);
    }
    &__right__column__phone__column{
      display: block;
    }
    &__right__column__phone{
      font-size: nth($app-header-main__phone__font-size, 3);
    }
    &__nav__list{
      &-item__account{
      }
      &-item__quick-order{
        display: block;
      }
    }
  }
  .flexbox .header__main{

  }
  .no-js .header__main,
  .no-flexbox .header__main{
    &__right__column__phone__column{
    }
  }
}
@include mq(xlarge-up) {
  .header__main{

  }
  .no-js .header__main,
  .no-flexbox .header__main{
    &__right__column__phone__column{
      display: table-cell;
    }
  }
}
