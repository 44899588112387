.panel{
    padding: 0;
    margin-bottom: $app-grid__column-gutter / 2;
    border: 1px solid $base__light-gray__border-color;
    border-radius: $base__border-radius;
    color: $base__dark-gray-color;
    &--has-icon{
        display: flex;
    }
    &__icon__container{
        display: flex;
        align-items: center;
        border-top-right-radius: $base__border-radius;
        border-bottom-right-radius: $base__border-radius;
        border-right: 1px solid $base__light-gray__border-color;
    }
    &__content{
        padding: $app-grid__column-gutter / 2;
        flex: 1 1 auto;
    }
    &--is-thin{
        & > .panel__content{
            padding: $app-grid__column-gutter / 4;
        }
    }
    &--is-success{
        background: screen($base__success-color, $base__color-shift);
        border-color: shade($base__success-color, $base__color-shift);
        color: shade($base__success-color, $base__color-shift);
        & .panel__icon__container{
            background: $base__success-color;
            color: screen($base__success-color, $base__color-shift);
            border-right-color: shade($base__success-color, $base__color-shift);
        }
    }
    &--is-info{
        background: $base__secondary-color;
        color: shade($base__secondary-color--dark, $base__color-shift);
        & .panel__icon__container{
            background: $base__secondary-color--dark;
            color: $base__secondary-color;
            border-right-color: shade($base__secondary-color--dark, $base__color-shift);
        }
    }
    &--is-alert{
        background: screen($base__alert-color, $base__color-shift);
        border-color: shade($base__alert-color, $base__color-shift);
        color: shade($base__alert-color, $base__color-shift);
        & .panel__icon__container{
            background: $base__alert-color;
            color: screen($base__alert-color, $base__color-shift);
            border-right-color: shade($base__alert-color, $base__color-shift);
        }
    }
    &--is-error{
        background: screen($base__error-color, $base__color-shift);
        border-color: $base__error-color;
        color: $base__error-color;
        & .panel__icon__container{
            background: $base__error-color;
            color: screen($base__error-color, $base__color-shift);
            border-right-color: shade($base__error-color, $base__color-shift);
        }
    }
    &--is-warning{
        background: screen($base__warning-color, $base__color-shift);
        border-color: shade($base__warning-color, $base__color-shift);
        color: shade($base__warning-color, $base__color-shift);
        & .panel__icon__container{
            background: $base__warning-color;
            color: screen($base__warning-color, $base__color-shift);
            border-right-color: shade($base__warning-color, $base__color-shift);
        }
    }
    &--is-gray{
        border-color: $base__gray-color;
        background: $base__lightest-gray-color;
    }
    &__list{
        margin-bottom: 0;
    }
}
