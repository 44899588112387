.list-tool-bar{
  @include clearfix();
  margin-bottom: rem-calc(8);
}

.list-search-input[type="search"]{
  width: 35%;
  display: inline-block;
  margin-bottom: 0;
}
select.list-select--records-per-page{
  width: 20%;
  display: inline-block;
  margin-bottom: 0;
}
.list-button--previous, .list-button--next{
  font-size: 1.5rem;
  display: inline-block;
}
.list-sort{
  position: relative;
  &.asc:before, &.desc:before{
    @include icon-font-defaults();
    position: absolute;
    top: rem-calc(8);
    right: 0;
    display: block;
  }
  &.asc:before{
    content: "\e002";
  }
  &.desc:before{
    content: "\e003";
  }
}
