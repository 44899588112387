$app-cart__line-item__description__sku-label__color: #333333 !default;
$app-cart__line-item__description__sku-label-light__color: #cdc3ae !default;
$app-cart__line-item__description__sku-label__padding: rem-calc(4) rem-calc(8) rem-calc(4) 0 !default;
$app-cart__line-item__description__sku-label__font-size: rem-calc(12) !default;
$app-cart__line-item__description__sku-label__font-family: $base__font-family !default;
$app-cart__line-item__description__sku-label__font-weight: $base__font-weight !default;
$app-cart__line-item__description__name-small__font-size: rem-calc(13) !default;
$app-cart__line-item__description__name-small__line-height: 1.3 !default;
$app-cart__shipping-summary__list-item__padding: $app-grid__column-gutter / 2 !default;
$app-cart__shipping-summary__list-item__font-size: rem-calc(13) !default;
$app-cart__shipping-summary__list-item__price__font-size: rem-calc(16) !default;
$app-cart__component__heading__margin: rem-calc(4) !default;
$app-cart__component__heading__padding: null !default;
$app-cart__component__heading__background-color: null !default;
$app-cart__component__heading__background: $app-cart__component__heading__background-color !default;
$app-cart__component__heading__border-color: null !default;
$app-cart__component__heading__border-bottom: null !default;
$app-cart__component__headline__margin: null !default;
$app-cart__component__headline__font-size: rem-calc(18) !default;
$app-cart__component__headline-note__color: $app-note__color !default;
$app-cart__component__headline-note__font-size: $app-note__font-size !default;
$app-cart__component__headline-note__font-style: italic !default;
$app-cart__component__list-item__padding: rem-calc(7) !default;
$app-cart__component__list-item__border-color: #dddddd !default;
$app-cart__component__list-item__border: 1px solid $app-cart__component__list-item__border-color !default;
$app-cart__component__list-item__action-column__width: rem-calc(40) !default;
$app-cart__component__list-item__description-column__font-size: rem-calc(14) !default;
$app-cart__component__list-item__description-column__line-height: 1.3 !default;

$app-cart__shipping-options__list-item__border-bottom: 1px solid #dddddd !default;
$app-cart__shipping-options__list-item__border--selected: 1px solid #D8D8D8 !default;
$app-cart__shipping-options__list-item__background: null !default;
$app-cart__shipping-options__list-item__background--selected: #f0f6fa !default;

$app-cart__shipping-options__list-item__link__padding: rem-calc(10) !default;
$app-cart__shipping-options__list-item__link__border: null !default;
$app-cart__shipping-options__list-item__link__border--hover: null !default;
$app-cart__shipping-options__list-item__link__background: null !default;
$app-cart__shipping-options__list-item__link__background--hover: #f0f6fa !default;

$app-cart__shipping-options__disclaimer__font-size: $app-note__font-size !default;
$app-cart__shipping-options__disclaimer__line-height: $app-note__line-height !default;
$app-cart__shipping-options__disclaimer__color: $app-note__color !default;

/*********************
This file needs to be rewritten still
- needs BEM syntax
- needs refactoring of color values/etc. into variables
**********************/

.cart__table{

}
.cart__table__li-row{

}
.cart__table__li-col{

}
.cart__line-item__details__qty-input__col{
  float: left;
  width: grid-calc-width(5, $app-grid__total-columns);
}
.cart__line-item__details__save-qty-button__col{
  float: left;
  width: grid-calc-width(7, $app-grid__total-columns);
}

.cart__line-item__qty{
  &__input{
    margin-bottom: 0;
    width: rem-calc(89.5);
  }
  &__input__column{
    //display: none;
  }
  &__save-btn{
    margin: 0;
    width: auto;
    padding: 9px 0;
    font-size: 12px;
    border-left: none;
    line-height: 1.8;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    height: $app-form__input-buttons__match-text-input__height;
  }
  &__save-btn__column{
    display: none;
    float: left;
    &.active{
      display: block;
    }
  }
}

.cart__line-item__description{
  &__sku-label{
    font-size: $app-cart__line-item__description__sku-label__font-size;
    font-weight: $app-cart__line-item__description__sku-label__font-weight;
    font-family: $app-cart__line-item__description__sku-label__font-family;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    white-space: nowrap;
    display: inline-block;
    margin-bottom: inherit;
    padding: $app-cart__line-item__description__sku-label__padding;
    vertical-align: middle;
    color: $app-cart__line-item__description__sku-label__color;
    &--light{
      color: $app-cart__line-item__description__sku-label-light__color;
    }
  }
  &__qty-label{
    margin-right: rem-calc(8);
  }
  &__name{
    display: block;
    &--small{
      font-size: $app-cart__line-item__description__name-small__font-size;
      line-height: $app-cart__line-item__description__name-small__line-height;
    }
  }
}

.cart__shipping-options{
  &__list{
    list-style: none;
    margin: 0;
  }
  &__list-item{
    background: $app-cart__shipping-options__list-item__background;
    border-bottom: $app-cart__shipping-options__list-item__border-bottom;
    border-radius: $base__border-radius;
    &:last-child{
      //border-bottom: none;
    }
    &:first-child{
      border-top: $app-cart__shipping-options__list-item__border-bottom;
    }
    &--selected{
      border: $app-cart__shipping-options__list-item__border--selected;
      background: $app-cart__shipping-options__list-item__background--selected;
      & .cart__shipping-options__list-item__link{
        color: $base__anchor__font-color--hover;
      }
    }
    &__link{
      overflow: hidden;
      @include single-transition('background');
      display: block;
      padding: $app-cart__shipping-options__list-item__link__padding;
      background: $app-cart__shipping-options__list-item__link__background;
      &:hover{
        background: $app-cart__shipping-options__list-item__link__background--hover;
      }
    }
    &__name{
      display: block;
      margin-right: rem-calc(100);
    }
    &__value{
      display: block;
      float: right;
      width: rem-calc(100);
      text-align: right;
    }
    &__show-expedited{
      padding: $app-cart__shipping-options__list-item__link__padding;
    }
  }
  &__disclaimer{
    font-size: $app-cart__shipping-options__disclaimer__font-size;
    margin-top: $app-grid__column-gutter / 2;
    margin-bottom: $app-grid__column-gutter / 2;
    padding-left: $app-grid__column-gutter / 2;
    padding-right: $app-grid__column-gutter / 2;
    line-height: $app-cart__shipping-options__disclaimer__line-height;
    color: $app-cart__shipping-options__disclaimer__color;
  }
  &__headline{
    padding-left: $app-grid__column-gutter / 2;
    padding-right: $app-grid__column-gutter / 2;
    margin-bottom: rem-calc(8);
    text-align: center;
    &__extra-info{
      display: inline-block;
      color: lighten($app-note__color, $base__color-shift);
      font-size: rem-calc(13);
    }
  }
}



.cart__shipping-summary{
  &__list{
    list-style: none;
    margin: 0;
  }
  &__list-item{
    padding: $app-cart__shipping-summary__list-item__padding;
    font-size: $app-cart__shipping-summary__list-item__font-size;
    &__price{
      font-size: $app-cart__shipping-summary__list-item__price__font-size;
    }
  }
}

.cart__component{
  &__heading{
    margin: $app-cart__component__heading__margin;
    background: $app-cart__component__heading__background;
    border-bottom: $app-cart__component__heading__border-bottom;
  }
  &__headline{
    display: inline-block;
    margin: $app-cart__component__headline__margin;
    font-size: $app-cart__component__headline__font-size;
    &-note{
      color: $app-cart__component__headline-note__color;
      font-size: $app-cart__component__headline-note__font-size;
      font-style: $app-cart__component__headline-note__font-style;
      display: block;
    }
  }
  &__list{
    list-style: none;
    margin-left: 0;
    &-item{
      @include clearfix();
      padding: $app-cart__component__list-item__padding $app-cart__component__list-item__padding $app-cart__component__list-item__padding rem-calc(13);
      border-bottom: $app-cart__component__list-item__border;
      &__description__sku-label{
        @extend .cart__line-item__description__sku-label;
        display: block;
        text-align: left;
        padding-bottom: 0;
      }
    }
    &-item__link{

    }
    &-item__action-column{
      display: block;
      float: left;
      max-width: $app-cart__component__list-item__action-column__width;
    }
    &-item__description-column{
      display: block;
      margin-left: $app-cart__component__list-item__action-column__width;
      font-size: $app-cart__component__list-item__description-column__font-size;
      line-height: $app-cart__component__list-item__description-column__line-height;
    }
  }
}
  // legacy
  // refactor to BEM naming convention
  .cart{

  }
  .cart-sub-nav-list{
    margin: 0 0 rem-calc(10) 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    & li{
      float: left;
      display: block;
      border-left: 1px solid #ebd4ba;// lighter version of #ccb59b;
      margin-left: rem-calc(1);
      &:first-child{
        margin-left: 0;
        border-left: none;
      }
      & a{
        display: block;
        padding: rem-calc(5) rem-calc(20);
      }
    }
  }
  .cart-line-items{
    table-layout: fixed;
    width: 100%;
    .line-item-action-col{
      width: rem-calc(50);//10%;
    }
    .line-item-options-col{
      display: none;
    }
    .line-item-image-col{
      //width: 28%;
    }
    .line-item-quantity-col{
      display: none;
      input,select{

      }
    }
    .line-item-description-col{
      //width: 72%;
    }
    th.line-item-price-col{
      //text-align: center;
    }
    .line-item-price-col{
      display: none;
      text-align: right;
    }
    th.line-item-subtotal-col{
      //text-align: center;
    }
    .line-item-subtotal-col{
      display: none;
      text-align: right;
    }
    th.line-item-extra-charges-col{
      //text-align: center;
    }
    .line-item-extra-charges-col{
      display: none;
      text-align: right;
    }
    th.line-item-discount-col{
      //text-align: center;
    }
    .line-item-discount-col{
      display: none;
      text-align: right;
    }
    th.line-item-total-col{
      //text-align: center;
    }
    .line-item-total-col{
      width: rem-calc(85);
      font-weight: bold;
      text-align: right;
    }

    .line-item-actions{
      margin-bottom: 0;
      &>li{
        display: none;
        &.line-item-action-toggle{
          display: block;
        }
      }
    }
    .approved-initials-info{
      margin-bottom: 0;
      font-size: rem-calc(12);
    }
    .extra-charges-detail{
      display: none;
    }
    .line-item-details{
      display: none;
      background: #ffffff;//#eeeeee;
      //.line-item-details{
      //    padding-right: rem-calc(10);
      //}
      border-bottom: 1px solid #ccb59b;
      &.active{
        display: table-row;
      }
      .row.collapse .line-item-details-qty-input{
        width: 41.66667%;
        padding-left: rem-calc(7);
      }
      //.line-item-option-details{
      //    padding-right: rem-calc(10);
      //}
      .row.collapse input .line-item-details-save-qty-button{
        border-top-right-radius: $base__border-radius;
        border-bottom-right-radius: $base__border-radius;
      }
    }
    tr td.line-item-details-column{
      padding: 0 0 rem-calc(12) 0;
    }
    .line-item-details-content{
      @include triangle(
                      top,
                      rem-calc(35),
                      20px,
              $color: #ffffff,
              $border-color: #cccccc
      );
      position: relative;
      background: #ffffff;
      border-top: 1px solid #cccccc;
      padding: 0 rem-calc(12) 0 rem-calc(12);
    }
    .line-item-details-items{
      margin: 0;
      list-style: none;
      &>li{
        font-size: rem-calc(13.5);
        padding: rem-calc(4);
        &.line-item-details-item-label{
          padding-bottom: 0;
        }
        &.line-item-details-item-qty-fieldset{
          @include clearfix();
          padding-top: 0;
        }
      }
    }
    .line-item-details-qty{
      max-width: rem-calc(400);
      padding-right: rem-calc(1);
    }
    .line-item-details-qty-input{
      margin-bottom: 0;
    }
    .line-item-details-save-qty-button{
      border-top: 1px solid #a7cae1;
      border-right: 1px solid #a7cae1;
      border-bottom: 1px solid #a7cae1;
    }
    .line-item-details-item-pricing{
      &>li{
        padding-left: rem-calc(5);
        padding-right: rem-calc(5);
        &:first-child{
          padding-left: 0;
        }
        p{
          border-top: none;
        }
      }
    }
    .inline-list.line-item-details-button-group{
      margin: 0;
      & li{
        margin: 0 rem-calc(8) 0 0;
        padding: rem-calc(1) 0;
      }
      /*.button{
        margin: 0;
      }*/
    }
    .line-item-details-remove-button{
      //background: #ffffff;
      padding: rem-calc(8) rem-calc(9) rem-calc(10) rem-calc(9);
      margin-bottom: 0;
      //color: #986e3d;
      //border-color: #986e3d;
      &:hover,
      &:focus{
        //background: #986e3d;
        //color: #7a5831;
        //border-color: #7a5831;
      }
    }
    /***************
        table.line-item-details-pricing-table{
            width: 100%;
            thead{
                background: none;
            }
            th,
            td{
                padding: rem-calc(5);
                border-left: 1px solid #eeeeee;
                text-align: center;
                &:first-child{
                    border-left: none;
                }
            }
        }
    ****************/
    .line-item-option-details{
      background: #aaaaaa;
    }
    .line-item-option-details-content{
      margin-left: 0;
    }
  }

  .line-item-quantity-save-button{
  }
  .cart-summary{

  }
  .cart__summary-notes__column{
    @include grid-column-padding($left: false);
    margin-top: $app-grid__column-gutter / 2;
    margin-bottom: $app-grid__column-gutter / 2;
  }
  .cart__final-summary__column{
    padding-left: $app-grid__column-gutter / 2;
    padding-right: rem-calc(11); // this helps text line up with table above it
  }
  .cart__final-summary__list{
    overflow: hidden; // contain the floats
    &-item__name{
      width: 61.5%;
      text-align: right;
      float: left;
      clear: left;
      &.cart-grand-total{
      }
    }
    &-item__value{
      text-align: right;
    }
  }

  .line-item-preview-pdf-button{
    width: 100%;
    margin-bottom: rem-calc(10);
  }

  .line-item-dropdown{
    box-shadow: 2px 2px 6px rgba(60,60,60, .8);
  }

  .line-item-dropdown-content{
    padding: rem-calc(8);
  }

  .line-item-dropdown-heading{
    background: #986e3d;
    color: #f9f6e4;
    text-align: center;
  }

  /*.f-dropdown.line-item-dropdown.drop-left,
  .f-dropdown.line-item-dropdown.drop-right,
  .f-dropdown.line-item-dropdown.drop-top{
    background: #f9f6e4;
    &:hover,
    &:focus,
    & li.line-item-dropdown-content:hover,
    & li.line-item-dropdown-content:focus{
      background: #f9f6e4;
    }
    & li.line-item-dropdown-heading:hover,
    & li.line-item-dropdown-heading:focus{
      background: #986e3d;
    }
  }*/

  .label.secondary.line-item-description-sku-label{
    background: transparent;
    font-size: rem-calc(12);
  }

  /*.line-item-description-name{
    display: block;
    &--small{
      font-size: rem-calc(13);
      line-height: 1.3;
    }
  }*/

  .cart__main-checkout__navigation{
    text-align: right;
    margin-bottom: $base__paragraph__margin-bottom;
  }
  .cart__checkout__btn__primary,
  .cart__checkout__btn__note{
    width: 100%;
    margin-bottom: 0;
  }
  .cart__checkout__btn__note{
    margin: rem-calc(8) 0 rem-calc(8) auto;
    font-size: $app-note__font-size;
    line-height: $app-note__line-height;
    color: lighten($app-note__color, $base__color-shift);
    text-align: center;
    font-style: italic;
  }

  @include mq(medium-alt-up) {
    .line-item-preview-pdf-button{
      width: auto;
      margin-bottom: 0;
    }
    .cart-line-items{
      .line-item-options-col{
        display: table-cell;
      }
    }
  }

  @include mq(medium-only){
    .cart__summary-notes__column{
      @include grid-column(7, $padding: false, $pull: 5);
    }
    .cart__final-summary__column{
      @include grid-column(5, $padding: false, $push: 7);
    }
  }
  @include mq(medium-up){
    .cart__component{
      &__headline{
        &-note{
          display: inline;
        }
      }
      &__list-item__description{
        &-column {
          margin-top: rem-calc(2.5);
        }
        &__sku-label{
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    .cart__summary-notes__column{
      margin-top: 0;
      margin-bottom: 0;
      padding-right: $app-grid__column-gutter;
    }
    .cart-line-items{
      table-layout: auto;
      .line-item-action-col{
        //width: auto;
      }
      //        .line-item-options-col{
      //            display: table-cell;
      //        }
      .line-item-image-col{
        width: auto;
      }
      .line-item-description-col{
        width: auto;
      }
      .line-item-price-col{
        display: table-cell;
      }
      .line-item-subtotal-col{
        display: table-cell;
      }
      .line-item-extra-charges-col{
        display: table-cell;
      }
      .line-item-discount-col{
        display: table-cell;
      }
      .line-item-total-col{
        width: auto;
      }
    }
    .pricing.line-item-details-item-pricing{
      display: none;
    }
    .cart__checkout__btn__primary,
    .cart__checkout__btn__note{
      width: 41.66667%;
    }
  }

  @include mq(large-up){
    .cart__line-item__qty{
      &__input{
      }
      &__input__column{
        &.active{
          width: 70.5%;
          float: left;
        }
      }
      &__save-btn{
      }
      &__save-btn__column{
      }
    }
    .active .cart__line-item__qty__input{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .cart__component__list-item{
      padding-left: rem-calc(29);
    }
    .cart__summary-notes__column{
      @include grid-column(8, $padding: false, $pull: 4);
    }
    .cart__final-summary__column{
      @include grid-column(4, $padding: false, $push: 8);
    }

    .cart-line-items{
      .line-item-quantity-col{
        display: table-cell;
        width: rem-calc(123);
        /*input,select{
          width: 100%;
        }
        input{
          &.active{
            width: 70.5%;
            float: left;
          }
        }*/
      }
      .line-item-actions{
        &>li{
          display: block;
          &.line-item-action-toggle{
            display: none;
          }
        }
      }
    }
    .cart__line-item__description__qty-label{
      display: none;
    }
    .label.secondary.line-item-description-sku-label{
      padding-left: 0;
    }
    .line-item-description-name{
      display: inline-block;
    }
    .f-dropdown.drop-right.line-item-actions-dropdown{
      width: auto;
      border-radius: $base__border-radius;
    }
    .line-item-dropdown-remove-button{
      padding: rem-calc(9.2) rem-calc(16) rem-calc(11.9) rem-calc(16);
      font-size: rem-calc(17.7);
    }
    .cart__checkout__btn__primary,
    .cart__checkout__btn__note{
      width: 33.33333%;
    }
  }

