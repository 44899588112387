$app-mq__screen: "only screen" !default;

$app-mq__breakpoints: (
  "small": em-calc(640),
  "medium": em-calc(1024),
  "medium-alt": em-calc(880),
  "large": em-calc(1440),
) !default;

$app-mq__small-up: #{$app-mq__screen} !default;
$app-mq__small-only: "#{$app-mq__screen} and ( max-width: #{map-get($app-mq__breakpoints, "small")} )" !default;
$app-mq__medium-up: "only screen and ( min-width: #{map-get($app-mq__breakpoints, "small")} )" !default;
$app-mq__medium-only: "#{$app-mq__screen} and ( min-width: #{map-get($app-mq__breakpoints, "small")} ) and (max-width: #{map-get($app-mq__breakpoints, "medium")} )" !default;
$app-mq__medium-alt-up: "#{$app-mq__screen} and ( min-width: #{map-get($app-mq__breakpoints, "medium-alt")} )" !default;
$app-mq__large-up: "#{$app-mq__screen} and ( min-width: #{map-get($app-mq__breakpoints, "medium")} )" !default;
$app-mq__landscape: "#{$app-mq__screen} and ( orientation: landscape )" !default;
$app-mq__portrait: "#{$app-mq__screen} and ( orientation: landscape )" !default;

$app-mq__queries: (
    "small-up":  $app-mq__small-up,
    "small-only": $app-mq__small-only,
    "medium-up": $app-mq__medium-up,
    "medium-only": $app-mq__medium-only,
    "medium-alt-up": $app-mq__medium-alt-up,
    "large-up":  $app-mq__large-up,
    "landscape": $app-mq__landscape,
    "portrait":  $app-mq__portrait
) !default;

@mixin mq($name) {
  @if map-has-key($app-mq__queries, $name) {
    @media #{map-get($app-mq__queries, $name)} {
      @content;
    }
  }
  @else {
    @warn "Couldn't find a breakpoint named `#{$name}`.";
  }
}

