.magnify{
  &__glass{
    @include single-transition(height, $base__transition-speed);
    @include shadows(2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    border: 0 solid #bbbbbb;
    border-radius: $base__border-radius;
    background-color: #ffffff;
  }
}