.site-map{
  &__list{
    list-style: none;
    &-item{
      border-bottom: 1px solid #dddddd;
    }
    &-item__link{
      display: block;
      padding: rem-calc(10);
    }
  }
}