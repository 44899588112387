.product-history-table__container{

}
.product-history-table{
  width: 100%;
  position: relative;
  table-layout: fixed;
}
.product-history-table__row{

}
.product-history-table__cell{

  &__product-actions{
    width: rem-calc(32);
    padding: 0;
  }
  &__product-id{
    width: rem-calc(85);
  }
  &__product-name{
    overflow: hidden;
    white-space: nowrap;
  }
  &__last-order-date{
    display: none;
    width: rem-calc(112);
    text-align: center;
  }
}

.product-history-table__dropdown{
  margin-top: - rem-calc(10);
  padding-top: rem-calc(8);
  padding-bottom: rem-calc(8);
  max-width: none;
  width: grid-calc-width(11, $app-grid__total-columns);
}
.product-history-table__dropdown__row{
  @include clearfix();
}
.product-history-table__dropdown__actions-list{
  list-style: none;
  margin-left: 0;
}
.product-history-table__dropdown__actions-item {
  padding: rem-calc(5);
  border-bottom: 1px solid #aaaaaa;
  &:last-child{
    border-bottom: none;
  }
}
.product-history-table__dropdown__actions-list,
.product-history-table__dropdown__thumbnail,
.product-history-table__dropdown__info{
  padding-left: ($app-grid__column-gutter / 2);
  padding-right: ($app-grid__column-gutter / 2);
  width: grid-calc-width(12, $app-grid__total-columns);
}
.product-history-table__dropdown__thumbnail{
  text-align: center;
}

@include mq(medium-up) {
  .product-history-table__cell {
    &__last-order-date {
      display: table-cell;
    }
  }
  .product-history-table__dropdown__actions-list{
    float: left;
    width: 26%;//grid-calc(4, $total-columns);
  }
  .product-history-table__dropdown__thumbnail{
    float: left;
    width: 30%;//grid-calc(3, $total-columns);
  }
  .product-history-table__dropdown__info{
    float: right;
    width: 44%;//grid-calc(5, $total-columns);
  }
}