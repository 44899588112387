.order{
  &__info__column{
    border-bottom: 1px solid #eeeeee;
    padding-bottom: $app-grid__column-gutter / 2;
    margin-bottom: $app-grid__column-gutter / 2;
    &:last-child{
      border-bottom: none;
    }
  }
  &__line-item__description__row{
    border-bottom: 1px solid #eeeeee;
    padding: $app-grid__column-gutter / 4 0;
    &:last-child{
      border-bottom: none;
    }
  }
}
.order__billing-info__column,
.order__shipping-info__column,
.order__payment-info__column{

}

@include mq(medium-up){
  .order{
    &__info__column{
      border-bottom: none;
      margin-bottom: 0;
      padding: 0 $app-grid__column-gutter / 2 0 $app-grid__column-gutter / 2;
      border-right: 1px solid #dddddd;
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
        border-right: none;
      }
    }
  }
  .order__billing-info__column,
  .order__shipping-info__column,
  .order__payment-info__column{

  }
}

@include mq(large-up){
  .order__billing-info__column,
  .order__shipping-info__column,
  .order__payment-info__column{
    @include grid-column(4, $padding: false);
  }
  .order__billing-info__column{
    padding-right: $app-grid__column-gutter / 2;
  }
  .order__shipping-info__column{
    @include grid-column-padding();
  }
  .order__payment-info__column{
    padding-left: $app-grid__column-gutter / 2;
  }
}
