//$app-product-list__product-order-info__input__border-color: #a7cae1 !default;
$app-product__qty-calculated__font-style: italic !default;
$app-product__qty-calculated__font-weight: 100 !default;
$app-product__qty-calculated__color: #888888 !default;
$app-product-list__display-choice-item__border-right: 1px solid #999999 !default;
$app-product-list__display-choice-button__background-color: #ffffff !default;
$app-product-list__display-choice-button__background-color--hover: #f0f6fa !default;
$app-product-list__display-choice-button__border: 1px solid #d8d8d8 !default;
$app-product-list__display-choice-button__border--selected: 1px solid #999999 !default;
$app-product-list__display-choice-button__padding: rem-calc(4) rem-calc(4.8) rem-calc(8) rem-calc(4.8) !default;
$app-product-list__product-description__font-size: rem-calc(15) !default;
$app-product-list__product-description__line-height: 1.4 !default;
$app-product-list__product-description__headline__font-size: null !default;
$app-product-list__product-description__headline__font-weight: bold !default;
$app-product-list__product-description__headline-sku__font-size: rem-calc(14) !default;
$app-product-list__product-description__headline-sku__font-weight: normal !default;
$app-product-list__large__product-list-item__border-bottom: 1px solid #999999 !default;
$app-product-list__small__product-list__product-information__border-top: 1px solid #dddddd !default;
$app-product-list__small__product-list__product-information__font-weight: bold !default;
$app-product-list__small__product-list__product-detail-link__font-size: rem-calc(13) !default;
$app-product-list__small__product-list__product-detail-link__font-weight: bold !default;
$app-product-list__small__product-list__product-detail-link__line-height: 1.3 !default;
$app-product-list__small__product-list__product-detail-sku__font-size: rem-calc(12) !default;
$app-product-list__small__product-list__product-detail-sku__font-weight: normal !default;

.product-list__add-to-cart__form__input-list{
  @include clearfix();
  &-item{
    &__quantity{
      @include grid-column(4, $collapse: true);
    }
    &__quantity-calculated{
      @include grid-column($columns:5, $collapse: true,$last-column: true);
      font-style: $app-product__qty-calculated__font-style;
      padding-left: rem-calc(12);
      font-size: rem-calc(18);
      line-height: rem-calc(40);
      color: $app-product__qty-calculated__color;
      font-weight: $app-product__qty-calculated__font-weight;
    }
    &__submit{
      @include grid-column($columns:3, $collapse: true);
    }
  }
}
.product-list-display-choice-list{
  @include clearfix();
  list-style: none;
  margin: 0 auto rem-calc(13) auto;
  padding: 0;
}
.product-list-display-choice-title{
  display: block;
  float: left;
  margin-right: rem-calc(13);
}
.product-list-display-choice-item{
  display: block;
  float: left;
  margin-right: rem-calc(13);
  padding-right: rem-calc(13);
  border-right: $app-product-list__display-choice-item__border-right;
  &:last-child{
    border-right: none;
  }
  &.selected{
    & .product-list-display-choice-button{
      border: $app-product-list__display-choice-button__border--selected;
      //color: #333333;
    }
  }
}
.product-list-display-choice-button{
  border: $app-product-list__display-choice-button__border;
  border-radius: $base__border-radius;
  padding: $app-product-list__display-choice-button__padding;
  //color: #666666;
  background: $app-product-list__display-choice-button__background-color;
  transition: background-color $base__transition-speed $base__transition-ease, color $base__transition-speed $base__transition-ease;
  &:hover, &:active{
    background: $app-product-list__display-choice-button__background-color--hover;
    //override global a:hover color
    //color: #333333;
  }
}

.product-list{
  list-style: none;
  margin: 0;
  &-item {
    margin-bottom: rem-calc(35);
    display: inline-block;
    vertical-align: top;
    .product-list-product-display{
      position: relative;
      text-align: center;
      img{

      }
      /*
          &:hover,&:focus{
              .quick-view{
                  left: 50%;
              }
          }
          .quick-view{
              position: absolute;
              width: 70%;
              left: -999rem;
              bottom: rem-calc(40);
              margin: auto auto auto -35%;
              text-align: center;
              box-shadow: 0 2px 4px rgba(60,60,60, .8);
          }
      */
    }
    .product-list-product-information{
      display: none;
    }
    .product-list-product-order-information{
      display: none;
    }
  }
  @at-root .small-list{
    & .product-list-item{
      @include grid-column($columns:6,$float:false);
    }
    & .product-list-product-img{
      max-height: rem-calc(270);
      overflow: hidden;
    }
  }
  @at-root .medium-list{
    & .product-list-item{
      @include grid-column($columns:12,$float:false);
    }
  }
  @at-root .large-list{
    & .product-list-item{
      display: block;
      padding: rem-calc(16 0);
      border-bottom: $app-product-list__large__product-list-item__border-bottom;
      @include grid-row($behavior:nest-collapse);
    }
    & .product-list-product-display{
      @include grid-column($columns:12,$last-column:true);
    }
    & .product-list-product-information{
      display: block;
      @include grid-column($columns:12,$last-column:true);
    }
    & .product-list-product-order-information{
      display: block;
      @include grid-column($columns:12,$last-column:true);
    }

  }
}
.product-list__product-description{
  font-size: $app-product-list__product-description__font-size;
  line-height: $app-product-list__product-description__line-height;
  margin-bottom: rem-calc(10);
  &__headline{
    margin-top: 0;
    font-size: $app-product-list__product-description__headline__font-size;
    font-weight: $app-product-list__product-description__headline__font-weight;
    &-link{

    }
    &-sku{
      display: inline-block;
      font-size: $app-product-list__product-description__headline-sku__font-size;
      font-weight: $app-product-list__product-description__headline-sku__font-weight;
      padding-right: rem-calc(10);
    }
  }
}

.product-list__product-quick-view__button{
  // set visibility instead of display so transition works
  position: absolute;
  left: -999rem;
  bottom: rem-calc(20);
  height: 1px;
  width: 1px;
  margin: auto auto auto -35%;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0;
  text-align: center;
  @include shadows(1);
  transition: opacity $base__transition-speed $base__transition-ease;
}

.small-list .product-list-product-img{
  position: relative;
  display: block;
  &:hover .product-list__product-quick-view__button{
    @include product-list__product-quick-view__button--show();
  }
}

.product-small-list-product-information{
  display: none;
  border-top: $app-product-list__small__product-list__product-information__border-top;
  margin-top: rem-calc(5);
  margin-bottom: rem-calc(5);
  padding-top: rem-calc(5);
  max-height: rem-calc(100);
  overflow: hidden;
}
.small-list .product-small-list-product-information{
  display: block;
}

.product-small-list-product-detail-link{
  display: inline-block;
  font-size: $app-product-list__small__product-list__product-detail-link__font-size;
  font-weight: $app-product-list__small__product-list__product-detail-link__font-weight;
  line-height: $app-product-list__small__product-list__product-detail-link__line-height;
  &:hover,&:focus{

  }
}
.product-small-list-product-id{
  display: inline-block;
  margin-right: rem-calc(3);
  font-weight: $app-product-list__small__product-list__product-detail-sku__font-weight;
  font-size: $app-product-list__small__product-list__product-detail-sku__font-size;
}

.product-list__product-more-information__list{
  list-style: none;
  display: table;
  width: 100%;
  margin: 0 0 rem-calc(8) 0;
  &-item,
  &-item__request-samples{
    display: table-cell;
    vertical-align: middle;
    text-align: left;
  }
  &-item__request-samples{
    text-align: right;
  }
}

@include mq(medium-up) {
  .product-list {
    &-item {
    }
    @at-root .small-list {
      & .product-list-item {
        @include grid-column($columns: 3, $float: false);
      }
    }
    @at-root .medium-list {
      & .product-list-item {
        @include grid-column($columns: 6, $float: false);
      }
    }
    @at-root .large-list {
      & .product-list-item {
      }
      & .product-list-product-display {
        @include grid-column($columns: 6);
      }
      & .product-list-product-information {
        @include grid-column($columns: 6);
      }
      & .product-list-product-order-information {
        @include grid-column($columns: 6, $last-column: true);
      }
    }
  }
}

@include mq(large-up){
  .product-list{
    &-item{
    }
    @at-root .small-list{
      & .product-list-item{
        @include grid-column($columns:2,$float:false);
      }
    }
    @at-root .medium-list{
      & .product-list-item{
        @include grid-column($columns:4,$float:false);
      }
    }
    @at-root .large-list{
      & .product-list-item{
      }
      & .product-list-product-display{
        @include grid-column($columns:3);
      }
      & .product-list-product-information{
        @include grid-column($columns:4);
      }
      & .product-list-product-order-information{
        @include grid-column($columns:5,$last-column:true);
      }
    }
  }
}