.product-detail__slides{
    &__component__container{
        margin-bottom: $app-grid__column-gutter;
    }
    &__container .swiper-button-next, &__container .swiper-button-prev{
        width: rem-calc(40);
        height: rem-calc(50);
        background-size: 70%;
        border-top: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        background-color: rgba(255, 255, 255, 0.55);
    }
    &__container .swiper-button-prev{
        left: 0;
        border-right: 1px solid #bbb;
    }
    &__container .swiper-button-next{
        right: 0;
        border-left: 1px solid #bbb;
    }
    &__thumbnail__container{
        //background: $base__secondary-color;
        //border: 1px solid $base__light-gray__border-color;
        border-top: 1px solid #bbbbbb; //$base__gray-color;
        border-bottom: 1px solid #aaaaaa; //$base__gray-color;
        padding: $app-grid__column-gutter / 3 $app-grid__column-gutter / 2;
    }
    &__thumbnail__container .swiper-wrapper {
        //padding: $app-grid__column-gutter / 3 $app-grid__column-gutter / 2;
    }
    &__thumbnail__container .swiper-slide{
        //border: 1px solid #ddd;
    }
    &__thumbnail__container .swiper-slide,
    &__thumbnail__container .swiper-slide > img{
        border-radius: 10px;
    }
    &__thumbnail__container  .swiper-slide.swiper-slide-thumb-active {
        border: 1px solid $base__gray-color; //#a7cae2
        box-shadow: 0 1.5px 4px rgba(0,0,0,.24), 0 1.5px 6px rgba(0,0,0,.12);
    }
    &__toolbar{
        &__container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $base__light-gray__border-color;
        }
        &__note, &__current-slide, &__action-link{
            margin-bottom: 0;
            color: $base__gray-color;
            font-size: nth($app-is-small-text__font-sizes, 1);
            line-height: nth($app-is-small-text__line-height, 1);
        }
        &__action-link__icon{
            fill: $base__gray-color;
        }
        &__action-link:hover, &__action-link:hover &__action-link__icon{
            color: $base__dark-gray-color;
            fill: $base__dark-gray-color;
        }
    }
}

@include mq(medium-up){
    .product-detail__slides{
        &__toolbar{
            &__container{
                border-bottom: none;
            }
            &__note, &__current-slide, &__action-link{
                font-size: nth($app-is-small-text__font-sizes, 2);
                line-height: nth($app-is-small-text__line-height, 2);
            }
        }
    }
}

@include mq(large-up){
    .product-detail__slides{
        &__toolbar{
            &__note, &__current-slide, &__action-link{
                font-size: nth($app-is-small-text__font-sizes, 3);
                line-height: nth($app-is-small-text__line-height, 3);
            }
        }
    }
}
