.sign-on{
  &__row{
    margin-bottom: $app-grid__column-gutter;
  }
  &__column{
    padding: $app-grid__column-gutter / 2;
    border-bottom: 1px solid #ccb59b;
    &:last-child{
      border-bottom: none;
    }
  }
}

@include mq(medium-up){
  .sign-on{
    &__column{
      flex: 1;
      padding: 0 $app-grid__column-gutter / 2;
      border-right: 1px solid #eee;
      border-bottom: none;
      &--max-width{
        max-width: $app-grid__column-gutter * 15;
      }
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
        border-right: none;
      }
    }
  }
}

@include mq(large-up){

}