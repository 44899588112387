@mixin dropdown(
  $background: $app-dropdown__background,
  $border: $app-dropdown__border,
  $border-radius: $base__border-radius,
  $margin: $app-dropdown__margin,
  $max-width: $app-dropdown__max-width,
  $card-depth: $app-dropdown__card-depth,
  $box-shadow: $app-dropdown__box-shadow--override,
  $width: 100%
){
    @if($card-depth != null){
        @include shadows($card-depth);
    }
    @else{
        box-shadow: $box-shadow;
    }
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    width: $width;
    max-height: none;
    height: auto;
    background: $background;
    border: $border;
    @if $border-radius > 0 {
      border-radius: $border-radius;
    }
    z-index: 89;
    @if($margin != null) {
      margin: $margin;
    }
    max-width: $max-width;
}
