.print-only { display: none !important; }
@media print {
  thead { display: table-header-group; /* h5bp.com/t */ }

  tr,
  img { page-break-inside: avoid; }

  @page { margin: 0.5cm; }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 { page-break-after: avoid; }

  .print-only { display: block !important; }
  .hide-for-print { display: none !important; }
  .show-for-print { display: inherit !important; }
}
