.magnify__images__container {
    & img{
        max-width: none;
        width: 100%;
    }
    &--flex-vertical,
    &--flex-vertical > div{
        height: 100%;
    }
    &--flex-vertical  > div > img{
        margin: auto;
        max-width: 100%;
        width:auto !important;
        max-height: 100%;
        height:auto;
    }
}
