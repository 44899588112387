$app-label__font-family: $base__font-family !default;
$app-label__font-size: rem-calc(11) !default;
$app-label__padding: rem-calc(4) rem-calc(8) rem-calc(4) !default;
$app-label__background-color: $base__primary-color !default;
$app-label__color: #ffffff !default;
$app-label__border-color: shade($base__primary-color, $base__color-shift) !default;
$app-label__border: 1px solid $app-label__border-color !default;
$app-label__border-radius: $base__border-radius !default;

$app-label__success__background-color: #8ca884 !default;
$app-label__success__color: #ffffff !default;
$app-label__success__border-color: shade($app-label__success__background-color, $base__color-shift) !default;
$app-label__success__border: 1px solid $app-label__success__border-color !default;

$app-label__alert__background-color: $base__alert-color !default;
$app-label__alert__color: #ffffff !default;
$app-label__alert__border-color: shade($app-label__alert__background-color, $base__color-shift) !default;
$app-label__alert__border: 1px solid $app-label__alert__border-color !default;

$app-label__light-gray__background-color: null !default;
$app-label__light-gray__color: #999999 !default;
$app-label__light-gray__border-color: #bbbbbb !default;
$app-label__light-gray__border: 1px solid $app-label__light-gray__border-color !default;

$app-label__info__background-color: null !default;
$app-label__info__color: $base__info-color !default;
$app-label__info__border-color: $base__info-color !default;
$app-label__info__border: 1px solid $app-label__info__border-color !default;

%extended_label{
  @include label();
}

.label--round{
  border-radius: $base__border-rounded;
}

.label{
  @extend %extended_label;
  background-color: $app-label__background-color;
  border: $app-label__border;
  color: $app-label__color;
  &--outline{
    background-color: transparent;
    color: $app-label__background-color;
  }
}
.label__success{
  @extend %extended_label;
  background-color: $app-label__success__background-color;
  border: $app-label__success__border;
  color: $app-label__success__color;
  &--outline{
    background-color: transparent;
    color: $app-label__success__background-color;
  }
}
.label__light-gray{
  @extend %extended_label;
  background-color: $app-label__light-gray__background-color;
  border: $app-label__light-gray__border;
  color: $app-label__light-gray__color;
}
.label__info{
    @extend %extended_label;
    background-color: $app-label__info__background-color;
    border: $app-label__info__border;
    color: $app-label__info__color;
}
.label__alert{
  @extend %extended_label;
  background-color: $app-label__alert__background-color;
  border: $app-label__alert__border;
  color: $app-label__alert__color;
  &--outline{
    background-color: transparent;
    color: $app-label__light-gray__background-color;
  }
}
.label--collapse{
  margin-bottom: 0;
}
