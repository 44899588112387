$app-inline-list__margin: 0 auto rem-calc(17) auto !default;
$app-inline-list__center__list-item-side__padding: rem-calc(10) !default;
$app-inline-nav-list__margin: 0 auto rem-calc(10) auto !default;
$app-inline-nav-list-item__border-color: #ebd4ba !default;
$app-inline-nav-list-item__border-left: 1px solid $app-inline-nav-list-item__border-color !default;
$app-inline-nav-list-link__padding: rem-calc(5) rem-calc(20) !default;
$app-nav-list-item__border-color: #aaaaaa !default;
$app-nav-list-item__border-bottom: 1px solid $app-nav-list-item__border-color !default;
$app-nav-list-link__padding: rem-calc(5) !default;
$app-list__list-style: none !default;
$app-list-item__border-bottom: 1px solid #aaaaaa !default;
$app-list-item__vertical__padding: rem-calc(5) !default;

$app-description-list__term__font-weight: $base__font-weight--bold !default;
$app-description-list__term__text-decoration: null !default;
$app-description-list__description__color: null !default;

.list{
  list-style: $app-list__list-style;
  &--collapse{
    margin: 0;
  }
  &--is-centered{
    display: table;
    margin: auto;
  }
  &--has-bottom-margin{
    margin-bottom: $app-grid__column-gutter / 2;
  }
}
.list-item{
  padding-top: $app-list-item__vertical__padding;
  padding-bottom: $app-list-item__vertical__padding;
  border-bottom: $app-list-item__border-bottom;
  &:last-child{
    border-bottom: none;
  }
}

.inline-list{
  margin: $app-inline-list__margin;
  padding: 0;
  list-style: none;
  overflow: hidden;
  &-item{
    float: left;
    display: block;
    margin-left: $app-grid__column-gutter / 2;
    &:first-child{
      margin-left: 0;
    }
  }
}
.inline-list__center__list{
  @extend .inline-list;
  display: table;
  &-item{
    display: table-cell;
    padding-left: $app-inline-list__center__list-item-side__padding;
    padding-right: $app-inline-list__center__list-item-side__padding;
  }
}

.inline-nav-list{
  margin: $app-inline-nav-list__margin;
  padding: 0;
  list-style: none;
  overflow: hidden;
  &-item{
    border-bottom: $app-inline-nav-list-item__border-left;
    &:last-child,
    &--no-border{
      border-bottom: none;
    }
  }
  &-link{
    display: block;
    padding: $app-inline-nav-list-link__padding;
    padding-left: 0;
  }
}

.description-list{
  &__term{
    font-weight: $app-description-list__term__font-weight;
    text-decoration: $app-description-list__term__text-decoration;
  }
  &__description{
    margin-bottom: $app-grid__column-gutter / 2;
    color: $app-description-list__description__color;
  }
}

.nav-list{
  @extend .inline-nav-list;
  &-item{
    border-bottom: $app-nav-list-item__border-bottom;
    &:last-child{
      border-bottom: none;
    }
  }
  &-link{
    display: block;
    padding: $app-nav-list-link__padding;
  }
}

@include mq(medium-up){
  .inline-nav-list{
    &-item{
      float: left;
      border-bottom: none;
      border-left: $app-inline-nav-list-item__border-left;
      &:first-child,
      &--no-border{
        border-left: none;
      }
      &:first-child .inline-nav-list-link{
        padding-left: 0;
      }
    }
    &-link{
      padding: $app-inline-nav-list-link__padding;
    }
  }
}