$base__heading__font-family: $base__font-family !default;
$base__heading__font-weight: $base__font-weight--medium !default;
$base__heading__font-style: normal !default;
$base__heading__line-height: 1.3 !default;
$base__heading__color: $base__primary-color !default;
$base__heading__margin-top: .2rem !default;
$base__heading__margin-bottom: .5rem !default;
$base__heading__text-rendering: optimizeLegibility !default;
$base__small__font-size: 70% !default;
$base__small__font-color: scale-color($base__heading__color, $lightness: 35%) !default;
$base__paragraph__font-family: inherit !default;
$base__paragraph__font-weight: $font-weight-normal !default;
$base__paragraph__font-size: 1rem !default;
$base__paragraph__line-height: 1.6 !default;
$base__paragraph__margin-bottom: rem-calc(20) !default;
$base__paragraph__aside-font-size: rem-calc(14) !default;
$base__paragraph__aside-line-height: 1.35 !default;
$base__paragraph__aside-font-style: italic !default;
$base__paragraph__text-rendering: optimizeLegibility !default;

$app-heading__h1__font-sizes: rem-calc(44), rem-calc(44), rem-calc(44) !default;
$app-heading__h1__line-height: 1.25, 1.25, 1.25 !default;
$app-heading__h1__font-family: null !default;
$app-heading__h1__font-style: null !default;
$app-heading__h1__font-weight: $base__font-weight--medium !default;
$app-heading__h1__border-color: #ccb59b !default;
$app-heading__h1__border: null !default;
$app-heading__h1__text-shadow: null !default;

$app-heading__h2__font-sizes: rem-calc(37), rem-calc(37), rem-calc(37) !default;
$app-heading__h2__line-height: $base__heading__line-height, $base__heading__line-height, $base__heading__line-height !default;
$app-heading__h2__font-style: null !default;
$app-heading__h2__font-weight: null !default;

$app-heading__h3__font-sizes: rem-calc(27), rem-calc(27), rem-calc(27) !default;
$app-heading__h3__line-height: null, $base__heading__line-height, $base__heading__line-height !default;
$app-heading__h3__font-style: null !default;
$app-heading__h3__font-weight: null !default;

$app-heading__h4__font-sizes: rem-calc(23), rem-calc(23), rem-calc(23) !default;
$app-heading__h4__line-height: 1.35, 1.35, 1.35 !default;
$app-heading__h4__font-style: null !default;
$app-heading__h4__font-weight: null !default;

$app-heading__h5__font-sizes: rem-calc(18), rem-calc(18), rem-calc(18) !default;
$app-heading__h5__line-height: 1.4, 1.4, 1.4 !default;
$app-heading__h5__font-style: null !default;
$app-heading__h5__font-weight: null !default;

$app-heading__h6__font-sizes: 1rem, 1rem, 1rem !default;
$app-heading__h6__line-height: 1.45, 1.45, 1.45 !default;
$app-heading__h6__font-style: null !default;
$app-heading__h6__font-weight: null !default;

// A general <small> styling

h1, h2, h3, h4, h5, h6 {
  font-family: $base__heading__font-family;
  font-weight: $base__heading__font-weight;
  font-style: $base__heading__font-style;
  color: $base__heading__color;
  text-rendering: $base__heading__text-rendering;
  margin-top: $base__heading__margin-top;
  margin-bottom: $base__heading__margin-bottom;
  small {
    font-size: $base__small__font-size;
    line-height: 0;
  }
}

h1 {
  font-size: nth($app-heading__h1__font-sizes, 1);
  font-family: $app-heading__h1__font-family;
  font-style: $app-heading__h1__font-style;
  font-weight: $app-heading__h1__font-weight;
  line-height: nth($app-heading__h1__line-height, 1);
  border-bottom: $app-heading__h1__border;
  text-shadow: $app-heading__h1__text-shadow;
}
h2 {
  font-size: nth($app-heading__h2__font-sizes, 1);
  line-height: nth($app-heading__h2__line-height, 1);
  font-style: $app-heading__h2__font-style;
  font-weight: $app-heading__h2__font-weight;
}
h3 {
  font-size: nth($app-heading__h3__font-sizes, 1);
  line-height: nth($app-heading__h3__line-height, 1);
  font-style: $app-heading__h3__font-style;
  font-weight: $app-heading__h3__font-weight;
}
h4 {
  font-size: nth($app-heading__h4__font-sizes, 1);
  line-height: nth($app-heading__h4__line-height, 1);
  font-style: $app-heading__h4__font-style;
  font-weight: $app-heading__h4__font-weight;
}
h5 {
  font-size: nth($app-heading__h5__font-sizes, 1);
  line-height: nth($app-heading__h5__line-height, 1);
  font-style: $app-heading__h5__font-style;
  font-weight: $app-heading__h5__font-weight;
}
h6 {
  font-size: nth($app-heading__h6__font-sizes, 1);
  line-height: nth($app-heading__h6__line-height, 1);
  font-style: $app-heading__h6__font-style;
  font-weight: $app-heading__h6__font-weight;
}

p {
  font-family: $base__paragraph__font-family;
  font-weight: $base__paragraph__font-weight;
  font-size: $base__paragraph__font-size;
  line-height: $base__paragraph__line-height;
  margin-bottom: $base__paragraph__margin-bottom;
  text-rendering: $base__paragraph__text-rendering;
}

em{
  font-style: italic;
  line-height: inherit;
}

strong{
  font-weight: $base__font-weight--bold;
  line-height: inherit;
}

small {
  font-size: $base__small__font-size;
  line-height: inherit;
}

@include mq(medium-up){
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }
  h1 {
    font-size: nth($app-heading__h1__font-sizes, 2);
    line-height: nth($app-heading__h1__line-height, 2);
  }
  h2 {
    font-size: nth($app-heading__h2__font-sizes, 2);
    line-height: nth($app-heading__h2__line-height, 2);
  }
  h3 {
    font-size: nth($app-heading__h3__font-sizes, 2);
    line-height: nth($app-heading__h3__line-height, 2);
  }
  h4 {
    font-size: nth($app-heading__h4__font-sizes, 2);
    line-height: nth($app-heading__h4__line-height, 2);
  }
  h5 {
    font-size: nth($app-heading__h5__font-sizes, 2);
    line-height: nth($app-heading__h5__line-height, 2);
  }
  h6 {
    font-size: nth($app-heading__h6__font-sizes, 2);
    line-height: nth($app-heading__h6__line-height, 2);
  }
}

@include mq(large-up){
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }
  h1 {
    font-size: nth($app-heading__h1__font-sizes, 3);
    line-height: nth($app-heading__h1__line-height, 3);
  }
  h2 {
    font-size: nth($app-heading__h2__font-sizes, 3);
    line-height: nth($app-heading__h2__line-height, 3);
  }
  h3 {
    font-size: nth($app-heading__h3__font-sizes, 3);
    line-height: nth($app-heading__h3__line-height, 3);
  }
  h4 {
    font-size: nth($app-heading__h4__font-sizes, 3);
    line-height: nth($app-heading__h4__line-height, 3);
  }
  h5 {
    font-size: nth($app-heading__h5__font-sizes, 3);
    line-height: nth($app-heading__h5__line-height, 3);
  }
  h6 {
    font-size: nth($app-heading__h6__font-sizes, 3);
    line-height: nth($app-heading__h6__line-height, 3);
  }
}
