.steps {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    min-height: 2rem; }
.steps:not(:last-child) {
    margin-bottom: 1.5rem; }
.steps .step-item {
    margin-top: 0;
    position: relative;
    flex-grow: 1;
    flex-basis: 0; }
.steps .step-item:not(:first-child) {
    flex-basis: 1em;
    flex-grow: 1;
    flex-shrink: 1; }
.steps .step-item:not(:first-child)::before {
    content: " ";
    display: block;
    position: absolute; }
.steps .step-item::before {
    background: linear-gradient(to left, #dbdbdb 50%, #89b87c 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item::before .step-marker {
    color: white; }
.steps .step-item.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-active .step-marker {
    background-color: white;
    border-color: #89b87c;
    color: #89b87c; }
.steps .step-item.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-completed .step-marker {
    color: white;
    background-color: #89b87c; }
.steps .step-item .step-marker {
    align-items: center;
    display: flex;
    border-radius: 50%;
    font-weight: 700;
    justify-content: center;
    background: #b5b5b5;
    color: white;
    border: 0.2em solid #fff;
    z-index: 1; }
.steps .step-item .step-details {
    text-align: center; }
.steps .step-item.is-white::before {
    background: linear-gradient(to left, #dbdbdb 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-white.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-white.is-active .step-marker {
    background-color: white;
    border-color: white;
    color: white; }
.steps .step-item.is-white.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-white.is-completed .step-marker {
    color: #0a0a0a;
    background-color: white; }
.steps .step-item.is-black::before {
    background: linear-gradient(to left, #dbdbdb 50%, #0a0a0a 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-black.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-black.is-active .step-marker {
    background-color: white;
    border-color: #0a0a0a;
    color: #0a0a0a; }
.steps .step-item.is-black.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-black.is-completed .step-marker {
    color: white;
    background-color: #0a0a0a; }
.steps .step-item.is-light::before {
    background: linear-gradient(to left, #dbdbdb 50%, whitesmoke 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-light.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-light.is-active .step-marker {
    background-color: white;
    border-color: whitesmoke;
    color: whitesmoke; }
.steps .step-item.is-light.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-light.is-completed .step-marker {
    color: rgba(0, 0, 0, 0.7);
    background-color: whitesmoke; }
.steps .step-item.is-dark::before {
    background: linear-gradient(to left, #dbdbdb 50%, #363636 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-dark.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-dark.is-active .step-marker {
    background-color: white;
    border-color: #363636;
    color: #363636; }
.steps .step-item.is-dark.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-dark.is-completed .step-marker {
    color: #fff;
    background-color: #363636; }
.steps .step-item.is-primary::before {
    background: linear-gradient(to left, #dbdbdb 50%, #00d1b2 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-primary.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-primary.is-active .step-marker {
    background-color: white;
    border-color: #00d1b2;
    color: #00d1b2; }
.steps .step-item.is-primary.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-primary.is-completed .step-marker {
    color: #fff;
    background-color: #00d1b2; }
.steps .step-item.is-link::before {
    background: linear-gradient(to left, #dbdbdb 50%, #3273dc 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-link.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-link.is-active .step-marker {
    background-color: white;
    border-color: #3273dc;
    color: #3273dc; }
.steps .step-item.is-link.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-link.is-completed .step-marker {
    color: #fff;
    background-color: #3273dc; }
.steps .step-item.is-info::before {
    background: linear-gradient(to left, #dbdbdb 50%, #3298dc 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-info.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-info.is-active .step-marker {
    background-color: white;
    border-color: #3298dc;
    color: #3298dc; }
.steps .step-item.is-info.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-info.is-completed .step-marker {
    color: #fff;
    background-color: #3298dc; }
.steps .step-item.is-success::before {
    background: linear-gradient(to left, #dbdbdb 50%, #48c774 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-success.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-success.is-active .step-marker {
    background-color: white;
    border-color: #48c774;
    color: #48c774; }
.steps .step-item.is-success.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-success.is-completed .step-marker {
    color: #fff;
    background-color: #48c774; }
.steps .step-item.is-warning::before {
    background: linear-gradient(to left, #dbdbdb 50%, #ffdd57 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-warning.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-warning.is-active .step-marker {
    background-color: white;
    border-color: #ffdd57;
    color: #ffdd57; }
.steps .step-item.is-warning.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-warning.is-completed .step-marker {
    color: rgba(0, 0, 0, 0.7);
    background-color: #ffdd57; }
.steps .step-item.is-danger::before {
    background: linear-gradient(to left, #dbdbdb 50%, #f14668 50%);
    background-size: 200% 100%;
    background-position: right bottom; }
.steps .step-item.is-danger.is-active::before {
    background-position: left bottom; }
.steps .step-item.is-danger.is-active .step-marker {
    background-color: white;
    border-color: #f14668;
    color: #f14668; }
.steps .step-item.is-danger.is-completed::before {
    background-position: left bottom; }
.steps .step-item.is-danger.is-completed .step-marker {
    color: #fff;
    background-color: #f14668; }
.steps .steps-content {
    align-items: stretch;
    flex-basis: 100%;
    margin: 2rem 0; }
.steps .steps-content .step-content {
    display: none; }
.steps .steps-content .step-content.is-active {
    display: block; }
.steps .steps-actions {
    display: flex;
    align-items: stretch;
    flex-basis: 100%; }
.steps .steps-actions .steps-action {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    margin: .5rem;
    justify-content: center;
    align-items: center; }
.steps.is-animated .step-item::before {
    transition: all 2s ease; }
.steps.is-animated .step-item .step-marker {
    transition: all 0s ease;
    transition-delay: 1.5s; }
.steps .step-item:not(:first-child)::before {
    height: 0.2em;
    width: 100%;
    bottom: 0;
    left: -50%;
    top: 1rem; }
.steps .step-item .step-marker {
    height: 2rem;
    width: 2rem;
    position: absolute;
    left: calc(50% - 1rem); }
.steps .step-item .step-marker .icon * {
    font-size: 1rem; }
.steps .step-item .step-details {
    margin-top: 2rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em; }
.steps .step-item .step-details .step-title {
    font-size: 1.2rem;
    font-weight: 600; }
.steps.is-small {
    font-size: 0.75rem;
    min-height: 1.5rem; }
.steps.is-small .step-item:not(:first-child)::before {
    height: 0.2em;
    width: 100%;
    bottom: 0;
    left: -50%;
    top: 0.75rem; }
.steps.is-small .step-item .step-marker {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    left: calc(50% - 0.75rem); }
.steps.is-small .step-item .step-marker .icon * {
    font-size: 0.75rem; }
.steps.is-small .step-item .step-details {
    margin-top: 1.5rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em; }
.steps.is-small .step-item .step-details .step-title {
    font-size: 0.9rem;
    font-weight: 600; }
.steps.is-medium {
    font-size: 1.25rem;
    min-height: 2.5rem; }
.steps.is-medium .step-item:not(:first-child)::before {
    height: 0.2em;
    width: 100%;
    bottom: 0;
    left: -50%;
    top: 1.25rem; }
.steps.is-medium .step-item .step-marker {
    height: 2.5rem;
    width: 2.5rem;
    position: absolute;
    left: calc(50% - 1.25rem); }
.steps.is-medium .step-item .step-marker .icon * {
    font-size: 1.25rem; }
.steps.is-medium .step-item .step-details {
    margin-top: 2.5rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em; }
.steps.is-medium .step-item .step-details .step-title {
    font-size: 1.5rem;
    font-weight: 600; }
.steps.is-large {
    font-size: 1.5rem;
    min-height: 3rem; }
.steps.is-large .step-item:not(:first-child)::before {
    height: 0.2em;
    width: 100%;
    bottom: 0;
    left: -50%;
    top: 1.5rem; }
.steps.is-large .step-item .step-marker {
    height: 3rem;
    width: 3rem;
    position: absolute;
    left: calc(50% - 1.5rem); }
.steps.is-large .step-item .step-marker .icon * {
    font-size: 1.5rem; }
.steps.is-large .step-item .step-details {
    margin-top: 3rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em; }
.steps.is-large .step-item .step-details .step-title {
    font-size: 1.8rem;
    font-weight: 600; }

.steps.is-bordered {
    padding: 0.54688rem;
    border: 1px solid #d8d8d8;
    border-radius: 3px; }

.steps .steps-content {
    margin: 0; }
.steps.is-bordered .steps-content {
    border-top: 1px solid #d8d8d8;
    padding: 1.09375rem 0.54688rem 0.54688rem 0.54688rem; }

.steps .step-item.is-active .step-marker {
    border-color: #89b87c;
    color: #89b87c; }

.steps .step-item.is-completed .step-marker, .steps .step-item.is-success.is-completed .step-marker {
    background-color: #89b87c; }
