$app-modal__body__border: 1px solid #999999 !default;
$app-modal__body__border-radius: $base__border-radius !default;
$app-modal__body__background: #ffffff !default;
$app-modal__body__min-width: 40% !default;
$app-modal__body__max-width: 75% !default;
$app-modal__body__min-height: null !default;
$app-modal__body__max-height: 70% !default;
$app-modal__overlay__background-color: #462009 !default;
$app-modal__overlay__opacity: .5 !default;
$app-modal__header__background: null;
$app-modal__header__border-color: #999999;
$app-modal__header__border: 1px solid $app-modal__header__border-color;
$app-modal__header__close__column__border-color: #999999 !default;
$app-modal__header__close__column__border: 1px solid $app-modal__header__close__column__border-color !default;
$app-modal__footer__background: null;
$app-modal__footer__border-color: $app-modal__header__border-color;
$app-modal__footer__border: 1px solid $app-modal__footer__border-color;
$app-modal__btn__accept__background-color: $app-btn__primary__background-color !default;
$app-modal__btn__accept__background: $app-modal__btn__accept__background-color !default;
$app-modal__btn__accept__background-hover: $app-btn__primary__background--hover !default;
$app-modal__btn__accept__border-color: $app-btn__primary__border-color !default;
$app-modal__btn__accept__border: 1px solid $app-modal__btn__accept__border-color !default;
$app-modal__btn__accept__border-color--hover: null !default;
$app-modal__btn__accept__color: $app-btn__primary__font-color !default;
$app-modal__btn__accept__color--hover: $app-btn__primary__font-color !default;
$app-modal__btn__cancel__background-color: null !default;
$app-modal__btn__cancel__background: $app-modal__btn__cancel__background-color !default;
$app-modal__btn__cancel__background-hover: null !default;
$app-modal__btn__cancel__border-color: #cccccc !default;
$app-modal__btn__cancel__border: 1px solid $app-modal__btn__cancel__border-color !default;
$app-modal__btn__cancel__border-color--hover: #999999 !default;
$app-modal__btn__cancel__color: #999999 !default;
$app-modal__btn__cancel__color--hover: #555555 !default;
$app-modal__btn__large__padding: rem-calc(13) rem-calc(21) !default;
$app-modal__btn__large__font-size: $app-btn__large__font-size !default;

.modal__content__storage-container{
  display: none;
  &--show{
    display: block;
  }
  @at-root .no-js .modal__content__storage-container{
    display: block;
  }
}

.body__modal--open{
  overflow: hidden;
}

.modal{
  position: absolute;
  left: -999em;
  z-index: 9999;
  text-align: center;
  background: rgba($app-modal__overlay__background-color, $app-modal__overlay__opacity);
  opacity: 0;
  //will-change: opacity;
  @include single-transition(opacity, $speed: 200ms, $ease: ease-in-out);
  &--open{
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($app-modal__overlay__background-color, $app-modal__overlay__opacity);
    opacity: 1;
    //vertical center hack - ghost pseudo element to force inline vertical middle of containing element
    &:before{
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      font-size: 0;
    }
  }
}

.modal__body{
  @include shadows(2, null);
  display: inline-block;
  position: relative;
  border-radius: $app-modal__body__border-radius;
  background: $app-modal__body__background;
  vertical-align: middle;
  min-width: $app-modal__body__min-width;
  max-width: $app-modal__body__max-width;
  min-height: $app-modal__body__min-height;
  max-height: $app-modal__body__max-height;
  overflow-y: auto;
  transform: scale(1.25);
  //will-change: transform;
  transition: transform 100ms ease-in-out;
  @at-root .modal--open .modal__body {
    transition: transform 300ms ease-in-out;
    transform: scale(1);
  }
}

.modal__content{
  padding: 0 $app-grid__column-gutter / 2;
}

.modal__btn{
  @include button-base($padding: rem-calc(11) rem-calc(24));
  &--large{
    padding: $app-modal__btn__large__padding;
    font-size: $app-modal__btn__large__font-size;
  }
  &--accept{
    background: $app-modal__btn__accept__background;
    border: $app-modal__btn__accept__border;
    color: $app-modal__btn__accept__color;
    &:hover,
    &:focus{
      background: $app-modal__btn__accept__background-hover;
      border-color: $app-modal__btn__accept__border-color--hover;
      color: $app-modal__btn__accept__color--hover;
    }
  }
  &--close,
  &--cancel{
    background: $app-modal__btn__cancel__background;
    color: $app-modal__btn__cancel__color;
    &:hover,
    &:focus{
      background: $app-modal__btn__cancel__background-hover;
      color: $app-modal__btn__cancel__color--hover;
      border-color: $app-modal__btn__cancel__border-color--hover;
    }
  }
  &--cancel{
    border: $app-modal__btn__cancel__border;
  }
  &--close{

  }
}

.modal__header{
  display: table;
  width: 100%;
  @if($app-modal__header__border-color) {
    border-bottom: $app-modal__header__border;
  }
  &--fixed {
  }
  &__row{
    display: table-row;
  }
  &__title{
    font-size: rem-calc(16);
    line-height: 1.2;
  }
  &__title__column,
  &__close__column{
    display: table-cell;
    vertical-align: middle;
  }
  &__title__column{
    padding: $app-grid__column-gutter / 3;
  }
  &__close__column{
    border-left: $app-modal__header__close__column__border;
    width: rem-calc(50);
  }
  &__btn--close{
    padding: rem-calc(16);
  }
}

.modal__footer{
  padding: $app-grid__column-gutter / 4;
  //text-align: right;
  @if($app-modal__footer__border-color) {
    border-top: $app-modal__footer__border;
  }
  &--fixed{

  }
  &__btn__conjunction{
    display: block;
    margin: rem-calc(8) auto;
  }
}

@include mq(medium-up){
  .modal__footer{
    &__btn__conjunction{
      display: inline-block;
      vertical-align: middle;
      margin: auto;
    }
  }
}