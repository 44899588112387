.page-hero{
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin: 0 -50vw $app-grid__column-gutter -50vw;
    background-color: #ffffff;
    &--primary{
        background-image: linear-gradient(315deg, #ffffff 0%, #c2a369 74%);
    }
    &--secondary{
        background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
    }
    &__row{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right center;
    }
    &__columns{
        max-width: $app-grid__row-width;
        margin-left: auto;
        margin-right: auto;
    }
    &__column{
        padding: 4rem 1.09375rem;
        &__callout{
            padding: $app-grid__column-gutter;
            background: rgba(255,255,255, 0.70);
            border: 1px solid #cccccc;
            border-radius: $base__border-radius;
        }
    }
}
@include mq(medium-up) {
    .page-hero{
        &__columns{
            display: flex;
        }
        &__column{
            &:only-child{
                max-width: 50%;
            }
            &__callout{
                background: transparent;
                border: none;
                border-radius: $base__border-radius;
                &:first-child{
                    padding-left: 0;
                }
            }
        }
    }
}
