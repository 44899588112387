$app-product-category__category__tabs__inline-list__box-shadow: 0 4px 5px -3px rgba(0, 0, 0, .4) !default;
$app-product-category__category__tabs__inline-list__border-color: $base__primary-color !default;
$app-product-category__category__tabs__inline-list__border: 1px solid $app-product-category__category__tabs__inline-list__border-color !default;
$app-product-category__category__tabs__inline-list-item__border-color: $base__primary-color !default;
$app-product-category__category__tabs__inline-list-item__border: 1px solid $app-product-category__category__tabs__inline-list-item__border-color !default;
$app-product-category__category__tabs__inline-list-item__small-only__border-bottom-color: #dddddd !default;
$app-product-category__category__tabs__inline-list-item__small-only__border-bottom: 1px solid $app-product-category__category__tabs__inline-list-item__small-only__border-bottom-color !default;
$app-product-category__category__tabs__inline-list-item__link__border-color: null !default;
$app-product-category__category__tabs__inline-list-item__link__border: $app-product-category__category__tabs__inline-list-item__link__border-color !default;
$app-product-category__category__tabs__inline-list-item__link__border-radius: $base__border-radius !default;
$app-product-category__category__tabs__inline-list-item__link__background-color: null !default;
$app-product-category__category__tabs__inline-list-item__link__background: $app-product-category__category__tabs__inline-list-item__link__background-color !default;
$app-product-category__category__tabs__inline-list-item__link__background-color--hover: null !default;
$app-product-category__category__tabs__inline-list-item__link__background--hover: $app-product-category__category__tabs__inline-list-item__link__background-color--hover !default;
$app-product-category__category__tabs__inline-list-item__link__font-color: null !default;
$app-product-category__category__tabs__inline-list-item__link__font-color--hover:  shade($base__primary-color, $base__color-shift) !default;
$app-product-category__category__tabs__inline-list-item__link-selected__background-color: $base__primary-color !default;
$app-product-category__category__tabs__inline-list-item__link-selected__background: $app-product-category__category__tabs__inline-list-item__link-selected__background-color !default;
$app-product-category__category__tabs__inline-list-item__link-selected__background-color--hover: shade($base__primary-color, $base__color-shift) !default;
$app-product-category__category__tabs__inline-list-item__link-selected__background--hover: $app-product-category__category__tabs__inline-list-item__link-selected__background-color--hover !default;
$app-product-category__category__tabs__inline-list-item__link-selected__font-color: #ffffff !default;
$app-product-category__category__tabs__inline-list-item__link-selected__font-color--hover: #ffffff !default;
$app-product-category__category__sub-category__tabs__list__background-color: $base__secondary-color !default;
$app-product-category__category__sub-category__tabs__list__background: $app-product-category__category__sub-category__tabs__list__background-color !default;
$app-product-category__category__sub-category__tabs__list-item__border-color: #dddddd !default;
$app-product-category__category__sub-category__tabs__list-item__border-bottom: 1px solid $app-product-category__category__sub-category__tabs__list-item__border-color !default;
$app-product-category__category__sub-category__tabs__list-item__border-right: 1px solid shade($app-product-category__category__sub-category__tabs__list-item__border-color, $base__color-shift) !default;
$app-product-category__category__sub-category__tabs__list-item-selected__background-color: #9eb0bd !default;
$app-product-category__category__sub-category__tabs__list-item-selected__background: $app-product-category__category__sub-category__tabs__list-item-selected__background-color !default;
$app-product-category__category__sub-category__tabs__list-item-selected__medium-up__background-color: #ffffff !default;
$app-product-category__category__sub-category__tabs__list-item-selected__medium-up__background: $app-product-category__category__sub-category__tabs__list-item-selected__medium-up__background-color !default;
$app-product-category__category__sub-category__tabs__list-item__link__padding: ($app-grid__column-gutter / 6) ($app-grid__column-gutter) !default;
$app-product-category__category__sub-category__tabs__list-item__link__medium-up__padding: ($app-grid__column-gutter / 3) ($app-grid__column-gutter) !default;
$app-product-category__category__sub-category__tabs__list-item__link__small__font-size: rem-calc(14) !default;
$app-product-category__category__sub-category__tabs__list-item__link__medium-up__font-size: rem-calc(16) !default;
$app-product-category__category__sub-category__tabs__list-item__link__font-color: #444444 !default;
$app-product-category__category__sub-category__tabs__list-item-selected__link__font-color: #000000 !default;
$app-product-category__category__sub-category__tabs__list-item-selected__link__medium-up__font-color: $base__primary-color !default;
$app-product-category__category__sub-category__tabs__list-item__link__background-color--hover: #dfeaef !default;
$app-product-category__category__sub-category__tabs__list-item__link__background--hover: $app-product-category__category__sub-category__tabs__list-item__link__background-color--hover !default;

$app-product-category__vertical-theme__sub-categories__list-item__border-color: #dddddd !default;
$app-product-category__vertical-theme__sub-categories__list-item__border: 1px solid $app-product-category__vertical-theme__sub-categories__list-item__border-color !default;

$app-product-category__slipsheet-theme__blue-gradient__background-color: #617c89 !default;
$app-product-category__slipsheet-theme__blue-gradient__background: linear-gradient(to bottom, $app-product-category__slipsheet-theme__blue-gradient__background-color 0%,#ffffff 80%) !default;
$app-product-category__slipsheet-theme__brown-gradient__background-color: #896f61 !default;
$app-product-category__slipsheet-theme__brown-gradient__background: linear-gradient(to bottom, $app-product-category__slipsheet-theme__brown-gradient__background-color 0%,#ffffff 80%) !default;
$app-product-category__slipsheet-theme__green-gradient__background-color: #67846d !default;
$app-product-category__slipsheet-theme__green-gradient__background: linear-gradient(to bottom, $app-product-category__slipsheet-theme__green-gradient__background-color 0%,#ffffff 80%) !default;
$app-product-category__slipsheet-theme__gray-gradient__background-color: #8a8a8a !default;
$app-product-category__slipsheet-theme__gray-gradient__background: linear-gradient(to bottom, $app-product-category__slipsheet-theme__gray-gradient__background-color 0%,#ffffff 80%) !default;

$app-product-category__slipsheet-theme__product-category__header__text-container__text-align: center !default;
$app-product-category__slipsheet-theme__product-category__header__headline__font-color: null !default;
$app-product-category__slipsheet-theme__product-category__header__headline__large-up__font-color: #ffffff !default;
$app-product-category__slipsheet-theme__product-category__header__headline__text-align: null !default;
$app-product-category__slipsheet-theme__product-category__header__headline__text-shadow: null !default;
$app-product-category__slipsheet-theme__product-category__header__headline__large-up__text-shadow: 1px 1px 2px #444 !default;
$app-product-category__slipsheet-theme__product-category__header__headline__border-bottom: none !default;
$app-product-category__slipsheet-theme__product-category__header__subhead__font-size: rem-calc(29) !default;

$app-product-category__sub-category-rows__list-item__child-category__subhead__font-size: rem-calc(16) !default;
$app-product-category__sub-category-rows__list-item__child-category__subhead__font-weight: $base__font-weight--light !default;
$app-product-category__sub-category-rows__list-item__child-category__subhead__font-color: $base__font-color !default;
$app-product-category__sub-category-rows__list-item__child-category__link__background: $base__secondary-color !default;
$app-product-category__sub-category-rows__list-item__child-category__link__border-color: #d8d8d8 !default;
$app-product-category__sub-category-rows__list-item__child-category__link__border: 1px solid $app-product-category__sub-category-rows__list-item__child-category__link__border-color !default;
$app-product-category__sub-category-rows__list-item__child-category__link__border-radius: $base__border-radius !default;
$app-product-category__sub-category-rows__list-item__child-category__link__padding: $app-grid__column-gutter / 4 !default;
$app-product-category__sub-category-rows__list-item__child-category__link__font-sizes: rem-calc(20), rem-calc(21), rem-calc(22) !default;
$app-product-category__sub-category-rows__list-item__child-category__link__line-height: 1.15, 1.2, 1.25 !default;
$app-product-category__sub-category-rows__list-item__child-category__link__font-style: null !default;
$app-product-category__sub-category-rows__list-item__child-category__link__font-weight: $base__font-weight--medium !default;

$app-product-category__sub-categories__list-item__container__border-color: #D8D8D8 !default;
$app-product-category__sub-categories__list-item__container__border-color--hover: darken($app-product-category__sub-categories__list-item__container__border-color, 13%) !default;
$app-product-category__sub-categories__list-item__container__border: 1px solid $app-product-category__sub-categories__list-item__container__border-color !default;
$app-product-category__sub-categories__list-item__container__border-radius: 5px !default;

.product-category{
  padding-top: $app-grid__column-gutter / 2;
  margin-bottom: $app-grid__column-gutter / 2;
}

.product-category__category-display__column,
.product-category__category-information__column,
.product-category__vertical-theme__category-display__column,
.product-category__vertical-theme__category-list__column,
.product-category__new-products__sub-category-information__column,
.product-category__new-products__sub-category-display__column
{
  @include grid-column-padding();
}
.product-category__category-display__column{
  text-align: center;
}
.product-category__category__sub-category__tabs__column {
  margin-top: rem-calc(16);
}
.product-category__category__sub-category__product-list__column{
  @include grid-column-padding($right: false);
}
.product-category__category__subcategory__product-lists__container{
  margin-top: $app-grid__column-gutter / 2;
  & .small-list .product-list-item{}
  & .large-list .product-list-item{}
}

.product-category__vertical-theme__category-display__column{
  position: relative;
}
.product-category__header__text-container{
  //text-align: center;
}
.product-category__new-products__sub-category-display__column{
  text-align: center;
}

.product-category__new-products__sub-category__row{
  @include clearfix();
  margin-bottom: rem-calc(16);
  padding-bottom: rem-calc(16);
  border-bottom: 1px solid #eeeeee;
}

.product-category__category__tabs__inline-list,
.product-category__category__sub-category__tabs__list,
.product-category__vertical-theme__sub-categories__list,
.product-category__sub-category-rows__list{
  list-style: none;
  margin: 0;
}
.product-category__category__tabs__inline-list{
  overflow: hidden;
  border-bottom: $app-product-category__category__tabs__inline-list__border;
  box-shadow: $app-product-category__category__tabs__inline-list__box-shadow;
  position: relative;
  &-item{
    //float: left;
    //border-left: $app-product-category__category__tabs__inline-list-item__border;
    border-bottom: $app-product-category__category__tabs__inline-list-item__small-only__border-bottom;
    &:last-child{
      border-bottom: none;
    }
    &:first-child,
    &.selected,
    &.selected + .product-category__category__tabs__inline-list-item{
      //border-left: none;
    }
    &:hover,
    &:focus{

    }
  }
  &-item__link{
    @include single-transition(background);
    padding: ($app-grid__column-gutter / 4) ($app-grid__column-gutter);
    display: block;
    //border-top-right-radius: $app-product-category__category__tabs__inline-list-item__link__border-radius;
    //border-top-left-radius: $app-product-category__category__tabs__inline-list-item__link__border-radius;
    background: $app-product-category__category__tabs__inline-list-item__link__background;
    color: $app-product-category__category__tabs__inline-list-item__link__font-color;
    @at-root .selected .product-category__category__tabs__inline-list-item__link{
      background: $app-product-category__category__tabs__inline-list-item__link-selected__background;
      color: $app-product-category__category__tabs__inline-list-item__link-selected__font-color;
      &:hover,
      &:focus{
        color: $app-product-category__category__tabs__inline-list-item__link-selected__font-color--hover;
      }
    }
    &:hover,
    &:focus{
      background: $app-product-category__category__tabs__inline-list-item__link__background--hover;
      color: $app-product-category__category__tabs__inline-list-item__link__font-color--hover;
    }
  }
}

.product-category__category__sub-category__tabs__list{
  background: $app-product-category__category__sub-category__tabs__list__background;
  &-item{
    border-bottom: $app-product-category__category__sub-category__tabs__list-item__border-bottom;
    border-right: $app-product-category__category__sub-category__tabs__list-item__border-right;
    &.selected{
      border-right: none;
      border-top: $app-product-category__category__sub-category__tabs__list-item__border-right;
      border-bottom: $app-product-category__category__sub-category__tabs__list-item__border-right;
      background: $app-product-category__category__sub-category__tabs__list-item-selected__background;
      &:first-child{
        border-top: none;
      }
    }
  }
  &-item__link{
    display: block;
    padding: $app-product-category__category__sub-category__tabs__list-item__link__padding;
    font-size: $app-product-category__category__sub-category__tabs__list-item__link__small__font-size;
    color: $app-product-category__category__sub-category__tabs__list-item__link__font-color;
    &:hover,
    &:focus{
      background: $app-product-category__category__sub-category__tabs__list-item__link__background--hover;
    }
    @at-root .selected .product-category__category__sub-category__tabs__list-item__link {
      color: $app-product-category__category__sub-category__tabs__list-item-selected__link__font-color;
    }
  }
}

.product-category__vertical-theme__sub-categories__list{
  margin-top: $app-grid__column-gutter / 2;
  &-item{
    border-bottom: $app-product-category__vertical-theme__sub-categories__list-item__border;
  }
  &-item__link{
    display: block;
    padding: $app-grid__column-gutter / 3;
    &:hover,
    &:focus{

    }
  }
}

.products-list-sub-category{
  height: 1px;
  width: 1px;
  overflow: hidden;
  //clip: rect(1px, 1px, 1px, 1px);
  visibility: hidden;
  opacity: 0;
  transition: opacity .75s linear;
  &.show{
    height: auto;
    width: auto;
    overflow: visible;
    //clip: auto;
    visibility: visible;
    opacity: 1;
  }
}

.product-category__header{
  margin-bottom: $app-grid__column-gutter / 2;
  &__headline{
  }
}

.product-category__slipsheet-theme{
  padding-top: 0;
  &.blue-gradient{
    & .product-category__header{
      background: $app-product-category__slipsheet-theme__blue-gradient__background;
    }
  }
  &.brown-gradient{
    & .product-category__header{
      background: $app-product-category__slipsheet-theme__brown-gradient__background;
    }
  }
  &.green-gradient{
    & .product-category__header{
      background: $app-product-category__slipsheet-theme__green-gradient__background;
    }
  }
  &.gray-gradient{
    & .product-category__header{
      background: $app-product-category__slipsheet-theme__gray-gradient__background;
    }
  }
  & .product-category__header__text-container{
    text-align: $app-product-category__slipsheet-theme__product-category__header__text-container__text-align;
  }
  & .product-category__header{
    padding: 0 rem-calc(34);
    &__headline{
      padding-top: $app-grid__column-gutter / 2;
      text-align: $app-product-category__slipsheet-theme__product-category__header__headline__text-align;
      border-bottom: $app-product-category__slipsheet-theme__product-category__header__headline__border-bottom;
      color: $app-product-category__slipsheet-theme__product-category__header__headline__font-color;
      text-shadow: $app-product-category__slipsheet-theme__product-category__header__headline__text-shadow;
      &--collapse-top{
        padding-top: 0;
      }
    }
    &__subhead{
      //font-size: $app-product-category__slipsheet-theme__product-category__header__subhead__font-size;
    }
  }
}

.product-category__sub-categories__list{
  margin: rem-calc(30) 0 0 0;
  text-align: center;
  list-style: none outside none;
  &-item{
    display: block;
    width: 100%;
    margin-bottom: $app-grid__column-gutter;
    &-item__link{
      display: block;
      color: #6C4212;
      &:hover,&:focus{

      }
    }
  }
}
.product-category__sub-categories__list-item__container{
  margin: 0;
  font-size: rem-calc(16);
  font-weight: $base__font-weight--medium;
}
.product-category__sub-categories__list-item__link__name{
  display: block;
  padding: $app-grid__column-gutter / 4;
  width: 100%;
  background: #f0f6fa;
  border-top: 1px solid #d8d8d8;
  &:hover,
  &:focus{
      background: #dfeaf2;
  }
}
.product-category__sub-category-rows__list{
  &-item{
    border-top: 1px solid #d4d4d4;
    margin-bottom: $app-grid__column-gutter / 2;
    &:first-child{
      border-top: none;
    }
    padding-top: $app-grid__column-gutter / 2;
  }
  &-item__subcategory__column,
  &-item__child-category__column{
    @include grid-column-padding();
  }
  &-item__subcategory__name{
    color: #222222;
  }
  &-item__child-category__subhead{
    font-size: $app-product-category__sub-category-rows__list-item__child-category__subhead__font-size;
    font-weight: $app-product-category__sub-category-rows__list-item__child-category__subhead__font-weight;
    color: $app-product-category__sub-category-rows__list-item__child-category__subhead__font-color;
  }
  &-item__child-category__link{
    display: block;
    background: $app-product-category__sub-category-rows__list-item__child-category__link__background;
    border: $app-product-category__sub-category-rows__list-item__child-category__link__border;
    border-radius: $app-product-category__sub-category-rows__list-item__child-category__link__border-radius;
    padding: $app-product-category__sub-category-rows__list-item__child-category__link__padding;
    font-size: nth($app-product-category__sub-category-rows__list-item__child-category__link__font-sizes,1);
    font-weight: $app-product-category__sub-category-rows__list-item__child-category__link__font-weight;
    line-height: nth($app-product-category__sub-category-rows__list-item__child-category__link__line-height,1);
    //padding: $app-grid__column-gutter / 4 0;
  }
}
.product-category__category__filter__products__list{
  list-style: none;
  &-item{

  }
  &-item__heading{
    border-bottom: 1px solid #D8D8D8;
    border-top: 1px solid #D8D8D8;
    padding: rem-calc(4);
    background: #f0f6fa;
    color: #444444;
  }
}


@include mq(medium-up){
  //.product-category__category-display__column,
  //.product-category__category-information__column{
  //  @include grid-column(6, $padding: false);
  //}
  .product-category__vertical-theme__category-display__column{
    @include grid-column(7, $padding: false);
  }
  .product-category__vertical-theme__category-list__column{
    @include grid-column(5, $padding: false);
  }
  .product-category__category__sub-category__tabs__column{
    margin-top: 0;
    @include grid-column(4, $padding: false);
  }
  .product-category__category__sub-category__product-list__column{
    @include grid-column(8, $padding: false);
    & .small-list .product-list-item{
      @include grid-column($columns: 4, $float: false, $padding: false);
    }
    & .large-list .product-list-item{}
  }
  .product-category__sub-categories__list{
    &-item{
      display: inline-block;
      vertical-align: top;
      @include grid-column($columns:6,$float:false,$collapse:false);
    }
  }
  .product-category__sub-categories__list-item__container{
    border: $app-product-category__sub-categories__list-item__container__border;
    border-radius: $app-product-category__sub-categories__list-item__container__border-radius;
    &:hover,&:focus{
      border-color: $app-product-category__sub-categories__list-item__container__border-color--hover;
    }
  }
  .product-category__category__tabs__inline-list{
    &-item{
      float: left;
      border-left: $app-product-category__category__tabs__inline-list-item__border;
      border-left: 1px solid #bbb;
      border-top: 1px solid #dddddd;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      &:last-child{
        border-right: 1px solid #999999;
      }
      //&:first-child,
      &.selected,
      &.selected + .product-category__category__tabs__inline-list-item{
        border-left: none;
      }
      &:hover,
      &:focus{

      }
    }
    &-item__link{
      padding: ($app-grid__column-gutter / 2) ($app-grid__column-gutter / 1.5);
      border-top-right-radius: $app-product-category__category__tabs__inline-list-item__link__border-radius;
      border-top-left-radius: $app-product-category__category__tabs__inline-list-item__link__border-radius;
      @at-root .selected .product-category__category__tabs__inline-list-item__link{
        &:hover,
        &:focus{
        }
      }
      &:hover,
      &:focus{
      }
    }
  }
  .product-category__category__sub-category__tabs__column {
    @include grid-column-padding($left: false);
  }
  .product-category__category__sub-category__tabs__list{
    &-item{
      &.selected{
        background: $app-product-category__category__sub-category__tabs__list-item-selected__medium-up__background;
        &:first-child{
        }
      }
    }
    &-item__link{
      font-size: $app-product-category__category__sub-category__tabs__list-item__link__medium-up__font-size;
      padding: $app-product-category__category__sub-category__tabs__list-item__link__medium-up__padding;
      &:hover,
      &:focus{
      }
      @at-root .selected .product-category__category__sub-category__tabs__list-item__link {
        color: $app-product-category__category__sub-category__tabs__list-item-selected__link__medium-up__font-color;
      }
    }
  }
  .product-category__sub-category-rows__list{
    &-item{
      @include clearfix();
    }
    &-item__subcategory__column,
    &-item__child-category__column{
      @include grid-column(6, $padding: false);
    }
    &-item__subcategory__column{
      text-align: right;
    }
    &-item__child-category__link{
      font-size: nth($app-product-category__sub-category-rows__list-item__child-category__link__font-sizes,2);
      line-height: nth($app-product-category__sub-category-rows__list-item__child-category__link__line-height,2);
    }
  }
}

@include mq(large-up){
  .product-category__header{
    display: flex;
    height: 100%;
  }
  .product-category__category-display__column{
    //flex: 1 1 50%;
    max-width: percentage( (8 / $app-grid__total-columns) );
  }
  .product-category__category-information__column{
    flex: 1 1 percentage( (4 / $app-grid__total-columns) );
    max-width: 50%;
  }
  .product-category__new-products__sub-category-information__column{
    @include grid-column(7, $padding: false);
  }
  .product-category__new-products__sub-category-display__column{
    @include grid-column(5, $padding: false);
  }
  .product-category__category__sub-category__tabs__column{
    @include grid-column(3, $padding: false, $float: false);
  }
  .product-category__category__sub-category__product-list__column{
    @include grid-column(9, $padding: false, $float: false);
    & .small-list .product-list-item{
      //@include grid-column($columns: 3, $float: false, $padding: false);
      width: 20%;
    }
    & .large-list .product-list-item{}
  }
  .product-category__sub-categories__list{
    &-item{
      @include grid-column($columns:3,$float:false,$collapse:false);
    }
  }
  .product-category__sub-category-rows__list{
    &-item{}
    &-item__subcategory__column,
    &-item__child-category__column{

    }
    &-item__subcategory__column{

    }
    &-item__child-category__link{
      font-size: nth($app-product-category__sub-category-rows__list-item__child-category__link__font-sizes,3);
      line-height: nth($app-product-category__sub-category-rows__list-item__child-category__link__line-height,3);
    }
  }
  .product-category__slipsheet-theme{
    & .product-category__header{
      &__headline{
        color: $app-product-category__slipsheet-theme__product-category__header__headline__large-up__font-color;
        text-shadow: $app-product-category__slipsheet-theme__product-category__header__headline__large-up__text-shadow;
      }
    }
  }
  .product-category__category__tabs__inline-list{
    &-item{
      &:first-child,
      &.selected,
      &.selected + .product-category__category__tabs__inline-list-item{
      }
      &:hover,
      &:focus{
      }
    }
    &-item__link{
      padding: ($app-grid__column-gutter / 2) ($app-grid__column-gutter);
      @at-root .selected .product-category__category__tabs__inline-list-item__link{
        &:hover,
        &:focus{
        }
      }
      &:hover,
      &:focus{
      }
    }
  }
}

