$app-footer-main__padding: $app-grid__column-gutter / 2 !default;
$app-footer-main__background-color: null !default;
$app-footer-main__background: $app-footer-main__background-color !default;
$app-footer-main__background-gradient: null !default;
$app-footer-main__border-top: null !default;
$app-footer-main__company-info__border-left--small: null !default;
$app-footer-main__resources-nav__list-link__opacity: null !default;
$app-footer-main__resources-nav__list-link__color: null !default;
$app-footer-main__resources-nav__list-link__opacity--hover: null !default;
$app-footer-main__resources-nav__list-link__color--hover: null !default;
$app-footer-main__company-info__list-item__color: null !default;
$footer__main-content__social-nav__list-item__color: null !default;

.footer__main{
  border-top: $app-footer-main__border-top;
  padding: $app-footer-main__padding $app-footer-main__padding rem-calc(70) $app-footer-main__padding;
  background: $app-footer-main__background;
  background: $app-footer-main__background-gradient;
  &-content, &-row {
    @include clearfix();
  }
  &-content__resources-nav__list,
  &-content__info-company__list,
  &-content__misc-nav__list,
  &-content__social-nav__list{
    list-style: none;
    overflow: hidden;
    margin-left: 0;
  }
  &-content__resources-nav__list-link,
  &-content__misc-nav__list-link,
  &-content__social-nav__list-link{
    color: $app-footer-main__resources-nav__list-link__color;
    opacity: $app-footer-main__resources-nav__list-link__opacity;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) 0;
    display: block;
    &:hover, &:focus{
      color: $app-footer-main__resources-nav__list-link__color--hover;
      opacity: $app-footer-main__resources-nav__list-link__opacity--hover;
    }
  }
  &-content__social-nav__list{
    &-item{
      color: $footer__main-content__social-nav__list-item__color;
    }
  }
  &-content__resources{
    @include grid-column(5, $padding: false);
    &-nav{

    }
    &-nav__list{
      &-item {

      }
      &-link{
      }
    }
  }
  &-content__info{
    @include grid-column(7, $padding: false);
    border-left: $app-footer-main__company-info__border-left--small;
    padding-left: $app-grid__column-gutter / 2;
    &-company__list{
      &-item{
        color: $app-footer-main__company-info__list-item__color;
        padding: rem-calc(5) rem-calc(5) rem-calc(5) 0;
      }
      &-link{

      }
    }
  }
  &-content__misc-nav{
    &__list{
      &-item{

      }
      &-link{

      }
    }
  }
}

@include mq(medium-only) {
  .footer__main{
    &-content__misc-nav__list{
      padding-left: $app-grid__column-gutter;
    }
  }
}

@include mq(medium-up) {
  .footer__main{
    padding-bottom: $app-footer-main__padding;
    &-content__resources{
      @include grid-column(4, $padding: false, $float: false);
    }
    &-content__info{
      @include grid-column(8, $padding: false, $float: false);
      border-left: none;
    }
    &-content__info-company__list,
    &-content__misc-nav__list{
      @include grid-column(6, $padding: false);
    }
  }
}

@include mq(large-up) {
  .footer__main{
    &-content-info{
      padding-left: 0;
    }
    &-content__resources,
    &-content__info,
    &-content__info-company__list,
    &-content__misc-nav__list{
      width: auto;
      float: none;
    }
    &-content__social-nav__list{
      margin-bottom: 0;
    }
    &-content__resources-nav__list,
    &-content__info-company__list,
    &-content__misc-nav__list,
    &-content__social-nav__list{
      display: table;
      margin-left: auto;
      margin-right: auto;
      &-item{
        display: table-cell;
      }
      &-link{
        display: inline-block;
        padding-left: $app-grid__column-gutter / 3;
        padding-right: $app-grid__column-gutter / 3;
      }
    }
    &-content__social-nav__list-link{
      padding-right: 0;
    }
    &-content__info-company__list-item{
      padding: rem-calc(5);
    }
  }
}