.account-sign-on{
  &__returning-customer-column,
  &__new-customer-column{
    @include grid-column-padding();
    margin-bottom: 1rem;
  }
}

@include mq(medium-up){
  .account-sign-on{
    &__returning-customer-column,
    &__new-customer-column{
      @include grid-column(6, $collapse: true);
    }
    &__returning-customer-column{
      padding-right: $app-grid__column-gutter *2;
      border-right: 1px solid #f5eed3;
    }
    &__new-customer-column{
      padding-left: $app-grid__column-gutter *2;
    }
  }
}

@include mq(large-up){
  .account-sign-on {
    &__returning-customer-column,
    &__new-customer-column {

    }
  }
}