$app-offer-code__form-input__border-color: null !default; //#a7cae1

.offer-code__row,
.offer-code__description{
  @include clearfix();
}

.offer-code__note{
  margin-top: $app-grid__column-gutter / 3;
}

.offer-code__form{

}

.offer-code__form-label{
}

input.offer-code__form-input__code{
  //margin-bottom: 0;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: $app-offer-code__form-input__border-color;
  &:focus{
  }
}

.offer-code__form-input__submit-button{
  float: left;
  width: 50%;
  line-height: .8;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: $app-form__input-buttons__match-text-input__height;
  padding-top: 0;
  padding-bottom: 0;
}

input.offer-code__form-input__code{
  float: left;
  width: 50%;
  border-bottom: $app-form__input__border;
  border-top-left-radius: $base__border-radius;
  border-bottom-left-radius: $base__border-radius;
  border-top-right-radius: 0;
}

.offer-code__edit-button{

}
.offer-code__note__column{
  margin-left: rem-calc(35);
}
.offer-code__form-label__info__link__column{
  float: left;
  width: rem-calc(35);
}
.offer-code__form-label__info__link{
  //font-size: rem-calc(16);
  //vertical-align: text-top;
}

@include mq(medium-up){
  .offer-code__form-label__column,
  .offer-code__form-input__column{
    float: left;
  }
  .offer-code__form-label__column{
    text-align: right;
  }
  .offer-code__form-label__column,
  .offer-code__checkout__form-label__column{
    width: 58.33333%;
    padding-right: 10px;
  }
  .offer-code__form-label{
    font-size: rem-calc(21);
  }
  .offer-code__form-input__column,
  .offer-code__checkout__form-input__column{
    width: 41.66666%;
  }
}

@include mq(large-up){
  .offer-code__form-label__column,
  .offer-code__checkout__form-label__column{
    width: 66.66666%;
  }
  .offer-code__form-input__column,
  .offer-code__checkout__form-input__column{
    width: 33.33334%;
  }
}
