$app-product__qty-calculated__font-style: italic !default;
$app-product__qty-calculated__color: #555555 !default;

$app-product-detail__product__sku-display__color: #444444 !default;;
$app-product-detail__product__sku-display__font-size: rem-calc(14) !default;;

$app-product-detail__product-display__thumbnail__column__width: null !default;
$app-product-detail__product-display__thumbnail__initial__max-height: null !default;
$app-product-detail__product-display__thumbnail__border-color: null !default;
$app-product-detail__product-display__thumbnail__border: null !default;
$app-product-detail__product-display__thumbnail__border-radius: null !default;

//
// give headings classes and decouple from .product-detail
//

.product-detail{
  padding-top: $app-grid__column-gutter / 2;
  @include clearfix();
  h1{
    font-size: rem-calc(38);
    font-weight: bold;
    line-height: 1.3;
  }
  h2{
    font-size: rem-calc(28);
    line-height: 1.3;
  }
  h3{
    font-size: rem-calc(19);
    font-style: italic;
    line-height: 1.4;
  }
    &__product-display__small-only{
        margin-bottom: $app-grid__column-gutter / 2;
    }
    &__product-display{
        @include grid-column($columns:12);
    }
    &__product-display__medium-up{
        display: none;
    }
    &__product__sku-display{
        margin-bottom: $app-grid__column-gutter / 4;
        color: $app-product-detail__product__sku-display__color;
        font-size: $app-product-detail__product__sku-display__font-size;
    }
}

.product-information{
  @include grid-column($columns:12);
}

.product-detail__product-offer__banner{
  &__img{

  }
}
.product-detail__add-to-cart__form__input-list{
  @include clearfix();
  &-item{
    &__quantity{
      @include grid-column(4, $collapse: true);
    }
    &__quantity-calculated{
      @include grid-column($columns:5, $collapse: true,$last-column: true);
      font-style: $app-product__qty-calculated__font-style;
      padding-left: rem-calc(12);
      font-size: rem-calc(18);
      line-height: rem-calc(40);
      color: $app-product__qty-calculated__color;
    }
    &__submit{
      @include grid-column($columns:3, $collapse: true);
    }
  }
}

.product-detail__specs__list{
  margin-bottom: $app-grid__column-gutter;
  &-name{
    float: left;
    margin-right: 5%;
    max-width: 30%;
    font-weight: bold;
    line-height: 1.3;
  }
  &-value{
    margin-left: 35%;
    margin-bottom: .5em;
    &:after{
      content: " ";
      display: block;
      clear: left;
    }
  }
}

@include mq(medium-up){
  .product-detail{
      &__product-display{
          @include grid-column($columns:6,$pull:6);
      }
      &__product-display__small-only{
          display: none;
      }
      &__product-display__medium-up{
          display: block;
      }
  }
  .product-information{
    @include grid-column($columns:6,$push:6);
  }
}

@include mq(large-up){
  .product-detail{}
}
