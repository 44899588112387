$auto-suggest__list__background: $base__white !default;
$auto-suggest__list__border: 1px solid $base__light-gray__border-color !default;
$auto-suggest__suggestion__hover__background: $base__light-gray-color !default;

.react-autosuggest{
    &__suggestions-container{
        position: relative;
    }
    &__suggestions-list{
        @include shadows(1);
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: rem-calc(150);
        overflow-y: auto;
        background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)), linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0)), linear-gradient(to bottom, #e4e4e4, rgba(255, 255, 255, 0)), linear-gradient(to top, #e4e4e4, rgba(255, 255, 255, 0));
        background-position: 0 0, 0 100%, 0 0, 0 100%;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 100% 5em, 100% 5em, 100% 1em, 100% 1em;
        background-attachment: local, local, scroll, scroll;
        border: $auto-suggest__list__border;
        &::after{
            display: block;
            border-top: $auto-suggest__list__border;
            content: '';
            height: rem-calc(18);
            width: 100%;
            background: url("/assets/img/style/powered-by-google.png") $auto-suggest__suggestion__hover__background no-repeat center center / contain;
        }
    }
    &__suggestion{
        padding: $app-grid__column-gutter / 6;
        border-bottom: $auto-suggest__list__border;
        white-space: nowrap;
        &--highlighted, &:hover, &:focus{
            background: $auto-suggest__suggestion__hover__background;
        }
        &:hover{
            cursor: pointer;
        }
        &:last-child{
            border-bottom: none;
        }
    }
}
