$app-table__border-color: #dddddd !default;
$app-table__border: 1px solid $app-table__border-color !default;
$app-table__margin-bottom: rem-calc(20) !default;
$app-table__row__padding: rem-calc(9) rem-calc(10) !default;
$app-table__row__font-size: rem-calc(14) !default;
$app-table__row__font-size--small: rem-calc(12) !default;
$app-table__row__color: #333333 !default;
$app-table__row__line-height: 1.3 !default;
$app-table__row-even__background-color: #f9f9f9 !default;
$app-table__row-even__background: $app-table__row-even__background-color !default;
$app-table__thead__background-color: #dddddd !default;
$app-table__thead__background: $app-table__thead__background-color !default;
$app-table__thead__font-size: rem-calc(14) !default;
$app-table__thead__font-color: #333333 !default;
$app-table__thead__font-weight: bold !default;
$app-table__thead__padding: rem-calc(8) rem-calc(10) rem-calc(10) !default;
$app-table__thead__small__font-size: rem-calc(11) !default;
$app-table__thead__small__padding: rem-calc(4) rem-calc(6) rem-calc(6) !default;
$app-table__thead__column--sorted-asc__background-img: linear-gradient(0deg, rgba(245,245,245,1) 25%, rgba(221,221,221,1) 100%) !default;
$app-table__thead__column--sorted-desc__background-img: linear-gradient(0deg, rgba(221,221,221,1) 30%, rgba(245,245,245,1) 100%) !default;


.display__table{
  display: table;
  &__row{
    display: table-row;
  }
  &__cell{
    display: table-cell;
  }
  &__head{
    display: table-header-group;
  }
  &__foot{
    display: table-footer-group;
  }
  &__body{
    display: table-row-group;
  }
}

table, .display__table {
  margin-bottom: $app-table__margin-bottom;
  border: $app-table__border;
  width: 100%;
}

th,
td,
.display__table__cell {
  padding: $app-table__row__padding;
  font-size: $app-table__row__font-size;
  color: $app-table__row__color;
  line-height: $app-table__row__line-height;
}

thead,
tfoot,
.display__table__head,
.display__table__foot{
  background: $app-table__thead__background;
  th,
  td,
  .display__table__cell{
    padding: $app-table__thead__padding;
    font-size: $app-table__thead__font-size;
    font-weight: $app-table__thead__font-weight;
    color: $app-table__thead__font-color;
    text-align: left;
    &.table__head__column--small{
      font-size: $app-table__thead__small__font-size;
      padding: $app-table__thead__small__padding;
    }
  }
}
.table__head,
.table__foot,
.display__table__head,
.display__table__foot{
  &--is-clean{
    border-bottom: 2px solid $app-table__border-color;
    background: transparent;
  }
}
.table__head__rotate{
  //height: 4rem;
  white-space: nowrap;
  width: 2rem;
  &__child{
    display: block;
    transform: rotate(270deg);
    width: 2rem;
  }
}
.table__head__column--sorted{
  &-asc{
    background-image: $app-table__thead__column--sorted-asc__background-img;
  }
  &-desc{
    background-image: $app-table__thead__column--sorted-desc__background-img;
  }
}


tr,
.display__table__row{
  &.even,
  &.alt{
    background: $app-table__row-even__background;
  }
}

.table--bordered{
  th,
  td,
  .display__table__cell {
    border: $app-table__border;
  }
  th,
  .display__table__head .display__table__cell,
  .display__table__foot .display__table__cell{
    border-color: shade($app-table__border-color, $base__color-shift);
  }
}

.table--rows-bordered{
  tr,
  .display__table__row{
    border-top: $app-table__border;
    &:first-child {
      border-top: none;
    }
  }
}

.table__row--on-hover-highlight:hover,
.table__row--is-hoverable:hover,
.display__table__row:hover{
  background-color: $base__lightest-gray-color;
}

.table__row--is-selectable:hover{
  cursor: pointer;
}

.table__row--is-selected,
.table__row--is-selectable:active,
.table__row--is-selectable:focus{
  background-color: $base__secondary-color;
}

.table--striped{
  tr,
  .display__table__row{
    &:nth-of-type(even){
      background: $app-table__row-even__background;
    }
  }
}

.table--right-divider,
th.table--right-divider,
td.table--right-divider{
  border-right-color: shade($app-table__border-color, $base__color-shift*2);
}

.table__column{
  &__font-size{
    font-size: $app-table__row__font-size;
    line-height: $app-table__row__line-height;
    &--small{
      font-size: $app-table__row__font-size--small;
    }
  }
  &--fit-to-content{
    width:1%;
  }
  &--no-wrap{
    white-space:nowrap;
  }
}