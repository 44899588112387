@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap');
$app-adz__row: null !default;
$app-adz__card__main-home__margin-left: rem-calc(295) !default;
$app-adz__card__main-home__max-width: $app-header-main__max-width !default;
$app-adz__card__text__container__padding: $app-grid__column-gutter / 2 !default;
$app-adz__card__text__reversed__color: #f9f6e4 !default;
$app-adz__card__heading__font-sizes: $app-heading__h3__font-sizes !default;
$app-adz__card__heading__line-height: $app-heading__h3__line-height !default;
$app-adz__card__heading__font-weight: $app-heading__h3__font-weight !default;
$app-adz__card__heading__font-family: $app-heading__h1__font-family !default;
$app-adz__card__heading__font-style: $app-heading__h3__font-style !default;
$app-adz__card__subhead__font-sizes: rem-calc(16), rem-calc(16), rem-calc(16) !default;
$app-adz__card__subhead__line-height: $app-heading__h4__line-height !default;
$app-adz__card__subhead__font-weight: $base__font-weight--light !default;
$app-adz__card__subhead__font-style: $app-heading__h4__font-style !default;

$app-adz__card__alt__text__reversed__color: #ffffff !default;
$app-adz__card__alt__heading__font-family: $base__font-family !default;
$app-adz__card__alt__heading__font-sizes: rem-calc(28), rem-calc(36), rem-calc(42) !default;
$app-adz__card__alt__heading__line-height: $app-heading__h2__line-height !default;
$app-adz__card__alt__heading__font-weight: $base__font-weight--light !default;
$app-adz__card__alt__heading__font-style: null !default;
$app-adz__card__alt__heading__text-shadow: 2px 2px 4px rgba(0,0,0, .5) !default;
$app-adz__card__alt__subhead__font-sizes: rem-calc(19), rem-calc(21), rem-calc(26) !default;
$app-adz__card__alt__subhead__line-height: $base__font-line-height, $base__font-line-height, $base__heading__line-height !default;
$app-adz__card__alt__subhead__font-weight: $base__font-weight--light !default;
$app-adz__card__alt__subhead__font-style: null !default;
$app-adz__card__alt__subhead__text-shadow: null !default;


$app-adz__card__main__heading__font-family: $app-heading__h1__font-family !default;
$app-adz__card__main__heading__font-sizes: rem-calc(26), rem-calc(36), rem-calc(43) !default;
$app-adz__card__main__heading__line-height: 1.25, 1.15, 1.1 !default;
$app-adz__card__main__heading__font-weight: $app-heading__h1__font-weight !default;
$app-adz__card__main__heading__font-style: $app-heading__h1__font-style !default;
$app-adz__card__main__subhead__font-sizes: rem-calc(16), rem-calc(18), rem-calc(24) !default;
$app-adz__card__main__subhead__line-height: $app-heading__h3__line-height !default;
$app-adz__card__main__subhead__font-weight: $base__font-weight--medium !default;
$app-adz__card__main__subhead__font-style: null !default;

.a-dz{
  &__row {
    @include clearfix();
    margin-bottom: $app-grid__column-gutter * 1.5;
    &--force-full-width{
      @include force-full-width();
    }
    &--padded{
      padding-left: $app-grid__column-gutter / 2;
      padding-right: $app-grid__column-gutter / 2;
    }
    &__main-home{
      margin-bottom: $app-grid__column-gutter;
    }
  }
  &__column{

  }
}
.a-dz__pre-header{
  &__feature{
    text-align: center;
    border-bottom: 1px solid;
    font-weight: normal;
    padding: rem-calc(5);
    &--blue{
      background:#abc6d9;//#a8c0d2
      border-bottom-color: #4c7290;
    }
    &__btn{
      @extend .btn;
      padding: rem-calc(3) 0 rem-calc(3) rem-calc(13);
      margin-right: rem-calc(16);
      &__blue--outline{
        @extend .btn__secondary--outline;
        border-color: #628AD8;
        &:hover, &:focus{
          border-color: shade(#628AD8, $base__color-shift);
        }
      }
    }
  }
}
.a-dz__list{
  list-style: none;
  overflow: hidden;
  margin: 0;
  &-item{
    padding-left: $app-grid__column-gutter;
    padding-right: $app-grid__column-gutter;
    margin-bottom: $app-grid__column-gutter;
    &:last-child{
      margin-bottom: 0;
    }
    &__1-column{

    }
    &--banner{
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.a-dz__card{
  position: relative;
  z-index: 1;
  &:hover{
    cursor: pointer;
  }
  &--is-flex{
    display: flex;
    align-items: center;
  }
  &__1-column{
    border-radius: $base__border-radius;
    overflow: hidden;
    &--with-border{
      border: 1px solid #d4d4d4;
    }
  }
  &__columns {
    height: 100%;
    &--max-width{
      max-width: $app-header-main__max-width;
      margin: auto;
    }
  }
  &__column{
    @include grid-column-padding();
    &--split-screen-left{
      order: 1;
      height: 100%;
    }
    &--split-screen-right{
      order: 2;
      height: 100%;
    }
  }
  &--blue,
  &__group--blue{
    background: #a8c0d2;//#9cb9cc;//#a5b7c3;
  }
  &--dark-blue,
  &__group--dark-blue{
    background: #3f72a6;
    background: linear-gradient(351deg, rgba(72,124,190,1) 25%, rgba(34,73,113,1) 100%);
    border-top: 1px solid #163e67;
    border-bottom: 1px solid #0c2946;
  }
 &--darker-blue,
  &__group--darker-blue{
    background: #1a3e89;
    background: linear-gradient(145deg,#1a3e89 71%,#365aa5);
    border-top: 1px solid #163e67;
    border-bottom: 1px solid #0c2946;
  }
  /*&--blue-gradient{
    background: #a5b7c3;
    background: linear-gradient(to bottom, #ffffff 0%,#a5b7c3 80%);
  }*/
  &--drk-purple,
  &__group--drk-purple{
    background: #42265C;
    border-top: 1px solid #371d69;
    border-bottom: 1px solid #260f51;
  }
  &--green,
  &__group--green{
    background: #4d7856;
    border-top: 1px solid #1b4925;
    border-bottom: 1px solid #0d2f14;
  }
  /*&--green-gradient{
    background: #4d7856;
    background: linear-gradient(to bottom, #ffffff 0%,#4d7856 80%);
  }*/
  &--brown,
  &__group--brown{
    background: #6C4212;
    border-top: 1px solid #352009;
    border-bottom: 1px solid #352009;
  }
  /*&--brown-gradient{
    background: #6C4212;
    background: linear-gradient(to bottom, #ffffff 0%,#6C4212 80%);
  }*/
  &--tan,
  &__group--tan{
    background: $app-header-main__background--color;
    border-top: 1px solid #cfc187;
    border-bottom: 1px solid #cfc187;
  }
  &--teal,
  &__group--teal{
    background: #15A295;
    background: linear-gradient(180deg,#15B8A9 30%,#06897D);
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
  }
  &--red,
  &__group--red{
    background: #7c0e0e;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
  }
  &--burgundy,
  &__group--burgundy{
    background: #72253d;
    background: linear-gradient(145deg,#72253d 71%,#5a1429);
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
  }
  &--lt-grey,
  &__group--lt-grey{
    background: #eeeeee;
    border-top: #555555;
    border-bottom: #555555;
  }
  &--grey,
  &__group--grey{
    background: #d4d4d4;
    border-top: #555555;
    border-bottom: #555555;
  }
  &--cream,
  &__group--cream{
    background: #f7e4a2;//#f5e4ab;
    border-top: 1px solid #ddc682;
    border-bottom: 1px solid #ddc682;
  }
  &--orange-gradient{
    background: #fcb03f;
    background: linear-gradient(to right, #fee3bc 0%,#fcb03f 90%);
  }
  &__group{
    padding-top: $app-grid__column-gutter / 2;
    padding-bottom: $app-grid__column-gutter;
  }
  &__heading__row{
    &__main-home{
      padding-top: $app-grid__column-gutter / 3;
    }
  }
  &__img{
    &__container{
      margin-top: rem-calc(16);
      text-align: center;
      &--is-flexed-full-width{
        flex: 0 0 100%;
      }
      &__1-column,
      &__banner,
      &--collapse{
        margin-top: 0;
      }
      &__1-column{
        margin-bottom: 1px;
      }
    }
    &__banner{}
    &__force-full-width{
      width: 100%;
    }
  }
  &__main-home{
    height: 100%;
  }
  &__text__center{
    &--up-to-large{
      text-align: center;
    }
  }
  &__text__container{
    padding: $app-adz__card__text__container__padding / 2 $app-adz__card__text__container__padding $app-adz__card__text__container__padding $app-adz__card__text__container__padding;
    position: relative;
    &--center-text-small-only{
      text-align: center;
    }
    &__main-home{
      text-align: center;
      margin-bottom: - $app-grid__column-gutter;//-35px;
      //border-top: 1px solid #666666;
      &--visually-hide{
        @include element-invisible();
      }
    }
    &--z-indexed{
      z-index: 1;
    }
    &--max-width{
      max-width: $app-grid__row-width;
    }
    &--floating{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-bottom: 0;
    }
    &--floating--medium-up,
    &--floating--medium-alt-up{
      //border-top: 1px solid #666666;
      //background: #f0f6fa;
      //box-shadow: 0 -2px 6px rgba(60, 60, 60, 0.6);
      //margin-bottom: 0;
    }
    &__1-column{
      padding: 0;
      text-align: center;
    }
    &__banner{
      text-align: center;
      &--overlay-for-small{
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(65,39,10, 0.6);
      }
      & .a-dz__card__heading--reversed,
      & .a-dz__card__subhead--reversed{
        text-shadow: 2px 2px 2px rgba(30,30,30, 0.8);
      }
      @at-root .a-dz__card__text-shadow{
        text-shadow: 2px 2px 2px rgba(30,30,30, 0.8);
      }
    }
  }
  &__text__highlight__panel{
    border-width: 1px;
    border-style: solid;
    border-radius: $base__border-radius * 4;
    display: inline-block;
    padding: $app-grid__column-gutter / 2;
    @at-root .a-dz__card--dark-blue .a-dz__card__text__highlight__panel{
      background: rgba( #3f72a6, .65);
      border-color: #3f72a6;
    }
  }
  &__img__container,
  &__text__container{
    &--split-screen{
      width: 100%;
      max-width: $app-header-main__max-width / 2;
    }
    &--split-screen-left,
    &--split-screen-right{
      padding-top: $app-grid__column-gutter / 2;
      padding-bottom: $app-grid__column-gutter / 2;
    }
    &--split-screen-left{
      margin: auto 0 auto auto;
    }
    &--split-screen-right{
      margin-left: 0;
    }
  }
  &__cta__container{
    position: relative;
    &__1-column{
      position: relative;
      box-shadow: 0 -2px 6px rgba(60, 60, 60, 0.6);
      border-radius: $base__border-radius;
      &--floating-at-bottom{
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
      }
    }
    &--bottom-right {
      //position: absolute;
      //bottom: 0;
      //right: 0;
    }
  }
  &__cta{
    @include shadows(2);
    padding: rem-calc(13) rem-calc(15);
    font-size: rem-calc(18);//$app-btn__small__font-size;
    &--primary{
      @extend .btn__primary;
    }
    &--secondary,
    &__1-column{
      @extend .btn__secondary;
    }
    &--warning{
      @extend .btn__warning;
    }
    &--alt-blue{
      @extend .btn__alt-blue;
    }
    &--alt-teal{
      @extend .btn__alt-teal;
    }
    &--red{
      @extend .btn__red;
    }
    &__1-column{
      width: 100%;
      padding: rem-calc(10);
      font-size: $app-btn__small__font-size;
    }
    &--collapse-right{
      padding-right: 0;
    }
    &__sub-copy{
      display: block;
      font-size: .75em;
    }
    &__center--small-only{
      text-align: center;
    }
  }
  &__heading{
    font-family: $app-adz__card__heading__font-family;
    font-size: nth($app-adz__card__heading__font-sizes, 1);
    line-height: nth($app-adz__card__heading__line-height, 1);
    font-weight: $app-adz__card__heading__font-weight;
    font-style: $app-adz__card__heading__font-style;
    &--reversed{
      color: $app-adz__card__text__reversed__color;
    }
    &--black{
      color: #222222;
    }
    &--dark-gray{
      color: #696a6c;
    }
    &--alert{
      color: #b00600;
    }
  }
  &__subhead{
    font-size: nth($app-adz__card__subhead__font-sizes, 1);
    line-height: nth($app-adz__card__subhead__line-height, 1);
    font-weight: $app-adz__card__subhead__font-weight;
    font-style: $app-adz__card__subhead__font-style;
    &--large{
      font-size: nth($app-adz__card__subhead__font-sizes, 1) + rem-calc(3);
    }
    &--reversed {
      color: $app-adz__card__text__reversed__color;
    }
    &--black{
      color: #222222;
    }
    &--dark-gray{
      color: #696a6c;
    }
    &--alert{
      color: #b00600;
    }
  }
  &__alt__heading{
    font-family: $app-adz__card__alt__heading__font-family;
    font-size: nth($app-adz__card__alt__heading__font-sizes, 1);
    line-height: nth($app-adz__card__alt__heading__line-height, 1);
    font-weight: $app-adz__card__alt__heading__font-weight;
    text-shadow: $app-adz__card__alt__heading__text-shadow;
    margin-bottom: $app-grid__column-gutter / 2;
    &--reversed {
      color: $app-adz__card__text__reversed__color;
    }
  }
  &__alt__subhead{
    font-size: nth($app-adz__card__alt__subhead__font-sizes, 1);
    line-height: nth($app-adz__card__alt__subhead__line-height, 1);
    font-weight: $app-adz__card__alt__subhead__font-weight;
    font-style: $app-adz__card__alt__subhead__font-style;
    margin-bottom: $app-grid__column-gutter / 2;
    &--reversed {
      color: $app-adz__card__text__reversed__color;
    }
  }
  &__text--is-reversed{
    color: $app-adz__card__text__reversed__color;
  }
  &__main__heading{
    font-family: $app-adz__card__main__heading__font-family;
    font-size: nth($app-adz__card__main__heading__font-sizes, 1);
    line-height: nth($app-adz__card__main__heading__line-height, 1);
    font-weight: $app-adz__card__main__heading__font-weight;
    font-style: $app-adz__card__main__heading__font-style;
  }
  &__main__subhead{
    font-size: nth($app-adz__card__main__subhead__font-sizes, 1);
    line-height: nth($app-adz__card__main__subhead__line-height, 1);
    font-weight: $app-adz__card__main__subhead__font-weight;
    font-style: $app-adz__card__main__subhead__font-style;
    @at-root .a-dz__card__main__subhead.a-dz__card__main__subhead--light{
      font-weight: $base__font-weight--light;
    }
  }
  &__main__heading,
  &__main__subhead{
    &--blue{
      color: #124a90;
    }
    &--black{
      color: #000000;
    }
  }
  &__body-copy{
    &--large{
    }
  }
  &__note{

  }
  &__main{
    &-home{
      border-bottom: 1px solid #666666;
      &:before{
        content:'';
        display:block;
        box-shadow: inset  0  8px 8px -8px rgba(60, 60, 60, 0.7), inset  0 -8px 8px -8px rgba(60, 60, 60, 0.7);
        position:absolute;
        top:0;
        right:0;
        bottom: 0;
        left: 0;
        width:100%;
        height:100%;
        z-index: 1;
      }
      &__width-constraint{
        max-width: $app-grid__row-width;
        margin: 0 auto;
      }
      &--has-bg-img{
        padding-bottom: $app-grid__column-gutter / 2;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: cover;
      }
      @at-root .dropdown--open .a-dz__card__main-home__width-constraint{
        max-width: $app-adz__card__main-home__max-width;
      }
    }
  }
  &__banner{
    width: 100%;
  }
  &__banner,
  &__group{
    overflow: hidden;
    min-height: rem-calc(175);
    position: relative;
    //border-bottom: 1px solid #666666;
    &:before,
    &:after{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
    }
    &:before{
      box-shadow: inset  0  8px 8px -8px rgba(60, 60, 60, 0.7);
    }
    &:after{
      box-shadow: inset  0 -8px 8px -8px rgba(60, 60, 60, 0.7);
    }
  }
  &__banner--collapse,
  &__group--collapse{
    overflow: visible;
    min-height: 0;
    margin: 0;
    padding: 0;
  }
}

// mmc
.mmc__inline__img{
  //display: inline-block;
}
.a-dz__card__main-home.a-dz__card__main-home__mmc{
  //height: auto;
  padding-bottom: $app-grid__column-gutter / 2;
  background: #dddddd url("/assets/img/a_d_z/main--mostad-mc-2017--bg.png") no-repeat 0 0;
  background-size: cover;
}
.a-dz__card__img__container__mmc{
  max-width: $app-adz__card__main-home__max-width;
  margin-top: 0;
}
.a-dz__card__img__panel__mmc{
  text-align: center;
  max-width: $app-grid__row-width;
  padding: $app-grid__column-gutter / 4;
  margin: auto;
}
.a-dz__card--mmc-white-paper{
  background: url("/assets/img/a_d_z/mostad/mostad-whitepaper-bg.jpg") #ffffff no-repeat top left;
  background-size: cover;
}
.a-dz__card__text__container--mmc-white-paper,
.a-dz__card__subhead--mmc-white-paper{
  background: rgba(255,255,255, .6);
}
// taxvid
/*
.a-dz__card__img__container__main-home__taxvid{
}
.a-dz__card__text__container__main-home__taxvid{
  z-index: 1;
}
.a-dz__card__main__heading__taxvid{
  display: block;
  font-size: rem-calc(40);
  font-family: $base__font-family;
}
.a-dz__card__heading__taxvid__img--free{
  display: block;
  margin: $app-grid__column-gutter / 2  auto 0 auto;
  width: rem-calc(120);
}
.a-dz__card__cta__taxvid{
  @extend .btn;
  margin-top: $app-grid__column-gutter / 2;
  margin-bottom: $app-grid__column-gutter / 2;
  padding: rem-calc(10) 0 rem-calc(10) rem-calc(26);
  border: 1px solid #6f6f6f;
  background: #fb8c00;
  color: #ffffff;
  font-size: rem-calc(25);
  font-weight: $base__font-weight--bold;
  text-transform: uppercase;
  &:hover, &:focus{
    color: #ffffff;
    background: shade(#fb8c00, $base__color-shift);
  }
}
.a-dz__card__text__list__taxvid{
  list-style: none;
  color: #696a6c;
  display: table;
  margin: auto;
}
.a-dz__card__text__list-item__taxvid{
  background: url("../img/a_d_z/taxvid/checkmark.svg");
  background-repeat: no-repeat;
  padding-left: rem-calc(30);
  background-position: 0 rem-calc(6);
}
*/
//integer
.a-dz__card__integer{
  &__heading{
    display: inline-block;
    background: rgba(255,255,255, .25);
    border-radius: $base__border-radius;
    border: 1px solid #979797;
    margin-right: auto;
    margin-left: auto;
    padding: $app-grid__column-gutter / 4 $app-grid__column-gutter / 2;
  }
  &__subhead{
    margin-bottom: $app-grid__column-gutter / 1.5;
  }
}
//1099
.a-dz__card__main-home--1099{
    background-color: #ffffff;
    background-image: url(/assets/img/a_d_z/1099-background-08122020.png), linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
}

/*@include mq(medium-only){
  .a-dz__card {
    &__text__container {
      &--float-at-bottom--medium-only{
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: none;
        background: none;
        box-shadow: none;
        margin-bottom: 0;
      }
    }
  }
}*/

@include mq(medium-only){
  //taxvid
  /*
  .dropdown--open .a-dz__card__text__list__taxvid{
    display: none;
  }
  */
    //1099
    .a-dz__card__main-home--1099{
        background-position: right center, center, center;
    }

}

@include mq(medium-up){
  .a-dz__card__columns{
    display: flex;
    align-items: center;
    justify-content: center;
    @at-root .dropdown--open .a-dz__card__columns{
      &--split-screen{
        padding-left: rem-calc(235);
      }
      &--split-screen.when-dropdown--open--has-full-padding-left{
        padding-left: $app-adz__card__main-home__margin-left;
      }
      &--dropdown-open__shift-right{
        margin-left: $app-adz__card__main-home__margin-left;
      }
    }
  }
  .a-dz__card__column{
    flex: 0 1 auto;
    height: auto;
    &--split-screen{
      display: flex;
      align-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
    }
    &--medium-up--split-screen-left{
      order: 1;
    }
    &--medium-up--split-screen-right{
      order: 2;
    }
  }
  .a-dz{
    &__row {
    }
    &__column{

    }
  }
  .flexbox .a-dz__list__flexbox,
  .flexbox .a-dz__list__1-column {
    display: flex;
  }
  .a-dz__list{
    &-item{
      margin-bottom: 0;
      &__1-column{
        @include grid-column(4, $float: false);
        display: inline-block;
        vertical-align: bottom;
        @at-root .flexbox .a-dz__list-item__1-column {
          display: flex;
        }
      }
      &__3-column{
        @include grid-column(8, $float: false);
        display: inline-block;
        vertical-align: bottom;
        @at-root .flexbox .a-dz__list-item__3-column {
          display: flex;
        }
      }
      &__1-column--large-up,
      &__3-column--large-up{
        margin-bottom: $app-grid__column-gutter;
      }
    }
  }
  .a-dz__card{
    &__img{
      &__container--flex{
        height: 100%;
        display: flex;
      }
      &--flex-end{
          align-self: flex-end;
      }
      &__container{
        &__banner{
          @include grid-column(6, $padding: false);
          position: relative;
        }
        &__main-home{
          margin: 0 auto;
          text-align: right;
          &--max-width{
            max-width: $app-grid__row-width;
          }
        }
      }
      &__banner--floating-right{
        position: absolute;
        top: 0;
        right: 0;
      }
      &__banner--floating-bottom-left{
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &__banner--floating-bottom-right{
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    &__heading__row{
      @at-root .dropdown--open &__main-home{
        margin-left: $app-adz__card__main-home__margin-left;
      }
      &__main-home{
        text-align: center;
      }
    }
    &__text__container{
      @at-root .dropdown--open &__main-home{
        margin-left: $app-adz__card__main-home__margin-left;
      }
      &--center-text-small-only{
        text-align: left;
      }
      &--medium-up--has-text-left{
        text-align: left;
      }
      &__main-home{
        padding: $app-adz__card__text__container__padding;
        background: transparent;
        text-align: left;
        border: none;
        box-shadow: none;
        margin-bottom: -56px;
      }
      &--floating--medium-up,
      &--float-at-bottom--medium-only{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: none;
        background: none;
        box-shadow: none;
      }
      &--floating,
      &--floating--medium-up,
      &--float-at-bottom--medium-only{
        margin-bottom: 0;
      }
      &--float-at-bottom--medium-only{
        top: auto;
      }
      &__banner{
        @include grid-column(6);
        position: static;
        right: auto;
        bottom: auto;
        left: auto;
        background: transparent;
      }
    }
    &__text__panel{
      &__9-columns__medium-up{
        width: grid-calc-width(9,$app-grid__total-columns);
      }
      &__medium-up--max-width-725{
        max-width: rem-calc(725);
      }
    }
    &__cta{
      //float: left;
      &__center--small-only{
        text-align: left;
      }
    }
    &__heading{
      font-size: nth($app-adz__card__heading__font-sizes, 2);
      line-height: nth($app-adz__card__heading__line-height, 2);
    }
    &__subhead{
      font-size: nth($app-adz__card__subhead__font-sizes, 2);
      line-height: nth($app-adz__card__subhead__line-height, 2);
      &--large{
        font-size: nth($app-adz__card__subhead__font-sizes, 2) + rem-calc(3);
      }
    }
    &__alt__heading{
      font-size: nth($app-adz__card__alt__heading__font-sizes, 2);
      line-height: nth($app-adz__card__alt__heading__line-height, 2);
    }
    &__alt__subhead{
      font-size: nth($app-adz__card__alt__subhead__font-sizes, 2);
      line-height: nth($app-adz__card__alt__subhead__line-height, 2);
    }
    &__main__heading{
      font-size: nth($app-adz__card__main__heading__font-sizes, 2);
      line-height: nth($app-adz__card__main__heading__line-height, 2);
    }
    &__main__subhead{
      font-size: nth($app-adz__card__main__subhead__font-sizes, 2);
      line-height: nth($app-adz__card__main__subhead__line-height, 2);
    }
    &__body-copy{
      &--large{
      }
    }
    &__note{

    }
    &__main{
      &-home{
        min-height: rem-calc(625);
        //max-height: rem-calc(650);
        height: rem-calc(605);
        overflow: hidden;
      }
    }
  }

  //mmc
  .a-dz__card__main-home__mmc{
    //min-height: rem-calc(565);
    padding-top: $app-grid__column-gutter / 2;
  }
  .dropdown--open{
    & .a-dz__card__img__panel__mmc{
      margin-left: rem-calc(350);
      max-width: $app-grid__row-width;
    }
  }
  .a-dz__card--mmc-white-paper{
    background-size: auto;
  }
  .a-dz__card__text__container--mmc-white-paper,
  .a-dz__card__subhead--mmc-white-paper{
    background: transparent;
  }
    //1099
    .a-dz__card__main-home--1099{
        background-image: url(/assets/img/a_d_z/1099-background-form-r4-08122020.png), url(/assets/img/a_d_z/1099-background-08122020.png), linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
        background-repeat: no-repeat, repeat, repeat;
        background-size: 83%, auto, auto;
        //background-position: 10px -100px, center, center;
    }
    .a-dz__card__img--1099{
        min-width: 350px;
        max-height: 100%;
    }
    //taxvid
  /*
  @at-root .dropdown--open .a-dz__card__text__container__main-home__taxvid{
    margin-left: rem-calc(325);
  }
  .a-dz__card__img__container__main-home__taxvid{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  @at-root .a-dz__card__cta.a-dz__card__cta__taxvid__spot{
    padding: rem-calc(20) 0 rem-calc(20) rem-calc(16);
  }
  .a-dz__card__text__list__taxvid{
    font-size: rem-calc(18);
    margin-left: 0;
  }
  .a-dz__card__main__heading__taxvid{
    display: inline-block;
    vertical-align: middle;
    font-size: rem-calc(50);
  }
  .a-dz__card__heading__taxvid__img--free{
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto 0 $app-grid__column-gutter / 2;
    width: rem-calc(133);
  }
  */
}

@include mq(medium-alt-up){
  .a-dz{
    &__row {
    }
    &__column{

    }
  }
  .a-dz__list{
    &-item{
      &__1-column{
        //@include grid-column(4, $float: false, $padding: false);
      }
    }
  }
  .a-dz__card{
    &__img{
      &__container{
        //text-align: center;
      }
    }
    &__text__container{
      &__main-home{
        margin-bottom: -101px;
      }
      &--floating--medium-alt-up{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: none;
        background: none;
        box-shadow: none;
      }
      &--floating,
      &--floating--medium-up,
      &--floating--medium-alt-up,
      &--float-at-bottom--medium-only{
        margin-bottom: 0;
      }
    }
    &__cta{
      padding: $app-btn__large__padding;
      font-size: rem-calc(22);//$app-btn__large__font-size;
      &--collapse-right{
        padding-right: rem-calc(10);
      }
    }
    &__heading{
      font-size: nth($app-adz__card__heading__font-sizes, 3);
      line-height: nth($app-adz__card__heading__line-height, 3);
    }
    &__subhead{
      font-size: nth($app-adz__card__subhead__font-sizes, 3);
      line-height: nth($app-adz__card__subhead__line-height, 3);
      &--large{
        font-size: nth($app-adz__card__subhead__font-sizes, 3) + rem-calc(3);
      }
    }
    &__main__heading{
      font-size: nth($app-adz__card__main__heading__font-sizes, 3);
      line-height: nth($app-adz__card__main__heading__line-height, 3);
    }
    &__main__subhead{
      font-size: nth($app-adz__card__main__subhead__font-sizes, 3);
      line-height: nth($app-adz__card__main__subhead__line-height, 3);
    }
    &__copy{

    }
    &__note{

    }
    &__main{
      &-home{

      }
    }
  }
  //taxvid
  /*
  .a-dz__card__cta__taxvid{
    padding: rem-calc(10) 0 rem-calc(10) rem-calc(26);
    font-size: rem-calc(33);
    &:hover, &:focus{
    }
  }
  */
}
//integer
@media #{$app-mq__screen} and ( min-width: rem-calc(640) ) and (max-width: rem-calc(1100) ){
  .a-dz__card__columns{
    &--split-screen,
    &--integer{
      flex-direction: column;
    }
  }
  .a-dz__card__column{
    &--split-screen{
      width: 100%;
      //height: auto;
    }
    &--split-screen-right{
      //height: 100%;
    }
  }
  .a-dz__card__img__container--split-screen-left{
    max-width: rem-calc(770);
    margin: auto;
  }
  .dropdown--open{
    .a-dz__card__columns--integer{
      .a-dz__card__img__container,
      .a-dz__card__text__container{
        &.a-dz__card__img__container--split-screen-left,
        &.a-dz__card__text__container--split-screen-right{
          //width: auto;
          padding-left: rem-calc(235);
          margin: auto;
        }
        &.a-dz__card__img__container--split-screen-left{

        }
      }
    }
  }
}

@include mq(large-up){
  .flexbox .a-dz__list__flexbox--large-up{
    display: flex;
  }
  .a-dz__list{
    &-item{
      &__1-column--large-up{
        @include grid-column(4, $float: false);
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 0;
        @at-root .flexbox .a-dz__list-item__1-column--large-up {
          display: flex;
        }
      }
      &__3-column--large-up{
        @include grid-column(8, $float: false);
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 0;
        @at-root .flexbox .a-dz__list-item__3-column--large-up {
          display: flex;
        }
      }
    }
  }
  //taxvid
  /*
  .a-dz__card__main__heading__taxvid{
    font-size: rem-calc(60);
  }
  .a-dz__card__heading__taxvid__img--free{
    width: rem-calc(147);
  }
  */
  .a-dz__card{
    &__alt__heading{
      font-size: nth($app-adz__card__alt__heading__font-sizes, 3);
      line-height: nth($app-adz__card__alt__heading__line-height, 3);
    }
    &__alt__subhead{
      font-size: nth($app-adz__card__alt__subhead__font-sizes, 3);
      line-height: nth($app-adz__card__alt__subhead__line-height, 3);
    }
    &__text__center{
      &--up-to-large{
        text-align: left;
      }
    }
  }
  //integer
  .dropdown--open{
    .a-dz__card__columns--integer{
      & .a-dz__card__img__container--split-screen,
      & .a-dz__card__text__container--split-screen{
        &.a-dz__card__img__container--split-screen-left,
        &.a-dz__card__text__container--split-screen-left{
          width: auto;
          padding-left: rem-calc(235);
        }
      }
    }
  }
    //1099
    .a-dz__card__main-home--1099{
        background-position: center -100px, center, center;
    }

}
@include mq(xlarge-up){
  .a-dz__card{
    &__text__container{
      &--floating--large-up{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: none;
        background: none;
        box-shadow: none;
        margin-bottom: 0;
      }
    }
    &--dark-blue &__text__remove-highlight__panel--xlarge-up{
      background: none;
      border: none;
      display: block;
      //padding: 0;
    }
    &__body-copy{
      &--large{
        font-size: rem-calc(20);
      }
    }
  }
  //mmc
  .a-dz__card__img__panel__mmc{
    //text-align: right;
  }
}

.a-dz__wisp__slide .a-dz__card__columns {
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  padding: 20px;
}

.a-dz__wisp__slide .a-dz__card__columns--split-screen {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
}

.a-dz__wisp__slide .a-dz__card__column--split-screen-left {
  flex: 0 0 50%;
  padding-left: 20px;
}

.a-dz__wisp__slide .a-dz__card__column--split-screen-right {
  flex: 0 0 50%;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.a-dz__wisp__slide .a-dz__card__img {
  max-width: 56rem;
  height: auto;
}

.a-dz__wisp__slide .a-dz__card__text__panel img{
  max-width: 70%;
  width: 24rem;
  margin-right: 18rem;
}


.a-dz__wisp__slide .a-dz__card__text__container--split-screen-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-left: 15rem;
  padding-top: 0;
}

.a-dz__wisp__slide .a-dz__card__main__heading {
  color: #2c6685;
  font-size: 3.1rem;
  font-family: 'Playfair Display', serif;
  font-weight: 900;
}

.a-dz__wisp__slide .a-dz__card__main__subhead {
  color: #2c6685;
  font-size: 2rem;
  margin-bottom: 30px;
  width: 110%;
  text-align: center;
  font-weight: 100;
}

.a-dz__wisp__slide .a-dz__card__cta {
  display: inline-flex;
  align-items: center;
  background-image: url('/assets/img/a_d_z/slides/button.svg');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  padding: 15px 30px;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  border: none;
  width: 15rem;
  justify-content: space-evenly;
}

.a-dz__wisp__slide .a-dz__card__cta:hover {
  opacity: 0.8;
}

.a-dz__wisp__slide .a-dz__card__column--split-screen-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0;
}

.a-dz__wisp__slide .a-dz__card__img__container--split-screen-right {
  max-width: 56rem;
  width: 56rem;
  text-align: right;
  padding-bottom: 0;
}

.a-dz__wisp__slide .a-dz__card__text__panel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.a-dz__card__main-home.a-dz__wisp__slide {
  background-color: #f6f6f6;
}

@media (max-width: 1500px) {

  .a-dz__wisp__slide .a-dz__card__main__heading {
    font-size: 2.7rem;
  }
  .a-dz__wisp__slide .a-dz__card__main__subhead {
    font-size: 1.5rem;
  }

  .a-dz__wisp__slide .a-dz__card__column--split-screen-right {
    display: none;
  }

  .a-dz__wisp__slide .a-dz__card__text__panel img {
    margin-right: 0;
    width: 21rem;
    max-width: 21rem;
  }

  .a-dz__wisp__slide .a-dz__card__text__container--split-screen-left {
    margin-left: 0;
  }
}

@media (max-width: 1100px) {
  .a-dz__wisp__slide .a-dz__card__columns--split-screen {
    flex-direction: row;
  }

  .a-dz__wisp__slide .a-dz__card__text__container--split-screen-left {
    margin-left: 0;
  }

  .a-dz__wisp__slide .a-dz__card__main__heading {
    font-size: 2.3rem;
  }

  .a-dz__wisp__slide .a-dz__card__main__subhead {
    font-size: 1.5rem;
  }

  .a-dz__wisp__slide .a-dz__card__text__panel img{
    max-width: 20rem;
    width: 20rem;
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .a-dz__wisp__slide .a-dz__card__columns {
    flex-direction: column;
    padding: 10px;
  }

  .a-dz__wisp__slide .a-dz__card__columns--split-screen {
    flex-wrap: wrap;
    gap: 10px;
  }

  .a-dz__wisp__slide .a-dz__card__column--split-screen-left {
    flex: 1 0 100%;
    padding: 0;
    text-align: center; 
  }

  .a-dz__wisp__slide .a-dz__card__column--split-screen-right {
    display: none;
  }

  .a-dz__wisp__slide .a-dz__card__img {
    max-width: 80%; 
    margin: 0 auto; 
  }

  .a-dz__wisp__slide .a-dz__card__text__panel img {
    margin-right: 0;
  }

  .a-dz__wisp__slide .a-dz__card__text__container--split-screen-left {
    margin-left: 0;
    padding: 10px 0; 
    text-align: center; 
  }

  .a-dz__wisp__slide .a-dz__card__main__heading {
    font-size: 24px;
  }

  .a-dz__wisp__slide .a-dz__card__main__subhead {
    font-size: 18px; 
    width: 100%; 
    margin-bottom: 15px; 
  }

  .a-dz__wisp__slide .a-dz__card__cta {
    font-size: 16px; 
    padding: 10px 20px; 
    width: 11rem;
    margin: 0 auto;
  }

  .a-dz__wisp__slide .a-dz__card__img__container--split-screen-right {
    max-width: 90%;
    padding-bottom: 10px;
  }
}

.a-dz__card__main__heading.custom-styling {
  font-size: 3rem;
}

.a-dz__card__main__subhead.custom-styling {
  font-size: 2.3rem;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

$font-family-sans: "Open Sans", sans-serif;
$main-color: #8c1c23;

.tax_update_preview_title {
  margin-bottom: 2rem;
}

.tax_update_preview_title h2{
  font-size: 3.5rem;
  font-family: $font-family-sans;
  font-weight: 700;
  color: $main-color;
  margin-bottom: 0;
}

.tax_update_preview_title h3 {
  font-size: 3rem;
  font-family: $font-family-sans;
  font-weight: 600;
  color: $main-color;
  margin-top: 0;
  line-height: 1.2;
}

.tax_update_preview_content {
  margin-bottom: 6rem;
}

.tax_update_preview_content h4 {
  font-size: 2.8rem;
  font-family: $font-family-sans;
  font-weight: 400;
  color: $main-color;
}

.tax_update_preview_content h6 {
  font-size: 1.5rem;
  font-family: $font-family-sans;
  font-weight: 300;
  color: $main-color;
}

.banner__tax-aid-filing__container {
  background: url('/assets/img/a_d_z/TAFCarousel-BG.jpg');
  justify-content: center;
  display: flex;
}

.banner__tax-aid-filing__container .a-dz__card__columns--max-width {
  max-width: 80rem;
}

.banner__tax-aid-filing__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.banner__tax-aid-filing__row .a-dz__card__column--split-screen {
  width: 30%;
}

.carousel_content {
  margin-bottom: 1rem;
}

.carousel_content h3{
  font-family: $font-family-sans;
  font-weight: 700;
  font-size: 3.1rem;
  color: #3160a5;
}

.carousel_content h6{
  font-family: $font-family-sans;
  font-weight: 300;
  font-size: 1.5rem;
  color: #000;
}

.tax_update_preview_button_bar a img {
  max-width: 55%;
}

.carousel_logo {
  margin-bottom: 2rem;
}

.carousel_logo img{
  max-width: 80%;
}

.banner__tax-update-newsletter__container {
  width: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: auto;
}

.banner__tax-update-newsletter__container .a-dz__card__columns {
  justify-content: start;
}

.banner__tax-update-newsletter__container .a-dz__card__column {
  height: auto; 
  min-width: 50%; 
  margin-top: 1rem;
  width: 63rem !important;
}

.banner__tax-update-newsletter__container .right_container .a-dz__card__img__container{
  width: 75%;
}

@media (max-width: 1500px) {
  .banner__tax-update-newsletter__container .a-dz__card__column {
    width: 29rem;
  }

  .banner__tax-update-newsletter__container a{
    padding: 1rem 1rem;
  }

  .tax_update_preview_title h2{
    font-size: 2.5rem;
  }
  
  .tax_update_preview_title h3 {
    font-size: 2rem;
  }
  
  .tax_update_preview_content {
    margin-bottom: 4rem;
  }
  
  .tax_update_preview_content h4 {
    font-size: 1.8rem;
  }
  
  .tax_update_preview_content h6 {
    font-size: 1rem;
  }

  .banner__tax-aid-filing__row {
    justify-content: space-evenly;
  }
}

@media (max-width: 1100px) {
  .banner__tax-update-newsletter__container .right_container {
    display: none;
  }

  .banner__tax-update-newsletter__container .a-dz__card__columns {
    margin-left: inherit;
    padding-left: inherit;
    margin-top: 2rem;
  }

  .banner__tax-aid-filing__row {
    display: flex;
    flex-direction: row;
  }

  .banner__tax-aid-filing__container .a-dz__card__columns {
    justify-content: center;
  }

  .banner__tax-aid-filing__container .carousel_logo img {
    max-width: 75%;
  }

  .tax_update_preview_button_bar a img {
    max-width: 50%;
  }

  .carousel_content {
    margin-bottom: 2rem;
  }

  .carousel_content h3 {
    font-size: 2.3rem;
  }

  .carousel_content h6 {
    font-size: 1.5rem;
  }
}

@media (max-width: 700px) {
  .banner__tax-aid-filing__container .right-column {
    display: none;
  }

  .banner__tax-update-newsletter__container .a-dz__card__columns, .banner__tax-aid-filing__row {
    margin-top: 7rem;
  }

  .banner__tax-aid-filing__container .carousel_logo img, .tax_update_preview_button_bar a img {
    max-width: 100%;
  }

  .carousel_content h3 {
    font-size: 1.8rem;
  }

  .carousel_content h6 {
    font-size: 1.3rem;
  }

  .banner__tax-aid-filing__row .a-dz__card__column {
    width: auto;
  }
}