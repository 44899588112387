.section{
    &__scrollable {
            max-height: rem-calc(500);
            overflow-y: auto;
            position: relative;
            //box-shadow: inset 0 -8px 8px -8px rgba(0, 0, 0, .5);
            margin-bottom: $app-grid__column-gutter/2;
            //inset shadow for overflow content
            background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)), linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0)), linear-gradient(to bottom, #e4e4e4, rgba(255, 255, 255, 0)), linear-gradient(to top, #e4e4e4, rgba(255, 255, 255, 0));
            background-position: 0 0, 0 100%, 0 0, 0 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 100% 5em, 100% 5em, 100% 1em, 100% 1em;
            background-attachment: local, local, scroll, scroll;
        &--short{
            max-height: rem-calc(250);
        }
    }
}
