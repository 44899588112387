$app-icon__menu__background--color: #6c4212 !default;
$app-icon__menu__background: $app-icon__menu__background--color !default;
$app-icon__menu__stroke-height: rem-calc(2) !default;
$app-icon__menu__stroke-spacing: rem-calc(6) !default;
$app-icon__menu__width: rem-calc(16) !default;

.icon-menu{
  @include icon-hamburger(
    $app-icon__menu__stroke-height,
    $app-icon__menu__width,
    $app-icon__menu__background,
    $app-icon__menu__stroke-spacing
  );
}

/*---------------------------------------------------------------
------>>>    icons  <<<----------------------
------------------------------------------------------------------*/
@font-face {
  font-family: 'tenenzIcons';
  src:url('#{$base__font-path}tv-icons.eot');
  src:url('#{$base__font-path}tv-icons.eot?#iefix') format('embedded-opentype'),
  url('#{$base__font-path}tv-icons.woff') format('woff'),
  url('#{$base__font-path}tv-icons.ttf') format('truetype'),
  url('#{$base__font-path}tv-icons.svg#tenenzIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-more,
.icon-right,
.icon-left,
.icon-up,
.icon-down,
.icon-dashboard,
.icon-users,
.icon-user-info,
.icon-cart,
.icon-reports,
.icon-database,
.icon-trash,
.icon-user,
.icon-home,
.icon-actions,
.icon-envelope,
.icon-action,
.icon-caution,
.icon-file,
.icon-catalog,
.icon-search,
.icon-delete,
.icon-remove,
.icon-wrench,
.icon-edit-page,
.icon-chevron-right,
.icon-chevron-up,
.icon-chevron-down,
.icon-chevron-left,
.icon-orders,
.icon-promotions,
.icon-approval,
.icon-edit,
.icon-start,
.icon-pause,
.icon-power,
.icon-add,
.icon-zoom-out,
.icon-zoom-in,
.icon-required,
.icon-warning,
.icon-success,
.icon-info,
.icon-pdf,
.icon-small-list,
.icon-large-list,
.icon-facebook,
.icon-twitter,
.icon-linkedin,
.icon-filter
{
  @include icon-font-defaults();
}
.icon{
  &--collapse{
    margin-right: -.275em;
    margin-left: -.275em;
  }
  &--collapse-right{
    margin-right: -.275em;
  }
  &--collapse-left{
    margin-left: -.275em;
  }
}
.icon-more:before {
  content: "\e800";
}
.icon-right:before {
  content: "\e01c";
}
.icon-left:before {
  content: "\e001";
}
.icon-up:before {
  content: "\e002";
}
.icon-down:before {
  content: "\e003";
}
.icon-dashboard:before {
  content: "\e004";
}
.icon-users:before {
  content: "\e006";
}
.icon-user-info:before {
  content: "\e007";
}
.icon-cart:before {
  content: "\e008";
}
.icon-reports:before {
  content: "\e009";
}
.icon-database:before {
  content: "\e00a";
}
.icon-trash:before {
  content: "\e00b";
}
.icon-user:before {
  content: "\e00c";
}
.icon-home:before {
  content: "\e00d";
}
.icon-actions:before {
  content: "\e00e";
}
.icon-envelope:before {
  content: "\e00f";
}
.icon-action:before {
  content: "\e010";
}
.icon-caution:before {
  content: "\e011";
}
.icon-file:before {
  content: "\e012";
}
.icon-catalog:before {
  content: "\e013";
}
.icon-search:before {
  content: "\e014";
}
.icon-delete:before {
  content: "\e015";
}
.icon-remove:before {
  content: "\e016";
}
.icon-wrench:before {
  content: "\e018";
}
.icon-edit-page:before {
  content: "\e019";
}
.icon-chevron-right:before {
  content: "\e01a";
}
.icon-chevron-up:before {
  content: "\e027";
}
.icon-chevron-down:before {
  content: "\e028";
}
.icon-chevron-left:before {
  content: "\e01b";
}
.icon-orders:before {
  content: "\e000";
}
.icon-promotions:before {
  content: "\e005";
}
.icon-approval:before {
  content: "\e01d";
}
.icon-edit:before {
  content: "\e017";
}
.icon-start:before {
  content: "\e01e";
}
.icon-pause:before {
  content: "\e01f";
}
.icon-power:before {
  content: "\e020";
}
.icon-add:before {
  content: "\e021";
}
.icon-zoom-out:before {
  content: "\e022";
}
.icon-zoom-in:before {
  content: "\e023";
}
.icon-required:before {
  content: "\e024";
}
.icon-warning:before {
  content: "\e025";
}
.icon-success:before {
  content: "\e026";
}
.icon-info:before {
  content: "\e600";
}
.icon-pdf:before {
  content: "\e601";
}
.icon-small-list:before {
  content: "\e801";
}
.icon-large-list:before {
  content: "\e802";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-filter:before {
  content: "\e902";
}

.icon--success{
  color: $base__success-color;
}
.icon--alert{
  color: $base__alert-color;
}
.icon--required-color{
  color: $base__required-color;
}
