// alphabetize the var listing according to the first segment
// base tangible values colors
$base__color-pallette__lightest-blue: #f0f6fa;
$base__color-pallette__lighter-blue: #adc7e6;
$base__color-pallette__light-blue: #2e9cda;
$base__color-pallette__blue: #0669b2;
$base__color-pallette__dark-blue: #005696;
$base__color-pallette__darker-blue: #00477D;
$base__color-pallette__darkest-blue: #003764;
$base__color-pallette__green: #a1d55d;
$base__color-pallette__ivory: #f6de8c;
$base__color-pallette__lightest-ivory: #fef7f1;
$base__color-pallette__orange: #ff9e18;
$base__color-pallette__warm-brown: #c04d00;
$base__color-pallette__darkest-gray: #222222;
$base__color-pallette__warm-gray: #766e64;
$base__color-pallette__cool-gray: #a9b3b6;
$base__color-pallette__lightest-gray: #f4f5f5;
//base defaults
$base__anchor-color: $base__color-pallette__dark-blue;
$base__light-anchor-color: $base__color-pallette__lighter-blue;
$base__lightest-anchor-color: $base__color-pallette__lightest-blue;
$base__primary-color: $base__color-pallette__dark-blue;
$base__secondary-color: $base__color-pallette__lightest-gray; // #dddddd
$base__secondary-color--dark: #b5b2ad; // #dddddd
$base__alt-blue-color: #1276bc; // #dddddd
$base__alt-teal-color: #19B1A2; // #dddddd
$base__alert-color: #e26a00;
$base__red-color: #902427;
$base__error-color: $base__red-color;
$base__success-color: #8ca884;
$base__alt-success-color: #89b87c;
$base__warning-color: #e6b217;//#daa403;
$base__info-color: #d0c7a3;
$base__required-color: #c2a369;
$base__darkest-gray-color: #363636;
$base__darker-gray-color: #4e4e4e;
$base__dark-gray-color: #666666;
$base__gray-color: #999999;
$base__light-gray-color: #dddddd;
$base__light-gray__border-color: #d8d8d8;
$base__dark-gray__border-color: $base__dark-gray-color;
$base__lighter-gray-color: #E7E7E7;
$base__lightest-gray-color: #f5f5f5;
$base__ivory: #f9f6e4;
$base__white: #ffffff;
$base__icon-color: $base__dark-gray-color;
$base__color-shift: 20%;
$base__assets-path-prefix: "../";
$base__font-path: $base__assets-path-prefix + "fonts/";
$base__body__background-color: $base__white;
$base__google__fonts: null;
$base__font-family: "Open Sans","Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$base__font-family--secondary: "Times New Roman",Times,serif;
$base__font-family--condensed: "Open Sans Condensed", "Open Sans","Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$base__font-color: #000000;
$base__font-weight--thin       : 100;
$base__font-weight--light      : 300;
$base__font-weight--medium     : 400;
$base__font-weight--bold       : 700;
$base__font-weight--ultra-bold : 900;
$base__font-weight: normal;
$base__font-style: normal;
$base__font-line-height: 1.5;
$base__font-size: 16px;
$base__border-radius: 3px;
$base__border-rounded: 1000px;
$base__list__margin-left: rem-calc(17.6);
$base__list__margin-bottom: rem-calc(20);
$base__list__list-style-position: outside;
$base__list-nested-margin: rem-calc(20);
$base__heading__font-family: $base__font-family;
$base__heading__font-weight: $base__font-weight--medium;
$base__heading__font-style: normal;
$base__heading__line-height: 1.25;
$base__heading__color: $base__dark-gray-color;
$base__heading__color--dark: $base__darkest-gray-color;
$base__heading__margin-top: .2rem;
$base__heading__margin-bottom: .5rem;
$base__heading__text-rendering: optimizeLegibility;
$base__small__font-size: 70%;
$base__small__font-color: scale-color($base__heading__color, $lightness: 35%);
$base__paragraph__font-family: inherit;
$base__paragraph__font-weight: $base__font-weight;
$base__paragraph__font-size: 1rem;
$base__paragraph__line-height: 1.45;
$base__paragraph__margin-bottom: rem-calc(20);
$base__paragraph__aside-font-size: rem-calc(14);
$base__paragraph__aside-line-height: 1.3;
$base__paragraph__aside-font-style: italic;
$base__paragraph__text-rendering: optimizeLegibility;
$base__blockquote__font-family: $base__font-family--secondary;
$base__blockquote__font-style: italic;
$base__blockquote__font-weight: $base__font-weight;
$base__blockquote__font-size: rem-calc(20), rem-calc(23), rem-calc(24);
$base__blockquote__font-color: #425866;
$base__blockquote__line-height: 1.25, 1.4, 1.45;
$base__blockquote__margin: rem-calc(35) 0;

//base anchor settings
$base__anchor__font-color: $base__anchor-color;
$base__anchor__text-decoration: none;
$base__anchor__font-color--hover: shade($base__anchor-color, $base__color-shift*2);
$base__anchor__text-decoration--hover: none;

$base__anchor__light__font-color: $base__lightest-anchor-color;
$base__anchor__light__font-color--hover: $base__color-pallette__lighter-blue;

//base animation defaults
$base__transition-speed: 300ms;
$base__transition-ease: ease-out;

//body-content - just in case someone views on a smaller screen than what we test for - they can scroll then
$app-body__content__min-width: rem-calc(320);

// grid
$app-grid__row-width: rem-calc(1280); // 1170
$app-grid__total-columns: 12;
$app-grid__column-gutter: rem-calc(35);

//buttons
$app-btn__border-radius: $base__border-radius;
$app-btn__text-decoration--hover: none;
$app-btn__padding: rem-calc(16) rem-calc(30);
$app-btn__font: inherit;
$app-btn__font-size: rem-calc(16);
$app-btn__transition-speed: $base__transition-speed;
$app-btn__transition-ease: $base__transition-ease;
$app-btn__primary__background-color: $base__primary-color;
$app-btn__primary__font-color: #ffffff;
$app-btn__primary__border-color: shade($app-btn__primary__background-color, $base__color-shift);
$app-btn__primary__border: 1px solid $app-btn__primary__border-color;
$app-btn__primary__background: $app-btn__primary__background-color;
$app-btn__primary__background--hover: shade($app-btn__primary__background-color, $base__color-shift);
$app-btn__primary-outline__font-color: $base__primary-color;
$app-btn__primary-outline__border-color: $base__gray-color;
$app-btn__primary-outline__border: 1px solid $app-btn__primary-outline__border-color;
$app-btn__primary-outline__background--hover: null;
$app-btn__primary-outline__border-color--hover: shade($app-btn__primary-outline__border-color, $base__color-shift);
$app-btn__secondary__background-color: $base__secondary-color;
$app-btn__secondary__font-color: #333333;
$app-btn__secondary__border-color: $base__secondary-color--dark;
$app-btn__secondary__border: 1px solid $app-btn__secondary__border-color;
$app-btn__secondary__background: $app-btn__secondary__background-color;
$app-btn__secondary__background--hover: $base__secondary-color--dark;
$app-btn__secondary-outline__font-color: $app-btn__secondary__font-color;
$app-btn__secondary-outline__border-color: $app-btn__secondary__border-color;
$app-btn__secondary-outline__border: 1px solid $app-btn__secondary__border-color;
$app-btn__secondary-outline__background--hover: null;
$app-btn__secondary-outline__border-color--hover: shade($app-btn__secondary-outline__border-color, $base__color-shift);
$app-btn__alert__background-color: $base__alert-color;
$app-btn__alert__font-color: #ffffff;
$app-btn__alert__border-color: shade($app-btn__alert__background-color, $base__color-shift);
$app-btn__alert__border: 1px solid $app-btn__alert__border-color;
$app-btn__alert__background: shade($app-btn__alert__background-color, $base__color-shift);
$app-btn__alert__background--hover: $app-btn__alert__background-color;
$app-btn__alert-outline__font-color: shade($base__alert-color, $base__color-shift);
$app-btn__alert-outline__font-color--hover: $base__alert-color;
$app-btn__alert-outline__border-color: shade($app-btn__alert__background-color, $base__color-shift);
$app-btn__alert-outline__border: 1px solid $app-btn__alert__border-color;
$app-btn__alert-outline__background--hover: null;
$app-btn__alert-outline__border-color--hover: $base__alert-color;

$app-btn__warning__background-color: $base__warning-color;
$app-btn__warning__font-color: #333333;
$app-btn__warning__border-color: shade($base__warning-color, $base__color-shift);
$app-btn__warning__border: 1px solid $app-btn__warning__border-color;
$app-btn__warning__background: $app-btn__warning__background-color;
$app-btn__warning__background--hover: shade($base__warning-color, $base__color-shift);

$app-btn__alt-blue__background-color: $base__alt-blue-color;
$app-btn__alt-blue__font-color: #ffffff;
$app-btn__alt-blue__border-color: shade($base__alt-blue-color, $base__color-shift);
$app-btn__alt-blue__border: 1px solid $app-btn__alt-blue__border-color;
$app-btn__alt-blue__background: $app-btn__alt-blue__background-color;
$app-btn__alt-blue__background--hover: shade($base__alt-blue-color, $base__color-shift);

$app-btn__large__padding: rem-calc(20) rem-calc(38);
$app-btn__large__font-size: rem-calc(20);
$app-btn__small__padding: rem-calc(7) rem-calc(10) rem-calc(8) rem-calc(10);
$app-btn__small__font-size: rem-calc(16);
$app-btn__tiny__padding: rem-calc(9) rem-calc(15) rem-calc(10) rem-calc(15);
$app-btn__tiny__font-size: rem-calc(12);

//note
$app-note__font-size: rem-calc(13.5);
$app-note__line-height: 1.3;
$app-note__color: #4d4d4d;

//cart
$app-cart__line-item__description__sku-label__color: #333333;
$app-cart__line-item__description__sku-label-light__color: #cdc3ae;
$app-cart__line-item__description__sku-label__padding: rem-calc(4) rem-calc(8) rem-calc(4) 0;
$app-cart__line-item__description__sku-label__font-size: rem-calc(12);
$app-cart__line-item__description__sku-label__font-family: $base__font-family;
$app-cart__line-item__description__sku-label__font-weight: $base__font-weight;
$app-cart__line-item__description__name-small__font-size: rem-calc(13);
$app-cart__line-item__description__name-small__line-height: 1.3;
$app-cart__shipping-summary__list-item__padding: $app-grid__column-gutter / 2;
$app-cart__shipping-summary__list-item__font-size: rem-calc(13);
$app-cart__shipping-summary__list-item__price__font-size: rem-calc(16);
$app-cart__component__heading__margin: rem-calc(4);
$app-cart__component__heading__padding: null;
$app-cart__component__heading__background-color: null;
$app-cart__component__heading__background: $app-cart__component__heading__background-color;
$app-cart__component__heading__border-color: null;
$app-cart__component__heading__border-bottom: null;
$app-cart__component__headline__margin: 0;
$app-cart__component__headline__font-size: rem-calc(18);
$app-cart__component__headline-note__color: $app-note__color;
$app-cart__component__headline-note__font-size: $app-note__font-size;
$app-cart__component__headline-note__font-style: italic;
$app-cart__component__list-item__padding: rem-calc(7);
$app-cart__component__list-item__border-color: #dddddd;
$app-cart__component__list-item__border: 1px solid $app-cart__component__list-item__border-color;
$app-cart__component__list-item__action-column__width: rem-calc(40);
$app-cart__component__list-item__description-column__font-size: rem-calc(14);
$app-cart__component__list-item__description-column__line-height: 1.3;
$app-cart__shipping-options__list-item__border-bottom: 1px solid #dddddd;
$app-cart__shipping-options__list-item__border--selected: 1px solid $base__light-gray__border-color;
$app-cart__shipping-options__list-item__background: null;
$app-cart__shipping-options__list-item__background--selected: #f0f6fa;
$app-cart__shipping-options__list-item__link__padding: rem-calc(10);
$app-cart__shipping-options__list-item__link__border: null;
$app-cart__shipping-options__list-item__link__border--hover: null;
$app-cart__shipping-options__list-item__link__background: null;
$app-cart__shipping-options__list-item__link__background--hover: #f0f6fa;
$app-cart__shipping-options__disclaimer__font-size: $app-note__font-size;
$app-cart__shipping-options__disclaimer__line-height: $app-note__line-height;
$app-cart__shipping-options__disclaimer__color: $app-note__color;

$app-cart-summary__off-canvas__list-item__total__background-color: shade($base__color-pallette__darkest-blue, $base__color-shift * 1.5);
$app-cart-summary__off-canvas__list-item__total__background: $app-cart-summary__off-canvas__list-item__total__background-color;
$app-cart-summary__off-canvas__list-item__total__font-color: $base__info-color;
$app-cart-summary__off-canvas__list-item__total__padding: rem-calc(10) $app-grid__column-gutter/2;
$app-cart-summary__off-canvas__line-item__description__name__font-size: rem-calc(13);
$app-cart-summary__off-canvas__line-item__description__name__line-height: 1.3;
$app-cart-summary__off-canvas__line-item__description__name__color--hover: #aaaaaa;
$app-cart-summary__large__container__background-color: $base__color-pallette__darkest-blue;
$app-cart-summary__large__container__background: $app-cart-summary__large__container__background-color;
$app-cart-summary__large__container__border-color: $base__gray-color;
$app-cart-summary__large__container__border: 1px solid $app-cart-summary__large__container__border-color;
$app-cart-summary__large__container__border-radius: $base__border-radius;
$app-cart-summary__cart-empty__heading__font-color: $app-cart-summary__off-canvas__list-item__total__font-color;

// confirm-it
$app-confirm-it__dialog__background-color: rgba(255,255,255, .8);
$app-confirm-it__dialog__background: $app-confirm-it__dialog__background-color;
$app-confirm-it__dialog__padding: $app-grid__column-gutter / 2;
$app-confirm-it__footer__margin: ($app-grid__column-gutter / 4) auto;
$app-confirm-it__button__padding: rem-calc(8) rem-calc(16);
$app-confirm-it__button__side-margin: $app-grid__column-gutter / 2;

//contact
$app-contact__border-color: #dddddd;
$app-contact__border: 1px solid $app-contact__border-color;

// customer service
$app-customer-service__border-color: #dddddd;
$app-customer-service__border: 1px solid $app-customer-service__border-color;

// forms
$app-form__label__color: #4d4d4d;
$app-form__label__font-size: rem-calc(14);
$app-form__label__line-height: 1.5;
$app-form__label__required__color: $base__required-color;
$app-form__label__required__content: "\e024";
$app-form__input__font-size: rem-calc(18);
$app-form__input__border-color: #cccccc;
$app-form__input__border: 1px solid $app-form__input__border-color;
$app-form__input__border-radius: $base__border-radius;
$app-form__input__border-color--focus: $base__color-pallette__warm-gray;
$app-form__input__border--focus: 1px solid $app-form__input__border-color--focus;
$app-form__input__box-shadow--focus: 0 0 5px rgba(150,150,150, .35);
$app-form__input-buttons__match-text-input__height: rem-calc(42);
$app-form__required-icon__content: "\e024";
$app-form__required-icon__color: #c2a369;
$app-form__input-list-item__margin-bottom: rem-calc(16);
$app-form__error__padding: rem-calc(6) rem-calc(9);
$app-form__error__font-size: rem-calc(12);
$app-form__error__font-weight: normal;
$app-form__error__font-style: italic;
$app-form__error__color: #ffffff;
$app-form__error__background-color: $base__alert-color;
$app-form__error__border-radius: $base__border-radius;

// header-main
$app-header-main__background--color: $base__color-pallette__blue;
$app-header-main__background: $app-header-main__background--color;
$app-header-main__background-gradient: linear-gradient(270deg, tint($app-header-main__background--color, $base__color-shift) 0%, $app-header-main__background--color 100%);
$app-header-main__font-color: #6C4212;
$app-header-main__top_padding: rem-calc(7);
$app-header-main__bottom_padding: rem-calc(3.2);// separated from top so we can use in other calcs for header
$app-header-main__card-depth: 0;
$app-header-main__box-shadow--override: 0 -2px 6px 0 rgba(51, 51, 51, 0.6);
$app-header-main__max-width: rem-calc(2000);
$app-header-main__row__padding: 0;
$app-header-main__row__height: rem-calc(60), rem-calc(72), rem-calc(72);
$app-header-main__logo__column__min-width: rem-calc(225), rem-calc(288), rem-calc(288);
$app-header-main__search__column__border-color: $base__gray-color;
$app-header-main__search__column__border: null; //1px solid $app-header-main__search__column__border-color;
$app-header-main__search__icon__column__min-width: rem-calc(40);
$app-header-main__search__icon__link__color: $base__lightest-anchor-color;
$app-header-main__right-column__padding: null;
$app-header-main__right-column__request-offers__font-size: rem-calc(18);
$app-header-main__right-column__request-offers__font-weight: $base__font-weight--medium;
$app-header-main__right-column__request-offers__line-height: 1.4;
$app-header-main__right-column__phone-number__font-size: rem-calc(23);
$app-header-main__right-column__phone-number__font-weight: $base__font-weight--medium;
$app-header-main__right-column__phone-number__line-height: 1.35;
$app-header-main__right__column__lpg__column__max-width: null, rem-calc(325), rem-calc(325);
$app-header-main__right__column__lpg__column__margin: auto 0 auto auto; //auto 0 auto auto
$app-header-main__right__column__lpg__column__padding: 0; //0 $app-grid__column-gutter / 3 0 0
$app-header-main__right__column__lpg__heading__margin: 0;
$app-header-main__right__column__lpg__img__max-width: null, null, null; //rem-calc(191), rem-calc(400), rem-calc(400)
$app-header-main__cart-column__cart-headline__font-size: rem-calc(23);
$app-header-main__cart-column__cart-headline__line-height: 1.35;
$app-header-main__cart-column__cart-headline__font-weight: $base__font-weight--medium;
$app-header-main__left-column-fixed__width: rem-calc(285);
$app-header-main__nav__row__container__box-shadow: 0 2px 4px -1.5px rgba(0, 0, 0, .24), 0 3px 6px -2px rgba(0, 0, 0, .12);
$app-header-main__nav__row__container__background-color: #ffffff;
$app-header-main__nav__row__container__background: $app-header-main__nav__row__container__background-color;
$app-header-main__nav__row__container__border-color: $base__dark-gray__border-color;
$app-header-main__nav__row__container__border: 1px solid $app-header-main__nav__row__container__border-color;
$app-header-main__nav-list__parent__shadow-depth: 2;
$app-header-main__nav-list__parent__dropdown-triangle__color: $base__darker-gray-color;
$app-header-main__nav-list__parent__border-color: $base__gray-color;
$app-header-main__nav-list__parent__border: 1px solid $app-header-main__nav-list__parent__border-color;
$app-header-main__nav-list__parent__border-radius: $base__border-radius;
$app-header-main__nav-list-item__border-color: $base__light-gray__border-color;
$app-header-main__nav-list-item__border: 1px solid $app-header-main__nav-list-item__border-color;
$app-header-main__nav-list-item__link__color: $base__heading__color;
$app-header-main__nav-list-item__link--dark__color: $base__heading__color--dark;
$app-header-main__product-category-nav__width: rem-calc(275);
$app-header-main__product-category-nav-heading__background: #f5eed3;
$app-header-main__product-category-nav-heading__border-top: 1px solid #ffffff;
$app-header-main__product-category-nav-heading__border-right: 1px solid #e2dbba;
$app-header-main__product-category-nav-heading__border-bottom: null;
$app-header-main__product-category-nav-heading__border-left: 1px solid #d3ccab;
$app-header-main__product-category-nav-heading__border-top-radius: 8px;
$app-header-main__product-category-nav-heading-link__padding: rem-calc(11) $app-grid__column-gutter/3.5 rem-calc(11) $app-grid__column-gutter/2;
$app-header-main__product-category-nav-heading-link__font-size: rem-calc( $base__font-size );
$app-header-main__product-category-nav-heading-link__font-color: null;
$app-header-main__product-category-nav-heading-link__line-height: $base__font-line-height;
$app-header-main__product-category-nav-list__open-class: "dropdown--open";
$app-header-main__product-category-nav-list__border: 1px solid #dddddd;
$app-header-main__product-category-nav-list__top-border-color: #efe8c7;
$app-header-main__product-category-nav-list__background: #ffffff;
$app-header-main__product-category-nav-list-item__border-bottom: 1px solid #dddddd;
$app-header-main__product-category-nav-list-item__background--hover: #f0f6fa;
$app-header-main__product-category-nav-list-item__dropdown__background: #f0f6fa;
$app-header-main__product-category-nav-list-item-link__padding: rem-calc(9) rem-calc(15);
$app-header-main__product-category-nav-list-item-link__font-color: $base__heading__color;
$app-header-main__product-category-nav-list-item-link__background--hover: #dfeaef;
$app-header-main__product-category-nav-list-item-link__icon__color: $base__secondary-color--dark;
$app-header-main__product-category-nav-list-item-link__icon__opacity: .5;
$app-header-main__product-category-nav-list-item-link__description__font-size: rem-calc(13);
$app-header-main__product-category-nav-list-item-link__description__line-height: 1.15;
$app-header-main__product-category-nav-list-item-link__description__font-color: $base__gray-color;
$app-header-main__tagline__font-style: italic;
$app-header-main__tagline__font-weight: $base__font-weight--light;
$app-header-main__tagline__font-size: rem-calc(14), rem-calc(15), rem-calc(17);
$app-header-main__tagline__color: $base__heading__color--dark;
$app-header-main__phone__font-style: normal;
$app-header-main__phone__font-weight: $base__font-weight--bold;
$app-header-main__phone__font-size: rem-calc(14), rem-calc(15), rem-calc(17);
$app-header-main__phone__color: $base__heading__color--dark;

$app-header-main__toolbar__toggle__stroke-size: rem-calc(3);
$app-header-main__toolbar__toggle__stroke-spacing: rem-calc(6);
$app-header-main__toolbar__toggle__width: rem-calc(30);
$app-header-main__toolbar__toggle__color: rgba($base__white, 0.60);
$app-header-main__toolbar__link__color: rgba($base__white, 0.60);
$app-header-main__toolbar__link__color--hover: rgba($base__white, 0.85);

// headings
$app-heading__h1__font-sizes: rem-calc(32), rem-calc(40), rem-calc(46);
$app-heading__h1__line-height: 1.25, 1.15, 1.1;
$app-heading__h1__font-family: $base__font-family--condensed;
$app-heading__h1__font-style: null;
$app-heading__h1__font-weight: $base__font-weight--bold;
$app-heading__h1__border-color: $base__light-gray__border-color;
$app-heading__h1__border: 1px solid $app-heading__h1__border-color;
$app-heading__h1__text-shadow: null; //1px 1px 2px rgba(60,60,60, .60);

$app-heading__h2__font-sizes: rem-calc(26), rem-calc(32), rem-calc(36);
$app-heading__h2__line-height: 1.2, $base__heading__line-height, $base__heading__line-height;
$app-heading__h2__font-style: null;
$app-heading__h2__font-weight: $base__font-weight--light;

$app-heading__h3__font-sizes: rem-calc(22), rem-calc(24), rem-calc(28);
$app-heading__h3__line-height: 1.15, $base__heading__line-height, $base__heading__line-height;
$app-heading__h3__font-style: null;
$app-heading__h3__font-weight: $base__font-weight--light;

$app-heading__h4__font-sizes: rem-calc(19), rem-calc(19), rem-calc(19);
$app-heading__h4__line-height: 1.15, 1.23, 1.23;
$app-heading__h4__font-style: null;
$app-heading__h4__font-weight: null;

$app-heading__h5__font-sizes: rem-calc(17), rem-calc(17), rem-calc(17);
$app-heading__h5__line-height: 1.15, 1.23, 1.23;
$app-heading__h5__font-style: null;
$app-heading__h5__font-weight: null;

$app-heading__h6__font-sizes: 1rem, 1rem, 1rem;
$app-heading__h6__line-height: 1.15, 1.23, 1.23;
$app-heading__h6__font-style: null;
$app-heading__h6__font-weight: null;

$app-is-large-text__font-sizes: rem-calc(17), rem-calc(18), rem-calc(19);
$app-is-large-text__line-height: 1.45, 1.5, 1.55;
$app-is-small-text__font-sizes: rem-calc(13), rem-calc(14), rem-calc(15);
$app-is-small-text__line-height: 1.3, 1.35, 1.4;

$app-is-smaller-text__font-sizes: rem-calc(11), rem-calc(12), rem-calc(13);
$app-is-smaller-text__line-height: 1.3, 1.35, 1.4;

//lists
$app-inline-list__margin: 0 auto rem-calc(17) auto;
$app-inline-list__center__list-item-side__padding: rem-calc(10);
$app-inline-nav-list__margin: 0 auto rem-calc(10) auto;
$app-inline-nav-list-item__border-color: #ebd4ba;
$app-inline-nav-list-item__border-left: 1px solid $app-inline-nav-list-item__border-color;
$app-inline-nav-list-link__padding: rem-calc(5) rem-calc(20);
$app-nav-list-item__border-color: #aaaaaa;
$app-nav-list-item__border-bottom: 1px solid $app-nav-list-item__border-color;
$app-nav-list-link__padding: rem-calc(5);
$app-description-list__term__font-weight: $base__font-weight--bold;
$app-description-list__term__text-decoration: null;
$app-description-list__description__color: #4d4d4d;


//label
$app-label__font-family: $base__font-family;
$app-label__font-size: rem-calc(11);
$app-label__padding: rem-calc(4) rem-calc(8) rem-calc(4);
$app-label__background-color: $base__primary-color;
$app-label__color: #ffffff;
$app-label__border-radius: $base__border-radius;

//notification
$app-notification__item__color: #4b4b4b;
$app-notification__error__heading__color: #964600;
$app-notification__success__heading__color: darken($base__success-color, 15%);

//search-bar
$app-header-main__search__input__background-color: transparent;
$app-header-main__search__input__background: $app-header-main__search__input__background-color;
$app-header-main__search__input__color: $base__white;
$app-header-main__search__input__color--placeholder: $base__white; //#59abde; //#00223e; //#000; //#59ABDE;
$app-header-main__search__input__font-weight: $base__font-weight--light;
$app-header-main__search__input__font-size: rem-calc(20), rem-calc(20), rem-calc(25);
$app-header-main__search__input__font-size--placeholder: rem-calc(14), rem-calc(14), rem-calc(16);

$app-header-main__search__submit-button__background-color: #eee7cc;
$app-header-main__search__submit-button__background: $app-header-main__search__submit-button__background-color;
$app-header-main__search__submit-button__background-color--hover: #986e3d;
$app-header-main__search__submit-button__background--hover: $app-header-main__search__submit-button__background-color--hover;
$app-header-main__search__submit-button__border-color: #ccb59b;
$app-header-main__search__submit-button__border: 1px solid $app-header-main__search__submit-button__border-color;
$app-header-main__search__submit-button__border-radius: $base__border-radius;
$app-header-main__search__submit-button__color: #603609;
$app-header-main__search__submit-button__color--hover: #ffffff;

// icons
$app-icon__menu__background--color: #6c4212;
$app-icon__menu__background: $app-icon__menu__background--color;
$app-icon__menu__stroke-height: rem-calc(2);
$app-icon__menu__stroke-spacing: rem-calc(4);
$app-icon__menu__width: rem-calc(18);

// loader
$app-loading__font-size: rem-calc(22);
$app-loading__font-color: #888888;
$app-loading__font-style: italic;
$app-loading__background-color: #b4b197;
$app-loading__background: $app-loading__background-color;
$app-loading__width: $app-loading__font-size;
$app-loading__height: $app-loading__font-size;
$app-loading__animation-speed: 700ms;
$app-loading__animation-ease: linear;

// dropdown
$app-dropdown__margin: rem-calc(8);
$app-dropdown__background: #ffffff;
$app-dropdown__card-depth: 2;
$app-dropdown__box-shadow--override: null; //0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$app-dropdown__border-color: shade($base__primary-color, $base__color-shift);
$app-dropdown__border: 1px solid $app-dropdown__border-color;
$app-dropdown__border-radius: $base__border-radius;
$app-dropdown__max-width: rem-calc(300);
$app-dropdown__triangle__height: 15px;
$app-dropdown__triangle__color: $app-dropdown__background;
$app-dropdown__triangle__border-width: 1px;
$app-dropdown__triangle__border-color: shade($base__primary-color, $base__color-shift);
//$app-dropdown__triangle__position: $app-dropdown__triangle__height + $app-dropdown__triangle__border-width + $app-dropdown__border-radius;
$app-dropdown__triangle__dark__color: $base__primary-color;
$app-dropdown__triangle__dark__border-color: shade($base__primary-color, $base__color-shift);
$app-dropdown__heading__color: $base__ivory;
$app-dropdown__heading__background-color: $base__primary-color;
$app-dropdown__heading__background: $app-dropdown__heading__background-color;
$app-dropdown__heading__padding: nth($app-heading__h5__font-sizes, 1) / 2;
$app-dropdown__heading__min-height: $app-dropdown__triangle__height * 3;
$app-dropdown__heading__font-size: rem-calc(18);
$app-dropdown__heading__line-height: 1.4;
$app-dropdown__content__margin: $app-grid__column-gutter / 2;
$app-dropdown__content__padding: null;
$app-dropdown__list__font-size: rem-calc(13);
$app-dropdown__list__margin: ($app-grid__column-gutter / 2) 0 ($app-grid__column-gutter / 2) 0;
$app-dropdown__list-item__border-color: #dddddd;
$app-dropdown__list-item__border: 1px solid $app-dropdown__list-item__border-color;
$app-dropdown__list-item__padding: rem-calc(5);
$app-dropdown__toolbar__list-item__padded__padding: rem-calc(5);
$app-dropdown__list-item__link__padding: rem-calc(8);
$app-dropdown__list-item__link__icon-column__width: rem-calc(50);
$app-dropdown__toolbar__list__background-color: $base__primary-color;
$app-dropdown__toolbar__list__background: $app-dropdown__toolbar__list__background-color;
$app-dropdown__toolbar__list-item__border-color: $base__primary-color;
$app-dropdown__toolbar__list-item__border: 1px solid $app-dropdown__toolbar__list-item__border-color;
$app-dropdown__toolbar__list-item__link__padding: rem-calc(13);
$app-dropdown__toolbar__list-item__link__background-color: null;
$app-dropdown__toolbar__list-item__link__background: $app-dropdown__toolbar__list-item__link__background-color;
$app-dropdown__toolbar__list-item__link__background-color--hover: $base__color-pallette__darker-blue;
$app-dropdown__toolbar__list-item__link__background--hover: $app-dropdown__toolbar__list-item__link__background-color--hover;
$app-dropdown__toolbar__list-item__link__color--hover: #ffffff;
$app-dropdown__toolbar__list-item__link__color: #eee7cc;
$app-dropdown__toolbar__list-item__link__color--hover: #ffffff;

// preheader
$app-pre-header__min-height: rem-calc(35);
$app-pre-header__background: $base__primary-color;
$app-pre-header__box-shadow: 0 -2px 4px -1.5px rgba(0, 0, 0, .14), 0 -3px 6px -2px rgba(0, 0, 0, .08);
$app-pre-header__border-color: $base__color-pallette__darker-blue;
$app-pre-header__border: 1px solid $app-pre-header__border-color;
$app-pre-header__padding: rem-calc(6) rem-calc(17);
$app-pre-header__tagline__font-size: rem-calc(13), rem-calc(13), rem-calc(13);
$app-pre-header__tagline__line-height: 1.2, 1.2, 1.2;
$app-pre-header__tagline__font-style: $app-header-main__tagline__font-style;
$app-pre-header__tagline__font-weight: $app-header-main__tagline__font-weight;
$app-pre-header__tagline__color: $app-header-main__tagline__color;
$app-pre-header__tagline__opacity: .5;
$app-pre-header__tagline__padding: 0 rem-calc(10) 0 0;
$app-pre-header__lpg__column__width: null;
$app-pre-header__lpg__column__max-width: rem-calc(250);

//address book
$app-address-book__list-item__border-bottom: 1px solid #dddddd;
$app-address-book__list-link__background: null;
$app-address-book__list-link__background--hover: $base__secondary-color;
$app-address-book__list-link__padding: rem-calc(10);
$app-address-book__list-link__add-address__font-color: #ffffff;
$app-address-book__list-link__add-address__background: $base__primary-color;
$app-address-book__list-link__add-address__background--hover: null;
$app-address-book__list-link__remove-address__font-color: shade($base__alert-color, $base__color-shift);
$app-address-book__list-link__remove-address__font-color--hover: #ffffff;
$app-address-book__list-link__remove-address__background-color: null;
$app-address-book__list-link__remove-address__background: $app-address-book__list-link__remove-address__background-color;
$app-address-book__list-link__remove-address__background--hover: $base__alert-color;
$app-address-book__dropdown__margin: 0;
$app-address-book__dropdown-triangle__height: 10px;
$app-address-book__dropdown-triangle__color: $app-address-book__list-link__add-address__background;

//breadcrumbs
$app-breadcrumbs__row__max-width: $app-header-main__max-width;
$app-breadcrumbs__row__margin: 0 auto;
$app-breadcrumbs__crumb__padding: rem-calc(9) rem-calc(14) rem-calc(9);
$app-breadcrumbs__crumb__border-color: $base__light-gray__border-color;
$app-breadcrumbs__crumb__border: 1px solid $app-breadcrumbs__crumb__border-color;
$app-breadcrumbs__crumb__background-color: #f0f6fa;
$app-breadcrumbs__crumb__background: $app-breadcrumbs__crumb__background-color;
$app-breadcrumbs__crumb__font-size: rem-calc(12);
$app-breadcrumbs__crumb__text-transform: uppercase;
$app-breadcrumbs__crumb__font-color: $base__primary-color;
$app-breadcrumbs__crumb__font-color--current: #444444;
$app-breadcrumbs__crumb__text-decoration--hover: none;
$app-breadcrumbs__crumb-slash__content: "/";
$app-breadcrumbs__crumb-slash__color: #aaaaaa;
$app-breadcrumbs__crumb-dropdown__border-right-color: #c8c8c8;
$app-breadcrumbs__crumb-dropdown__border-right: 1px solid $app-breadcrumbs__crumb-dropdown__border-right-color;
$app-breadcrumbs__crumb-dropdown__border-bottom-color: #999999;
$app-breadcrumbs__crumb-dropdown__border-bottom: 1px solid $app-breadcrumbs__crumb-dropdown__border-bottom-color;
$app-breadcrumbs__crumb-dropdown__border-left-color: #c8c8c8;
$app-breadcrumbs__crumb-dropdown__border-left: 1px solid $app-breadcrumbs__crumb-dropdown__border-left-color;
$app-breadcrumbs__crumb-dropdown-item__border-bottom-color: #f9f9f9;
$app-breadcrumbs__crumb-dropdown-item__border-bottom: 1px solid $app-breadcrumbs__crumb-dropdown-item__border-bottom-color;
$app-breadcrumbs__crumb-dropdown-item-link__border-bottom-color: $base__light-gray__border-color;
$app-breadcrumbs__crumb-dropdown-item-link__border-bottom: 1px solid $app-breadcrumbs__crumb-dropdown-item-link__border-bottom-color;
$app-breadcrumbs__crumb-dropdown-item-link__color: #444444;

//pagination
$app-pagination__list-item__color: #222222;
$app-pagination__list-item__font-size: rem-calc(14);
$app-pagination__list-item__margin-left: rem-calc(5);
$app-pagination__link__padding: rem-calc(1) rem-calc(10);
$app-pagination__link__color: #999999;
$app-pagination__link__font-size: $app-pagination__list-item__font-size;
$app-pagination__link__cursor: pointer;
$app-pagination__link__background-color: transparent;
$app-pagination__link__background-color--hover: #e6e6e6;
$app-pagination__link-current__color: #ffffff;
$app-pagination__link-current__background-color: $base__primary-color;
$app-pagination__link-current__background-color--hover: shade($app-pagination__link-current__background-color, $base__color-shift);
$app-pagination__link-disabled__background-color--hover: transparent;

// product
$app-product__qty-calculated__font-style: italic;
$app-product__qty-calculated__font-weight: 100;
$app-product__qty-calculated__color: #888888;


//product list
$app-product-list__display-choice-item__border-right: 1px solid #999999;
$app-product-list__display-choice-button__background-color: #ffffff;
$app-product-list__display-choice-button__background-color--hover: #f0f6fa;
$app-product-list__display-choice-button__border: 1px solid $base__light-gray__border-color;
$app-product-list__display-choice-button__border--selected: 1px solid #999999;
$app-product-list__display-choice-button__padding: rem-calc(4) rem-calc(4.8) rem-calc(8) rem-calc(4.8);
$app-product-list__product-description__font-size: rem-calc(15);
$app-product-list__product-description__line-height: 1.4;
$app-product-list__product-description__headline__font-size: null;
$app-product-list__product-description__headline__font-weight: bold;
$app-product-list__product-description__headline-sku__font-size: rem-calc(14);
$app-product-list__product-description__headline-sku__font-weight: normal;
$app-product-list__large__product-list-item__border-bottom: 1px solid #999999;
$app-product-list__small__product-list__product-information__border-top: 1px solid #dddddd;
$app-product-list__small__product-list__product-information__font-weight: bold;
$app-product-list__small__product-list__product-detail-link__font-size: rem-calc(13);
$app-product-list__small__product-list__product-detail-link__font-weight: bold;
$app-product-list__small__product-list__product-detail-link__line-height: 1.3;
$app-product-list__small__product-list__product-detail-sku__font-size: rem-calc(12);
$app-product-list__small__product-list__product-detail-sku__font-weight: normal;
$app-product-list__product-preview__bottom-arrow__height: 30px;
$app-product-list__product-preview__bottom-arrow__border-width: 2px;
$app-product-list__product-preview__bottom-arrow__border-color: #b0c2cf;
$app-product-list__product-preview__bottom-arrow__background-color: #f0f6fa;
$app-product-list__product-preview__product-image__link__padding: rem-calc(16);
$app-product-list__product-preview__product-image__link__border: 1px solid #b9b9b9;
$app-product-list__product-preview__product-image__link__border-radius: $base__border-radius;
$app-product-list__product-preview__product-image__link__background-color: #ffffff;
$app-product-list__product-preview-expander__content__padding: rem-calc(10) rem-calc(30);
$app-product-list__product-preview-expander__content__max-width: $app-grid__row-width;
$app-product-list__product-preview-expander__content__background-color: #f0f6fa;
$app-product-list__product-preview-expander__content__border-top-color: $base__light-gray__border-color;
$app-product-list__product-preview-expander__content__border-top: 2px solid $app-product-list__product-preview-expander__content__border-top-color;
$app-product-list__product-preview-expander__content__border-bottom-color: #C7C7C7;
$app-product-list__product-preview-expander__content__border-bottom: 1px solid $app-product-list__product-preview-expander__content__border-bottom-color;
$app-product-list__product-preview__action-button__color: $base__secondary-color--dark;
$app-product-list__product-preview__action-button__color--hover: #588bae;
$app-product-list__product-preview__action-button__background: null;
$app-product-list__product-preview__action-button__background--hover: #e5eff9;
$app-product-list__product-preview__action-button-disabled__color: #cccccc;
$app-product-list__product-preview__action-button-disabled__color--hover: #cccccc;
$app-product-list__product-preview__action-button-close__font-size: rem-calc(16);
$app-product-list__product-preview__action-button-close__padding-left: rem-calc(5.6);
$app-product-list__product-preview__action-button-close__padding-right: rem-calc(7.2);
$app-product-list__product-preview__action-button-close__large__font-size: rem-calc(20);
$app-product-list__product-preview__action-button-close__large__padding-left: rem-calc(25);
$app-product-list__product-preview__action-button-close__large__padding-right: rem-calc(25);
$app-product-list__product-preview__action-button-icon__font-size: rem-calc(96);
$app-product-list__product-preview__action-button-icon__width: rem-calc(22.4);
$app-product-list__product-preview__action-button-icon__left: - rem-calc(40);
$app-product-list__product-preview__action-button-icon__large__font-size: rem-calc(128);
$app-product-list__product-preview__action-button-icon__large__width: rem-calc(64);
$app-product-list__product-preview__action-button-icon__large__left: - rem-calc(38);
$app-product-list__product-preview__action-button__add-to-cart__background-color: $base__secondary-color--dark;
$app-product-list__product-preview__action-button__add-to-cart__background-color--hover: #588bae;
$app-product-list__product-preview__action-button__add-to-cart__border-color: #588bae;
$app-product-list__product-preview__action-button__add-to-cart__border: 1px solid $app-product-list__product-preview__action-button__add-to-cart__border-color;

//product category
$app-product-category__category__tabs__inline-list__box-shadow: 0 4px 5px -3px rgba(0, 0, 0, .4);
$app-product-category__category__tabs__inline-list__border-color: $base__primary-color;
$app-product-category__category__tabs__inline-list__border: 1px solid $app-product-category__category__tabs__inline-list__border-color;
$app-product-category__category__tabs__inline-list-item__border-color: $base__primary-color;
$app-product-category__category__tabs__inline-list-item__border: 1px solid $app-product-category__category__tabs__inline-list-item__border-color;
$app-product-category__category__tabs__inline-list-item__small-only__border-bottom-color: #dddddd;
$app-product-category__category__tabs__inline-list-item__small-only__border-bottom: 1px solid $app-product-category__category__tabs__inline-list-item__small-only__border-bottom-color;
$app-product-category__category__tabs__inline-list-item__link__border-color: null;
$app-product-category__category__tabs__inline-list-item__link__border: $app-product-category__category__tabs__inline-list-item__link__border-color;
$app-product-category__category__tabs__inline-list-item__link__border-radius: $base__border-radius;
$app-product-category__category__tabs__inline-list-item__link__background-color: null;
$app-product-category__category__tabs__inline-list-item__link__background: $app-product-category__category__tabs__inline-list-item__link__background-color;
$app-product-category__category__tabs__inline-list-item__link__background-color--hover: null;
$app-product-category__category__tabs__inline-list-item__link__background--hover: $app-product-category__category__tabs__inline-list-item__link__background-color--hover;
$app-product-category__category__tabs__inline-list-item__link__font-color: null;
$app-product-category__category__tabs__inline-list-item__link__font-color--hover:  shade($base__primary-color, $base__color-shift);
$app-product-category__category__tabs__inline-list-item__link-selected__background-color: $base__primary-color;
$app-product-category__category__tabs__inline-list-item__link-selected__background: $app-product-category__category__tabs__inline-list-item__link-selected__background-color;
$app-product-category__category__tabs__inline-list-item__link-selected__background-color--hover: shade($base__primary-color, $base__color-shift);
$app-product-category__category__tabs__inline-list-item__link-selected__background--hover: $app-product-category__category__tabs__inline-list-item__link-selected__background-color--hover;
$app-product-category__category__tabs__inline-list-item__link-selected__font-color: #ffffff;
$app-product-category__category__tabs__inline-list-item__link-selected__font-color--hover: #ffffff;
$app-product-category__category__sub-category__tabs__list__background-color: $base__secondary-color;
$app-product-category__category__sub-category__tabs__list__background: $app-product-category__category__sub-category__tabs__list__background-color;
$app-product-category__category__sub-category__tabs__list-item__border-color: #dddddd;
$app-product-category__category__sub-category__tabs__list-item__border-bottom: 1px solid $app-product-category__category__sub-category__tabs__list-item__border-color;
$app-product-category__category__sub-category__tabs__list-item__border-right: 1px solid shade($app-product-category__category__sub-category__tabs__list-item__border-color, $base__color-shift);
$app-product-category__category__sub-category__tabs__list-item-selected__background-color: #9eb0bd;
$app-product-category__category__sub-category__tabs__list-item-selected__background: $app-product-category__category__sub-category__tabs__list-item-selected__background-color;
$app-product-category__category__sub-category__tabs__list-item-selected__medium-up__background-color: #ffffff;
$app-product-category__category__sub-category__tabs__list-item-selected__medium-up__background: $app-product-category__category__sub-category__tabs__list-item-selected__medium-up__background-color;
$app-product-category__category__sub-category__tabs__list-item__link__padding: ($app-grid__column-gutter / 6) ($app-grid__column-gutter);
$app-product-category__category__sub-category__tabs__list-item__link__medium-up__padding: ($app-grid__column-gutter / 3) ($app-grid__column-gutter);
$app-product-category__category__sub-category__tabs__list-item__link__small__font-size: rem-calc(14);
$app-product-category__category__sub-category__tabs__list-item__link__medium-up__font-size: rem-calc(16);
$app-product-category__category__sub-category__tabs__list-item__link__font-color: #444444;
$app-product-category__category__sub-category__tabs__list-item-selected__link__font-color: #000000;
$app-product-category__category__sub-category__tabs__list-item-selected__link__medium-up__font-color: $base__primary-color;
$app-product-category__category__sub-category__tabs__list-item__link__background-color--hover: #dfeaef;
$app-product-category__category__sub-category__tabs__list-item__link__background--hover: $app-product-category__category__sub-category__tabs__list-item__link__background-color--hover;

$app-product-category__vertical-theme__sub-categories__list-item__border-color: #dddddd;
$app-product-category__vertical-theme__sub-categories__list-item__border: 1px solid $app-product-category__vertical-theme__sub-categories__list-item__border-color;

$app-product-category__slipsheet-theme__blue-gradient__background-color: #528096; //#617c89;
$app-product-category__slipsheet-theme__blue-gradient__background: linear-gradient(to bottom, $app-product-category__slipsheet-theme__blue-gradient__background-color 0%,#ffffff 80%);
$app-product-category__slipsheet-theme__brown-gradient__background-color: #896f61;
$app-product-category__slipsheet-theme__brown-gradient__background: linear-gradient(to bottom, $app-product-category__slipsheet-theme__brown-gradient__background-color 0%,#ffffff 80%);
$app-product-category__slipsheet-theme__green-gradient__background-color: #67846d;
$app-product-category__slipsheet-theme__green-gradient__background: linear-gradient(to bottom, $app-product-category__slipsheet-theme__green-gradient__background-color 0%,#ffffff 80%);
$app-product-category__slipsheet-theme__gray-gradient__background-color: #8a8a8a;
$app-product-category__slipsheet-theme__gray-gradient__background: linear-gradient(to bottom, $app-product-category__slipsheet-theme__gray-gradient__background-color 0%,#ffffff 80%);

$app-product-category__slipsheet-theme__product-category__header__text-container__text-align: center;
$app-product-category__slipsheet-theme__product-category__header__headline__font-color: null;
$app-product-category__slipsheet-theme__product-category__header__headline__large-up__font-color: #ffffff;
$app-product-category__slipsheet-theme__product-category__header__headline__text-align: null;
$app-product-category__slipsheet-theme__product-category__header__headline__text-shadow: null;
$app-product-category__slipsheet-theme__product-category__header__headline__large-up__text-shadow: 1px 1px 2px #444;
$app-product-category__slipsheet-theme__product-category__header__headline__border-bottom: none;
$app-product-category__slipsheet-theme__product-category__header__subhead__font-size: rem-calc(29);

$app-product-category__sub-category-rows__list-item__child-category__subhead__font-size: rem-calc(16);
$app-product-category__sub-category-rows__list-item__child-category__subhead__font-weight: $base__font-weight--light;
$app-product-category__sub-category-rows__list-item__child-category__subhead__font-color: $base__font-color;
$app-product-category__sub-category-rows__list-item__child-category__link__background: $base__secondary-color;
$app-product-category__sub-category-rows__list-item__child-category__link__border-color: $base__light-gray__border-color;
$app-product-category__sub-category-rows__list-item__child-category__link__border: 1px solid $app-product-category__sub-category-rows__list-item__child-category__link__border-color;
$app-product-category__sub-category-rows__list-item__child-category__link__border-radius: $base__border-radius;
$app-product-category__sub-category-rows__list-item__child-category__link__padding: $app-grid__column-gutter / 4;

$app-product-category__sub-categories__list-item__container__border-color: $base__light-gray__border-color;
$app-product-category__sub-categories__list-item__container__border-color--hover: darken($app-product-category__sub-categories__list-item__container__border-color, 13%);
$app-product-category__sub-categories__list-item__container__border: 1px solid $app-product-category__sub-categories__list-item__container__border-color;
$app-product-category__sub-categories__list-item__container__border-radius: 5px;

//product detail
$app-product-detail__product-display__thumbnail__column__width: rem-calc(80);
$app-product-detail__product-display__thumbnail__initial__max-height: rem-calc(400);
$app-product-detail__product-display__thumbnail__border-color: $base__light-gray__border-color;
$app-product-detail__product-display__thumbnail__border: 1px solid $app-product-detail__product-display__thumbnail__border-color;
$app-product-detail__product-display__thumbnail__border-radius: $base__border-radius;


//product options
$app-product-options__child-descriptions__border-color: $base__secondary-color--dark;
$app-product-options__child-descriptions__border: 1px solid $app-product-options__child-descriptions__border-color;
$app-product-options__child-descriptions__background-color: #f0f6fa;
$app-product-options__child-descriptions__background: $app-product-options__child-descriptions__background-color;
$app-product-options__child-descriptions__padding: rem-calc(16);
$app-product-options__child-descriptions__height: rem-calc(288);
$app-product-options__reveal-img__list__background-color: #ffffff; //f8f8f8
$app-product-options__reveal-img__list__border-color: #cccccc;
$app-product-options__reveal-img__list__border: 1px solid $app-product-options__reveal-img__list__border-color;
$app-product-options__reveal-img__list__padding: rem-calc(16);


//product pricing
$app-product-pricing__border-color: #999999;
$app-product-pricing__border: 1px solid $app-product-pricing__border-color;
$app-product-pricing__font-size: rem-calc(14);
$app-product-pricing__qty__background: null;
$app-product-pricing__price__background: null;
$app-product-pricing__price__font-weight: bold;
$app-product-pricing__title__background: #f0f6fa;
$app-product-pricing__notes__font-size: rem-calc(14);
$app-product-pricing__notes__color: #444444;
$app-product-pricing__title__product-preview__background: transparent;
$app-product-pricing__title__product-preview__border-color: $base__secondary-color--dark;
$app-product-pricing__title__product-preview__border: 1px solid $app-product-pricing__title__product-preview__border-color;

//product recommendations
$app-product-recommendations__border-color: $base__light-gray__border-color;
$app-product-recommendations__border: 1px solid $app-product-recommendations__border-color;
$app-product-recommendations__headline__background: #f0f6fa;
$app-product-recommendations__headline__border-color: $base__light-gray__border-color;
$app-product-recommendations__headline__border: 1px solid $app-product-recommendations__headline__border-color;
$app-product-recommendations__headline__padding: rem-calc(8);
$app-product-recommendations__headline__font-size: rem-calc(20);
$app-product-recommendations__headline__font-color: #444444;
$app-product-recommendations__list-item__margin: 0 0 $app-grid__column-gutter/2 0;
$app-product-recommendations__title__margin: rem-calc(5) 0;
$app-product-recommendations__title__padding: rem-calc(5) 0 0 0;
$app-product-recommendations__title__border-top-color: #dddddd;
$app-product-recommendations__title__border-top: 1px solid $app-product-recommendations__title__border-top-color;
$app-product-recommendations__product-detail-link__font-size: rem-calc(13);
$app-product-recommendations__product-detail-link__font-weight: bold;
$app-product-recommendations__product-detail-link__line-height: 1.3;
$app-product-recommendations__product-detail-link__sku-label__font-size: rem-calc(12);
$app-product-recommendations__product-detail-link__sku-label__font-weight: normal;

//product images
$app-product-images__list-item__caption__padding: $app-grid__column-gutter / 2;
$app-product-images__list-item__caption__text-align: center;
$app-product-images__list-item__caption__background: rgba(255,255,255, .75);
$app-product-images__list-item__caption__font-size: rem-calc(17);

// slides
$app-slides__btn__border-color: $base__secondary-color--dark;
$app-slides__btn__border: 1px solid $app-slides__btn__border-color;
$app-slides__btn__border-radius: $base__border-radius;
$app-slides__btn__padding: rem-calc(3);
$app-slides__btn__color: $base__darker-gray-color;
$app-slides__btn__color--hover: shade( $base__darker-gray-color, $base__color-shift);
$app-slides__btn__background: #f0f6fa;
$app-slides__btn__background--hover: $base__secondary-color--dark;
$app-slides__transition-speed: 1000ms;
$app-slides__transition-ease: ease-in-out;
$app-slides__thumbnails__list-item__margin: rem-calc(8);
$app-slides__thumbnails__list-item__padding: rem-calc(4);
$app-slides__thumbnails__list-item__border-color: #ffffff;
$app-slides__thumbnails__list-item__border: 1px solid $app-slides__thumbnails__list-item__border-color;
$app-slides__thumbnails__list-item__border-color--active: $base__secondary-color--dark;
$app-slides__thumbnails__list-item__border--active: 1px solid $app-slides__thumbnails__list-item__border-color--active;
$app-slides__thumbnails__list-item__border-radius: $base__border-radius;
$app-slides__thumbnails__list-item__shadow-depth: 1;

//tables
$app-table__border-color: #dddddd;
$app-table__border: 1px solid $app-table__border-color;
$app-table__margin-bottom: rem-calc(20);
$app-table__row__padding: rem-calc(9) rem-calc(10);
$app-table__row__font-size: rem-calc(14);
$app-table__row__color: #333333;
$app-table__row__line-height: 1.3;
$app-table__row-even__background-color: #f9f9f9;
$app-table__row-even__background: $app-table__row-even__background-color;
$app-table__thead__background-color: #dddddd;
$app-table__thead__background: $app-table__thead__background-color;
$app-table__thead__font-size: rem-calc(14);
$app-table__thead__font-color: #333333;
$app-table__thead__font-weight: bold;
$app-table__thead__padding: rem-calc(8) rem-calc(10) rem-calc(10);

//testimonial
$app-testimonial__quote__font-family: null;
$app-testimonial__quote__font-style: $base__blockquote__font-style;
$app-testimonial__quote__font-weight: $base__font-weight--light;
$app-testimonial__quote__font-size: $base__blockquote__font-size;
$app-testimonial__quote__font-weight: $base__font-weight--light;
$app-testimonial__quote__line-height: $base__blockquote__line-height;
$app-testimonial__quote__color: $base__blockquote__font-color;
$app-testimonial__quote__border-left-color: $base__blockquote__font-color;
$app-testimonial__quote__border-left: null;//1px solid $app-testimonial__quote__border-left-color;
$app-testimonial__quote__padding-left: $app-grid__column-gutter / 2;
$app-testimonial__quote__margin: $app-grid__column-gutter / 2 0;
$app-testimonial__quote__mark__font-family: $base__font-family--secondary;
$app-testimonial__quote__mark__font-weight: $base__font-weight--bold;
$app-testimonial__quote__mark__font-style: italic;
$app-testimonial__quote__mark__font-size: 1.5em;
$app-testimonial__quote__mark__line-height: 0;
$app-testimonial__author__color: $base__blockquote__font-color;

// footer
$app-footer-main__padding: $app-grid__column-gutter / 2;
$app-footer-main__background-color: $base__color-pallette__blue;
$app-footer-main__background: $app-footer-main__background-color;
$app-footer-main__background-gradient: linear-gradient(0deg, $app-footer-main__background-color 0%, tint($app-footer-main__background-color, $base__color-shift) 100%);
$app-footer-main__border-top: 1px solid $base__color-pallette__darkest-blue;
$app-footer-main__company-info__border-left--small: 1px solid $base__color-pallette__darkest-blue;
$app-footer-main__company-info__list-item__color: #bed2e6;
$app-footer-main__resources-nav__list-link__opacity: null;
$app-footer-main__resources-nav__list-link__color: $base__white;
$app-footer-main__resources-nav__list-link__opacity--hover: 0.65;
$app-footer-main__resources-nav__list-link__color--hover: $base__white;
$footer__main-content__social-nav__list-item__color: $app-footer-main__company-info__list-item__color;

// media queries
$app-mq__screen: "only screen";

$app-mq__breakpoints: (
        "small": em-calc(640),
        "medium": em-calc(1024),
        "medium-alt": em-calc(880),
        "large": em-calc(1360),
);

$app-mq__small-up: #{$app-mq__screen};
$app-mq__small-only: "#{$app-mq__screen} and ( max-width: #{map-get($app-mq__breakpoints, "small")} )";
$app-mq__medium-up: "#{$app-mq__screen} and ( min-width: #{map-get($app-mq__breakpoints, "small")} )";
$app-mq__medium-only: "#{$app-mq__screen} and ( min-width: #{map-get($app-mq__breakpoints, "small")} ) and (max-width: #{(map-get($app-mq__breakpoints, "medium") - em-calc(1))} )";
$app-mq__medium-alt-up: "#{$app-mq__screen} and ( min-width: #{map-get($app-mq__breakpoints, "medium-alt")} )";
$app-mq__large-up: "#{$app-mq__screen} and ( min-width: #{map-get($app-mq__breakpoints, "medium")} )";
$app-mq__xlarge-up: "#{$app-mq__screen} and ( min-width: #{map-get($app-mq__breakpoints, "large")} )";
$app-mq__landscape: "#{$app-mq__screen} and ( orientation: landscape )";
$app-mq__portrait: "#{$app-mq__screen} and ( orientation: landscape )";

$app-mq__queries: (
        "small-up":  $app-mq__small-up,
        "small-only": $app-mq__small-only,
        "medium-up": $app-mq__medium-up,
        "medium-only": $app-mq__medium-only,
        "medium-alt-up": $app-mq__medium-alt-up,
        "large-up":  $app-mq__large-up,
        "xlarge-up":  $app-mq__xlarge-up,
        "landscape": $app-mq__landscape,
        "portrait":  $app-mq__portrait
);

//off canvas
$app-off-canvas__container__open__position: fixed;
$app-off-canvas__container__open__z-index: 999;
$app-off-canvas__menu__width: 75%;
$app-off-canvas__container-overlay__background-color: #fff;
$app-off-canvas__container-overlay__background-opacity: .65;
$app-off-canvas__container-overlay__box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
$app-off-canvas__container-overlay__z-index: 998;
$app-off-canvas__menu__content__background-color: $base__color-pallette__blue;
$app-off-canvas__menu__content__background: $app-off-canvas__menu__content__background-color;
$app-off-canvas__menu__content__list-item__padding: rem-calc(10) $app-grid__column-gutter/2;
$app-off-canvas__menu__content__list-item__border-bottom-color: #222222;
$app-off-canvas__menu__content__list-item__border-bottom: 1px solid $app-off-canvas__menu__content__list-item__border-bottom-color;
$app-off-canvas__menu__content__list-item__label__background-color: shade($base__color-pallette__darkest-blue, $base__color-shift * 1.5);
$app-off-canvas__menu__content__list-item__label__background: $app-off-canvas__menu__content__list-item__label__background-color;
$app-off-canvas__menu__content__list-item__label__border-top-color: $base__color-pallette__darker-blue;
$app-off-canvas__menu__content__list-item__label__border-top: 1px solid $app-off-canvas__menu__content__list-item__label__border-top-color;
$app-off-canvas__menu__content__list-item__label__color: $base__gray-color;
$app-off-canvas__menu__content__list-item__label__text-transform: uppercase;
$app-off-canvas__menu__content__list-item__label__font-weight: null;
$app-off-canvas__menu__content__list-item__label-link__background-color: $app-off-canvas__menu__content__list-item__label__background-color;
$app-off-canvas__menu__content__list-item__label-link__background: $app-off-canvas__menu__content__list-item__label-link__background-color;
$app-off-canvas__menu__content__list-item__label-link__background--hover: shade($app-off-canvas__menu__content__list-item__label-link__background-color, $base__color-shift);
$app-off-canvas__menu__content__list-item__label-link__color: $app-off-canvas__menu__content__list-item__label__color;
$app-off-canvas__menu__content__list-item__label-link__color--hover: $app-off-canvas__menu__content__list-item__label__color;
$app-off-canvas__menu__content__list-item__link__background-color: $app-off-canvas__menu__content__background-color;
$app-off-canvas__menu__content__list-item__link__background: null;
$app-off-canvas__menu__content__list-item__link__background--hover: shade($app-off-canvas__menu__content__list-item__link__background-color, $base__color-shift);
$app-off-canvas__menu__content__list-item__link__color: rgba($base__white, 0.60);
$app-off-canvas__menu__content__list-item__link__color--hover: rgba($base__white, 0.85);
$app-off-canvas__menu__content__dropdown__list__background-color: shade($base__color-pallette__darkest-blue, $base__color-shift * 2);
$app-off-canvas__menu__content__dropdown__list__background: $app-off-canvas__menu__content__dropdown__list__background-color;
$app-off-canvas__menu__content__dropdown__list__border-top: 1px solid #000;
$app-off-canvas__menu__content__dropdown__list-item__border-bottom: 1px solid #000;
$app-off-canvas__menu__content__dropdown__list-item__link__padding: rem-calc(7) $app-grid__column-gutter/2;
$app-off-canvas__menu__content__dropdown__list-item__link__background--hover: $app-off-canvas__menu__content__list-item__link__background-color;
$app-off-canvas__menu__content__dropdown__list-item__link__color: rgba($base__white, 0.60);
$app-off-canvas__menu__content__dropdown__list-item__link__color--hover: rgba($base__white, 0.85);

$app-site-error__content__text-align: center;
$app-site-error__heading__font-size: rem-calc(150);
$app-site-error__heading__margin-bottom: $app-grid__column-gutter;
$app-site-error__subhead__margin-bottom: $app-grid__column-gutter;

$app-modal__overlay__background-color: #5a5246; // $base__color-pallette__warm-gray; //#082237; //$base__color-pallette__darkest-blue;
$app-modal__overlay__opacity: .60;
