@mixin button-base(
  $padding: $app-btn__padding,
  $font: $app-btn__font,
  $font-size:
  $app-btn__font-size,
  $border-radius:
  $app-btn__border-radius)
{
  display: inline-block;
  padding: $padding;
  @include single-transition(background);
  cursor: pointer;
  text-align: center;
  font: $font;
  font-size: $font-size;
  line-height: normal;
  border-radius: $border-radius;
  -webkit-appearance: none;
}