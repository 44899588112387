$app-breadcrumbs__row__max-width: null !default;
$app-breadcrumbs__row__margin: null !default;
$app-breadcrumbs__crumb__padding: rem-calc(9) rem-calc(14) rem-calc(9) !default;
$app-breadcrumbs__crumb__border-color: #D8D8D8 !default;
$app-breadcrumbs__crumb__border: 1px solid $app-breadcrumbs__crumb__border-color !default;
$app-breadcrumbs__crumb__background-color: #f0f6fa !default;
$app-breadcrumbs__crumb__background: $app-breadcrumbs__crumb__background-color !default;
$app-breadcrumbs__crumb__font-size: rem-calc(12) !default;
$app-breadcrumbs__crumb__text-transform: uppercase !default;
$app-breadcrumbs__crumb__font-color: $base__primary-color !default;
$app-breadcrumbs__crumb__font-color--current: #444444 !default;
$app-breadcrumbs__crumb__text-decoration--hover: none !default;
$app-breadcrumbs__crumb-slash__content: "/" !default;
$app-breadcrumbs__crumb-slash__color: #aaaaaa !default;
$app-breadcrumbs__crumb-dropdown__border-right-color: #c8c8c8 !default;
$app-breadcrumbs__crumb-dropdown__border-right: 1px solid $app-breadcrumbs__crumb-dropdown__border-right-color !default;
$app-breadcrumbs__crumb-dropdown__border-bottom-color: #999999 !default;
$app-breadcrumbs__crumb-dropdown__border-bottom: 1px solid $app-breadcrumbs__crumb-dropdown__border-bottom-color !default;
$app-breadcrumbs__crumb-dropdown__border-left-color: #c8c8c8 !default;
$app-breadcrumbs__crumb-dropdown__border-left: 1px solid $app-breadcrumbs__crumb-dropdown__border-left-color !default;
$app-breadcrumbs__crumb-dropdown-item__border-bottom-color: #f9f9f9 !default;
$app-breadcrumbs__crumb-dropdown-item__border-bottom: 1px solid $app-breadcrumbs__crumb-dropdown-item__border-bottom-color !default;
$app-breadcrumbs__crumb-dropdown-item-link__border-bottom-color: #d8d8d8 !default;
$app-breadcrumbs__crumb-dropdown-item-link__border-bottom: 1px solid $app-breadcrumbs__crumb-dropdown-item-link__border-bottom-color !default;
$app-breadcrumbs__crumb-dropdown-item-link__color: #444444 !default;

.breadcrumbs__row{
  @include clearfix();
  max-width: $app-breadcrumbs__row__max-width;
  margin: $app-breadcrumbs__row__margin;
  position: relative;
  z-index: 2;
}
.breadcrumbs__list {
  border-bottom-left-radius: $base__border-radius;
  border-bottom-right-radius: $base__border-radius;
  //position: relative;
  //z-index: 1;
  padding: $app-breadcrumbs__crumb__padding;
  list-style: none;
  border: $app-breadcrumbs__crumb__border;
  background: $app-breadcrumbs__crumb__background;
  width: 100%;
  float: left;
  margin: 0;

  &-item {
    margin: 0;
    float: left;
    font-size: $app-breadcrumbs__crumb__font-size;
    text-transform: $app-breadcrumbs__crumb__text-transform;
    position: relative;
    &-link{
      display: inline-block;
      color: $app-breadcrumbs__crumb__font-color;
      &:hover, &:focus{
        color: $app-breadcrumbs__crumb__font-color--current;
        text-decoration: $app-breadcrumbs__crumb__text-decoration--hover;
      }
    }
    &__current {
      & > .breadcrumbs__list-item__current-link {
        cursor: default;
        color: $app-breadcrumbs__crumb__font-color--current;
        &:hover, &:focus{
          text-decoration: $app-breadcrumbs__crumb__text-decoration--hover;
        }
      }
    }
    &:before {
      content: "#{$app-breadcrumbs__crumb-slash__content}";
      color: $app-breadcrumbs__crumb-slash__color;
      margin: 0 rem-calc(12);
      position: relative;
      top: 1px;
    }
    // remove content from first one
    &:first-child:before {
      content: " ";
      margin: 0;
    }
    &:hover, &:hover .breadcrumbs__list-item-link, &:focus, &:focus .breadcrumbs__list-item-link{
      .breadcrumbs__dropdown__list{
        left: rem-calc(-6);
      }
    }
  }
}

.breadcrumbs__dropdown__list{
  @include shadows(1);
  border-bottom-left-radius: $base__border-radius;
  border-bottom-right-radius: $base__border-radius;
  display: block;
  position: absolute;
  margin: 0 0 0 rem-calc(20);
  padding-top: rem-calc(3);
  left: -999rem;
  list-style: none outside none;
  background: $app-breadcrumbs__crumb__background;
  border-right: $app-breadcrumbs__crumb-dropdown__border-right;
  border-bottom: $app-breadcrumbs__crumb-dropdown__border-bottom;
  border-left: $app-breadcrumbs__crumb-dropdown__border-left;
  border-top: none;
  min-width: 100%;
  max-width: none;
  width: auto;
  z-index: 1;
  &-item{
    border-bottom: $app-breadcrumbs__crumb-dropdown-item__border-bottom;
    text-transform: none;
    &:last-child{
      border-bottom: none;
      &-link{
        border-bottom: none;
      }
    }
    &-link{
      white-space: nowrap;
      font-size: rem-calc(12);
      display: block;
      padding: rem-calc(6);
      border-bottom: $app-breadcrumbs__crumb-dropdown-item-link__border-bottom;
      &:hover, &:focus{
        color: $app-breadcrumbs__crumb-dropdown-item-link__color;
      }
    }
  }
}
