$app-dropdown__margin: rem-calc(8) !default;
$app-dropdown__background: #ffffff !default;
$app-dropdown__card-depth: 2;
$app-dropdown__box-shadow--override: null !default;// 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
$app-dropdown__border-color: #7a5831 !default;
$app-dropdown__border: 1px solid $app-dropdown__border-color !default;
$app-dropdown__border-radius: $base__border-radius !default;
$app-dropdown__max-width: rem-calc(225) !default;
$app-dropdown__triangle__height: 15px !default;
$app-dropdown__triangle__color: $app-dropdown__background !default;
$app-dropdown__triangle__border-width: 1px !default;
$app-dropdown__triangle__border-color: #7a5831 !default;
$app-dropdown__triangle__position: $app-dropdown__triangle__height + $app-dropdown__triangle__border-width + $app-dropdown__border-radius !default;
$app-dropdown__triangle__dark__color: #6C4212 !default;
$app-dropdown__triangle__dark__border-color: #56350e !default;
$app-dropdown__heading__color: #f9f6e4 !default;
$app-dropdown__heading__background-color: #6C4212 !default;
$app-dropdown__heading__background: $app-dropdown__heading__background-color !default;
$app-dropdown__heading__padding: nth($app-heading__h5__font-sizes, 1) / 2 !default;
$app-dropdown__heading__min-height: $app-dropdown__triangle__height * 3 !default;
$app-dropdown__heading__font-size: nth($app-heading__h5__font-sizes, 1) !default;
$app-dropdown__heading__line-height: nth($app-heading__h5__line-height, 1) !default;
$app-dropdown__content__margin: null !default;
$app-dropdown__content__padding: null !default;

.dropdown {
  @include dropdown(
    $app-dropdown__background,
    $app-dropdown__border,
    $app-dropdown__border-radius,
    $app-dropdown__margin,
    $app-dropdown__max-width,
    $app-dropdown__card-depth,
    $app-dropdown__box-shadow--override
  );
  &__indicator__icon{
    @include single-transition( transform, 150ms );
  }
  &--open{
    & .dropdown__indicator__icon{
      display: inline-block;
      transform: rotate(180deg);
    }
  }
}
.dropdown--width-auto,
.dropdown--collapse{
  width: auto;
}
.dropdown--collapse{
  margin: 0;
}
.dropdown--collapse-top{
  margin-top: 0;
}
.dropdown--bottom{
  @include triangle(top,
          $app-dropdown__triangle__height + $app-dropdown__triangle__border-width + $app-dropdown__border-radius,
          $app-dropdown__triangle__height,
          $app-dropdown__triangle__border-width,
          $app-dropdown__triangle__color,
          $app-dropdown__triangle__border-color);
}
.dropdown--right {
  @include triangle(left,
          $app-dropdown__triangle__height + $app-dropdown__triangle__border-width + $app-dropdown__border-radius,
          $app-dropdown__triangle__height,
          $app-dropdown__triangle__border-width,
          $app-dropdown__triangle__color,
          $app-dropdown__triangle__border-color);
}
.dropdown--left {
  @include triangle(right,
          $app-dropdown__triangle__height + $app-dropdown__triangle__border-width + $app-dropdown__border-radius,
          $app-dropdown__triangle__height,
          $app-dropdown__triangle__border-width,
          $app-dropdown__triangle__color,
          $app-dropdown__triangle__border-color);
}
.dropdown--top {
  //@include triangle(bottom, $app-dropdown__triangle-position, 30px, 4px, #88b7d5, #c2e1f5);
}

.dropdown--dark-right {
  @include triangle(left,
          $app-dropdown__triangle__height + $app-dropdown__triangle__border-width + $app-dropdown__border-radius,
          $app-dropdown__triangle__height,
          $app-dropdown__triangle__border-width,
          $app-dropdown__triangle__dark__color,
          $app-dropdown__triangle__dark__border-color);
  border-color: $app-dropdown__triangle__dark__border-color;
}
.dropdown--dark-left {
  @include triangle(right,
          $app-dropdown__triangle__height + $app-dropdown__triangle__border-width + $app-dropdown__border-radius,
          $app-dropdown__triangle__height,
          $app-dropdown__triangle__border-width,
          $app-dropdown__triangle__dark__color,
          $app-dropdown__triangle__dark__border-color);
  border-color: $app-dropdown__triangle__dark__border-color;
}


.dropdown__heading{
  background: $app-dropdown__heading__background;
  color: $app-dropdown__heading__color;
  text-align: center;
  margin: 0;
  padding: $app-dropdown__heading__padding;
  min-height: $app-dropdown__heading__min-height;
  font-size: $app-dropdown__heading__font-size;
  line-height: $app-dropdown__heading__line-height;
}

.dropdown__content{
  margin: $app-dropdown__content__margin;
  padding: $app-dropdown__content__padding;
}