@mixin grid-row($behavior: false) {
  @include clearfix();
  // use @include grid-row(nest); to include a nested row
  @if $behavior == nest {
    margin: 0 (-($app-grid__column-gutter/2));
    max-width: none;
    width: auto;
  }

    // use @include grid-row(collapse); to collapsed a container row margins
  @else if $behavior == collapse {
    margin: 0;
    max-width: $app-grid__row-width;
    width: 100%;
  }

    // use @include grid-row(nest-collapse); to collapse outer margins on a nested row
  @else if $behavior == nest-collapse {
    margin: 0;
    max-width: none;
    width: auto;
  }

    // use @include grid-row; to use a container row
  @else {
    margin: 0 auto;
    max-width: $app-grid__row-width;
    width: 100%;
  }
}

@mixin grid-column-padding($left: true, $right: true){
  @if $left {
    padding-left: ($app-grid__column-gutter / 2);
  }
  @if $right {
    padding-right: ($app-grid__column-gutter / 2);
  }
}

@mixin force-full-width(){
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin grid-column(
  $columns:false,
  $last-column:false,
  $center:false,
  $offset:false,
  $push:false,
  $pull:false,
  $collapse:false,
  $padding: true,
  $float:true,
  $position:false) {

  // If positioned for default .column, include relative position
  // push and pull require position set
  @if $position or $push or $pull {
    position: relative;
  }

  // If collapsed, get rid of gutter padding
  @if $collapse and $padding {
    padding-left: 0;
    padding-right: 0;
  }

    // Gutter padding whenever a column isn't set to collapse
    // (use $collapse:null to do nothing)
  @else if $collapse == false and $padding {
    @include grid-column-padding();
  }

  // If a column number is given, calculate width
  @if $columns {
    width: grid-calc-width($columns, $app-grid__total-columns);

    // If last column, float naturally instead of to the right
    @if $last-column { float: right; }
  }

  // Source Ordering, adds left/right depending on which you use.
  @if $push { left: grid-calc-width($push, $app-grid__total-columns); right: auto; }
  @if $pull { right: grid-calc-width($pull, $app-grid__total-columns); left: auto; }

  @if $float and $last-column == false {
    @if $float == left or $float == true { float: left; }
    @else if $float == right { float: right; }
    @else { float: none; }
  }

  // If centered, get rid of float and add appropriate margins
  @if $center {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  // If offset, calculate appropriate margins
  @if $offset { margin-left: grid-calc-width($offset, $app-grid__total-columns); }

}
