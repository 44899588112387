.product-approval__form-row{
  @include clearfix();
}
.product-approval__statement-column{
}
.product-approval__form-column{
}
.product-approval__preview-img{
  margin: 0 rem-calc(16) rem-calc(10) 0;
  border: 1px solid #888;
  &__link{

  }
}
.product-approval__statement{
  @include clearfix();
}
.product-approval__form-input-column__initials{
  @include grid-column(5, $padding: false);
}
.product-approval__form-input__initials{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.product-approval__form-input-column__submit-button{
  float: left;
}
.product-approval__form-input__submit-button{
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: $app-form__input-buttons__match-text-input__height;
  border-left: none;
  border-top-right-radius: $base__border-radius;
  border-bottom-right-radius: $base__border-radius;
}


.product-approval__line-item__fieldset{
  padding-left: 0;
  padding-bottom: 0;
}

.product-approval__line-item__list{
  @include clearfix();
  list-style: none;
  margin: 0;
  max-width: rem-calc(170);
  &-item{

  }
}

input.product-approval__line-item__form-input__initials{
  font-size: rem-calc(13);
  height: rem-calc(34.4);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &:focus{

  }
}

.product-approval__line-item__form-input__submit-button{
  margin-bottom: 0;
  border-top: none;
  width: 100%;
  height: rem-calc(34.4);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.product-approval__line-item__initials{
  margin-bottom: 0;
}

@include mq(medium-up) {
  .product-approval__statement-column{
    @include grid-column(6);
  }
  .product-approval__form-column{
    @include grid-column(6);
  }
  .product-approval__form-input-column__initials{
    @include grid-column(4, $padding: false);
  }
}

@include mq(medium-alt-up){
  .product-approval__line-item__fieldset{
  }

  .product-approval__line-item__list{
    max-width: none;
    &-item{
      float: left;
      margin-left: rem-calc(16);
      &:first-child{
        margin-left: 0;
      }
      /* &--set-width{
        // had to set this because width was not auto adjusting going from small to large media queries
        width: rem-calc(218);
      } */
    }
  }

  .product-approval__line-item__form-input-column__initials{
    float: left;
    width: rem-calc(112);
  }
  .product-approval__line-item__form-input-column__submit-button{
    float: left;
    width: auto;
  }

  input.product-approval__line-item__form-input__initials{
    height: rem-calc(34.4);
    border-right: none;
    border-bottom-left-radius: $app-form__input__border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:focus{
      /* border-top-color: mix(#000000,#a7cae1,15%);
      border-right-color: #a7cae1; */
    }
  }

  .product-approval__line-item__form-input__submit-button{
    border-top: $app-btn__secondary__border;
    border-top-right-radius: $app-btn__border-radius;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@include mq(large-up) {
  .product-approval__form-input-column__initials{
    @include grid-column(3, $padding: false);
  }
}